import React from 'react';
import { observer } from 'mobx-react-lite';
import { useEffect } from "react";

interface IProps {
  gaAppId?: string;
}

export const GaAnalytics = observer(function  ({gaAppId}: IProps) {
  useEffect(() => {
    if (!gaAppId) {
      return;
    }

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaAppId}`;
    script.async = true;
    script.onload = function () {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      function gtag(){window.dataLayer.push(arguments);}
      // @ts-ignore
      gtag('js', new Date());
      // @ts-ignore
      gtag('config', gaAppId);
    }
    document.body.appendChild(script);

    console.log('ga_inserted', gaAppId);
  }, [gaAppId]);

  return (
    <></>
  )
});
