import React from 'react';
import ReactDOM from 'react-dom';
import App from './current-flow/app'

const isSuperFlow = window.location.href.includes('super=1') || sessionStorage.getItem('is_super');


if (isSuperFlow) {
    import('./super-flow/app').then(run);
} else {
    import('./current-flow/app').then(run);
}

function run({ default: App }) {
    ReactDOM.render(
        <App />,
        document.getElementById('root')
    );
}


