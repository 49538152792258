import React, { useEffect } from 'react';
import Info from '../../components/info/info';
import Form from '../../components/form/form';
import { WrapTypeSelector } from '../../components/wrap-type-selector/wrap-type-selector';
import { Button } from '../../components/button/button';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../common/conts';
import { createGift } from '../../../common/actions';
import {sendMetric} from "../../../common/metrics";
import {observer} from "mobx-react-lite";
import { i18n } from '../../locales';
import { useNotAvailableItem } from '../../../hooks';

export const SelectWrapTypePage = observer(function  () {
  const history = useHistory();
  useNotAvailableItem();

  async function onSubmitRadio(data) {
    if (data.wrap_type === 'send_link') {
      sendMetric('newgift_classic_enabled');
      await createGift(0);
      history.push(ROUTES.SHARE_LINK);
    } else {
      sendMetric('newgift_through_enabled');
      history.push(ROUTES.DELIVERY_FORM);
    }
  }

  useEffect(() => {
    sendMetric('newgift_flow_selector_opened');
  }, []);

  // scroll page up at ROUTE
  useEffect(() => {
    window.scroll(0,0);
  }, []);

  return (
    <div className={ 'main main_theme-default' }>
      <Info
        title={ i18n('sender.flow.intro.title') }
        desc={ i18n('sender.flow.intro.subtitle') }
        link={ true }
      />
      <div className={ 'main-container' }>
      <Form
        title={ '' }
        name={ 'wrap-type-selector' }
        onSubmit={ data => onSubmitRadio(data) }
        theme={ 'wrap-type-selector' }
      >
        <WrapTypeSelector />
        <Button name={ 'send-wrap-type-selector-data' } text={ i18n('form.action.next') } type={ 'submit' } size={ 'default' } className={ 'button_variant-wrap-type-selector' } />
      </Form>
      </div>
    </div>
  );
});
