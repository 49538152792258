import React from 'react';
import Info from '../components/info/info';
import { observer } from "mobx-react-lite";
import { i18n } from '../locales';
export const ErrorPage = observer(function  () {

  return (
    <div className="main main_theme-error">
      <Info
        title={ i18n('error.title') }
        desc={ i18n('error.subtitle') }
        link={ false }
      />
    </div>
  );
});
