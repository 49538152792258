import React, { useEffect, useState } from 'react';
import './address.scss';
import ymaps from 'ymaps';
import { checkOzonAddress } from '../../../common/actions';
import { ERROR_TEXTS } from "../../../common/conts";
import { sendMetric } from '../../../common/metrics';
import { i18n } from "../../locales";

interface IAddressProps {
  value?: string,
  name: string,
  error?: string,
  title: string,
  required?: boolean,
  tabindex?: number,
  onError?: () => void;
  size?: string,
  outerSize?: string,
}

const YA_URL = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU';

export function Address (props: IAddressProps) {
  const [ value, setValue ] = useState<string>();
  const [ error, setError ] = useState<string>('');
  const [ isFocused, setFocus ] = useState<boolean>(false);

  useEffect(() => {
    const input = document.getElementById('ymapi-suggest-view') as HTMLInputElement;
    if (value) {
      checkOzonAddress(value).then(error => {
        if(!error) {
          return;
        }
        setError(ERROR_TEXTS[error]);
        sendMetric(`newgift_through_delivery_${error}`);
      });
    }
    input.value = value || '';
  }, [ isFocused ]);

  function handleBlur() {

    if (!value) {
      setValue('');
      setError( i18n( 'form.error.fullAddressNotComplete' ) );
    }

    // нужно, так как саджест обновялет данные после фокуса
    setTimeout(() => setFocus(false), 100);
  }

  function handleFocus() {
    setError('');
    setFocus(true);
  }

  useEffect(() => {
    setError(props.error || '');
  }, [ props.error ]);

  useEffect(() => {
    if (props.onError && error && !isFocused) {
      props.onError();
    }
  }, [ error ]);

  useEffect(() => {
    ymaps.load(YA_URL).then((maps) => {
      const suggest = new maps.SuggestView('ymapi-suggest-view');
      const input = document.getElementById('ymapi-suggest-view') as HTMLInputElement;

      if (input) {
        input.setAttribute('autocomplete', 'new-password');
      }

      suggest.events.add("select", function (event) {
        setError('');
        setValue(event.get('item').value);
      });
    });
  }, []);

  return (
    <div className={ `address address_outer-size ${ props.outerSize ? 'address_outer-size' + props.outerSize : '' } ${ props.size ?  `address_${props.size}-size` : ''}` }>
      <span className={[ 'address__placeholder', `${ value || isFocused ? 'address__placeholder_focused' : ''} `].join(' ')}>
        Город, улица, дом *
      </span>
      <input autoComplete={ 'off' } type={ 'hidden' } value={ value } name={props.name}/>
      <input
        className={ `address__input ${ error ? 'address__input_invalid': '' } ${ isFocused ? 'address__input_focused' : '' }` }
        id={ 'ymapi-suggest-view' }
        onFocus={ () => { handleFocus() } }
        onBlur={ () => { handleBlur() } }
        autoComplete={ 'new-password' }
        maxLength={ 255 }
      />
      {error && (
        <div className={ 'address__error' }>
          <input type={ 'hidden' } name={ 'error' } value={ error } />
          { error }
        </div>
      )}
    </div>
  )
}
