import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { shopStore } from '../../../stores';

import './info.scss';

interface IInfoProps {
  title: string,
  desc: { },
  link?: boolean,
  change?: boolean, // ?
  className?: string,
  titleVariant?: string,
  descVariant?: string,
  theme?: string,
}

const Info = observer((props: IInfoProps) => {
  const { className = '' } = props;
  const history = useHistory();
  const { fromApp } = shopStore;

  return (
    <div className={ `info ${props.theme ? 'info_theme-'+ props.theme : 'info_theme-default' } ${className} `}>
      <h1 className={ `info__title ${ props.titleVariant ? 'info__title_variant-' + props.titleVariant : '' }` }>
        { !fromApp && (props.link && (
          <button className={ `info__back-link ${shopStore.appName === 'mygiftmagic' ? 'info__back-link_rebranding':''}` }
                  onClick={() => history.goBack()} tabIndex={0}></button> ))
        }
        {props.title}
      </h1>
      <p className={ `info__desc ${ props.change ? 'info__desc_change' : '' } 
        ${props.descVariant ? 'info__desc_variant-'+ props.descVariant : ''}` }>{props.desc}</p>
    </div>
  )
});

export default Info;
