import React, {useEffect, useState} from 'react';
import {recipientStore, giftStore, userStore} from '../../../stores';
import { Input } from '../../components/input/input';
import { Button } from '../../components/button/button';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../common/conts';
import { i18n } from '../../locales';
import { useNotAvailableItem } from '../../../hooks';

import Gift from '../../components/gift/gift';
import Form from '../../components/form/form';
import Info from '../../components/info/info';
import {sendMetric} from "../../../common/metrics";

const fioRegex = /(.*)(\s[^\s]+$)/;

export function RecipientPage() {
  const history = useHistory();
  const [ isReady, setIsReady ] = useState<boolean>(false);

  useNotAvailableItem();

  function onSubmitRecipient(data) {
    data.firstName = data.fullName;
    if (data.fullName && fioRegex.test(data.fullName)) {
      const [_, firstName = '', lastName = ''] = data.fullName.match(fioRegex);
      data.firstName = firstName.trim();
      data.lastName = lastName.trim();
    }

    recipientStore.setFio(data);
    history.push(ROUTES.SENDER_FORM);
  }

  function onFormChange(data) {
    setIsReady(!data.error && data.fullName)
  }

  function onRecipientError() {
    sendMetric('newgift_recipientinfo_error');
  }

  useEffect(() => {
    if (!giftStore.loaded) {
      return;
    }

    onFormChange(recipientStore);
    sendMetric('newgift_recipientinfo_opened');
  }, [ userStore.isReady ]);

  // scroll page up at ROUTE
  useEffect(() => {
    window.scroll(0,0);
  }, []);


  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    /*script.innerHTML = `
      ;!function(j,u,m,b,l,e){var n="jumbleberry",i="3.2.2",o=function(){o.v==i&&o.e?o.e.apply(o,arguments):o.q.push(arguments)}
,a=encodeURIComponent,t=decodeURIComponent,d=j.location;(o.push=o).l=!1,o.q=[],o.v=i,o.duid=function(n){return(
n=n&&u.cookie.match(RegExp(t("%5Cs*")+n.substr(0,21)+"id=([^;]+)")))?t(n[1].split(".")[0]):""},o.g=function(n,i){return!!(
i=RegExp("^[^#]*[?&]"+n+"=([^&#]+)").exec(i||d.href))&&t(i[1].replace(/\\+/g," "))},o.s=function(n){for(var i=Math.round((
new Date).getTime()/1e3),t=d.hostname.split("."),r=t.length-1;0<r--&&/^(([a-z0-9]{4}-?){8}|[0-9]+)$/i.test(n)&&n!=o.duid(
o.p);)u.cookie=o.p.substr(0,21)+"id="+a(n)+"."+i+".0."+i+".; path=/; max-age=63072000; domain=."+t.slice(r,t.length
).join(".")},o.i=function(n,i,t){if("init"==n)return[o.u=o.duid(o.p=i),o.s(o.h=t||o.u)];t=t||{},(n=u.createElement(
"iframe")).src=o.d+o.p+"?hid="+a(o.h)+"&uid="+a(o.u)+"&event="+a(i||"")+"&transid="+a(t.transaction_id||"")+"&oi="+a(
t.order_index||"")+"&ctx="+a(JSON.stringify(t)),n.height=n.width=0,n.style="display:none;visibility:hidden",
n.sandbox="allow-forms allow-same-origin allow-scripts",n.referrerPolicy="unsafe-url",(u.body||u.head).appendChild(n)},
o.m=o.e=function(){var n,i;!j._RNGSeed&&o.i.apply(o,arguments)&&(n=u.createElement(m),i=u.getElementsByTagName(m)[0],
n.src=o.d+o.p+"?hid="+a(o.h)+"&uid="+a(o.u)+"&v="+o.v,n.async=!!o.h,o.e=!1,o.q.unshift(arguments),j.addEventListener(
"beforeunload",n.onerror=function(){o.e=o.i;for(var n=0;n<o.q.length;++n)o.apply(o,o.q[n]);o.q=[]}),
i.parentNode.insertBefore(n,i))},j[n]=j[n]||o,j[n].d=b}(window,document,"script","https://www.unique-skipping-kittens.com/");

jumbleberry("init", "Nx_1sOLhgp6R4y2QPYFVRDWfyHozyGqeFYuQP_I2jyAyu9QAlbjzLrzdbFJzqODD%2F%2F%2F", jumbleberry.g("sqdid"));
jumbleberry("track", "Lead");
jumbleberry("track", "ViewContent");
    `;*/

    script.innerHTML = '';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);


  return (<div className={ 'main main_theme-default' }>
    <Info
      title={ i18n('sender.recipient.intro.title') }
      desc={ i18n('sender.recipient.intro.subtitle') }
      link={ false }
    />
    <div className={ 'main-container main-container_theme-default' }>
      <Gift covered={ 'opened' } variant={ 'boxView' } theme={'boxView'} />
      <Form
        title={ i18n('sender.recipient.form.title') }
        name={ 'recipient' }
        onSubmit={ data => onSubmitRecipient(data) }
        onChange={ onFormChange }
        requiredField={ i18n('form.legend.required') }
        className={ 'form_outer-size-top' }
      >
        <Input
          name={ 'fullName' }
          onFocus={() => sendMetric('newgift_recipientinfo_opened_firstname_focus') }
          onError={ onRecipientError }
          title={ i18n('form.field.recipient.firstName') }
          value={ recipientStore.fullName }
          required={ true }
          tabindex={ 0 }
          maxLength={ 50 }
          outerSize={ '_16-24' }
          autoFocus={true}
        />
        <Button disabled={ !isReady } name={ 'send-recipient-data' } text={ i18n('form.action.next') } type={ 'submit' } size={ 'default' } />
      </Form>
    </div>
  </div>);
}
