import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {userStore, shopStore, giftStore} from "../../../stores";
import Form from "../../components/form/form";
import {Textarea} from "../../components/textarea/textarea";
import {Button} from "../../components/button/button";
import {i18n} from "../../locales";
import Gift from "../../components/gift/gift";
import Checkmark from "../../../image/giftcard-checkmark.svg";
import { getQueryParams } from "../../../common/utils";
import {sendMetric} from "../../../common/metrics";

export const GiftcardBackThanksPage = observer( function () {
  const {userInfo} = userStore;
  const {settings} = shopStore;
  const {items = []} = giftStore;
  const {thankYouMessage} = giftStore;
  const [isMessageWritten, setMessageWritten] = useState<boolean>(!!thankYouMessage);
  const params = getQueryParams();

  async function onSubmitGiftcardThanks(data) {
    await giftStore.sendThankYou(data.message);
    setMessageWritten(true)
  }

  useEffect(() => {
    sendMetric('postcard_recipient_thankyou_opened');
  })

  return(
    <main className={ 'main main_theme-recipient giftcard-thanks' }>
      <div className={'main-container main-container_theme-giftcard'}>
        <Gift covered={ 'opened' } variant={ 'fullView' } theme={'fullView'}/>

        <div className={'giftcard-thanks__wrapper-form'}>
          {!isMessageWritten && (
            <Form
                name={'reciprocal-thanks'}
                onSubmit={data => onSubmitGiftcardThanks(data)}
                className={''}
            >
                <h2 className={'giftcard-thanks__legend'}>
                  {
                    items.length > 1 ?
                      `${i18n('recipient.unwrap.intro.title.many', {
                        item: `${items.length}`,
                        name: ([userInfo?.firstName, userInfo?.lastName].join(' ')) || ''
                      })}`
                      :
                      `${i18n('recipient.unwrap.intro.title', {name: ([userInfo?.firstName, userInfo?.lastName].join(' ')) || ''})}`
                  }
                </h2>
                <span className={'giftcard-thanks__legend-desc'}>{i18n('recipient.finish.intro.subtitle')}</span>
                <Textarea name={'message'} value={thankYouMessage}
                          placeholder={`${i18n('giftcard.note.form.field.placeholder')} ${userStore.userInfo?.firstName}!`}
                          outerSize={'_16-24'} autoFocus={true}/>
              {!params.sender ? (
                <>
                  <Button
                    variant={'variant-default'}
                    size={'default'}
                    name={'send-reciprocal-thanks'}
                    type={'submit'}
                    text={i18n('recipient.finish.thankYouNote.button')}
                    onClick={() => {
                      sendMetric('postcard_recipient_sent_answer');
                    }}
                  />
                  <a className={'button_variant-link'} href={settings?.siteUrl}>
                    {i18n('recipient.finish.takeMeToPartner', {partner: settings?.name ? settings.name[0].toUpperCase()+settings.name.slice(1) : '' })}
                  </a>
                </>
              ) : <></>}
            </Form>
          )}
          { (isMessageWritten || giftStore.thankYouMessage) && (
            <>
              <h2 className={ 'giftcard-thanks__legend' }>{i18n('recipient.finish.intro.title')}</h2>
              <button className={'giftcard-thanks__button-disabled'} disabled={true}>
                <img className={'giftcard-thanks__button-checkmark'} src={Checkmark}/>
                {i18n('recipient.finish.thankYouNote.success')}
              </button>
            </>
          )}
        </div>
      </div>
    </main>
  );
})
