import React, { useEffect } from 'react';
import Info from '../../components/info/info';
import Gift from '../../components/gift/gift';
import Form from '../../components/form/form';
import { Select } from '../../components/select/select';
import { Textarea } from '../../components/textarea/textarea';
import { Button } from '../../components/button/button';
import { giftStore } from '../../../stores'
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../common/conts';
import { sendMetric } from "../../../common/metrics";
import { observer } from "mobx-react-lite";
import { i18n } from '../../locales';
import { shopStore } from "../../../stores";
import { useNotAvailableItem } from '../../../hooks';

export const OccasionPage = observer(function  () {
  const history = useHistory();
  const { occasion, mainItem } = giftStore;
  const { settings } = shopStore;

  useNotAvailableItem();

  function nextPage() {
    if (mainItem?.digital) {
      history.push(ROUTES.DELIVERY_FORM);
    } else {
      history.push(ROUTES.SELECT_WRAP_TYPE);
    }
  }

  function onSubmitOccasion(data) {
    giftStore.setOccasion(data.reason, data.text);
    sendMetric('newgift_occasion_next');
    nextPage();
  }

  function handleSkip() {
    sendMetric('newgift_occasion_skipped');
    nextPage();
  }

  useEffect(() => {
    sendMetric('newgift_occasion_opened');
  }, []);

  // scroll page up at ROUTE
  useEffect(() => {
    window.scroll(0,0);
  }, []);

  return (
    <div className={ 'main main_theme-default' }>
      <Info
        title={ i18n('sender.occasion.intro.title') }
        desc={ i18n('sender.occasion.intro.subtitle') }
        link={ true }
      />
      <div className={ 'main-container main-container_theme-default' }>
        <Gift covered={ 'opened' } variant={ 'boxView' } theme={'boxView'} hidden={ true }/>
        <Form
          title={ i18n('sender.occasion.form.title') }
          name={ 'occasion' }
          onSubmit={data => onSubmitOccasion(data)}
          className={ 'form_outer-size-top' }
          hiddenLegend={ (settings?.name === 'ozon') ? true : false }
        >
          <Select
            title={ '' }
            name={ 'reason' }
            label= { i18n('form.field.occasion') }
            value={ occasion?.reason }
            onChange={ () =>   sendMetric('newgift_occasion_changed') }
            autoFocus={true}
            options={[
              { title: i18n('form.field.occasion.legend'), value: '' },
              { title: i18n('form.field.occasion.option.birthday'), value: 'День рождения' },
              { title: i18n('form.field.occasion.option.fathersDay'), value: 'День отца' },
              { title: i18n('form.field.occasion.option.mothersDay'), value: 'День матери' },
              { title: i18n('form.field.occasion.option.christmas'), value: 'Рождество' },
              { title: i18n('form.field.occasion.option.graduation'), value: 'Выпускной' },
              { title: i18n('form.field.occasion.option.anniversary'), value: 'Юбилей' },
              { title: i18n('form.field.occasion.option.congratulations'), value: 'Поздравление' },
              { title: i18n('form.field.occasion.option.getWell'), value: 'Поправляйся!' },
              { title: i18n('form.field.occasion.option.iAmSorry'), value: 'Извинение' },
          ]}
          />
          <Textarea
            name={ 'text' }
            text={ i18n('form.field.message') }
            value={ occasion?.comment }
            outerSize={ '_16-24' }
            maxLength={ 280 }
          />
          <Button name={'send-occasion-data'} text={ i18n('form.action.next') } type={'submit'} size={ 'default' } />

          <Button name={'skip-occasion-data'} text={ i18n('form.action.skip') } type={'button'} variant={'variant-link'} onClick={handleSkip}/>
        </Form>
      </div>
    </div>
  );
});
