import React, { FormEvent, useRef, useEffect } from 'react';
import './form.scss';
import { Button } from '../button/button';

interface IFormData {
  [key: string]: string | number | File
}

interface IFormProps {
  name: string,
  title?: string,
  hint?: string,
  onSubmit: (data: IFormData) => void,
  children?: React.ReactChild | React.ReactChildren | React.ReactChild[];
  direction?: string,
  theme?: string,
  sizeTop?: string,
  subtitle?: string,
  className?: string,
  onChange?: (data: IFormData) => void;
  hiddenSubtitle?: boolean,
  hiddenLegend?: boolean,
  requiredField?: string,
}

function Form(props: IFormProps) {
  const { className = '' } = props;
  const formEl = useRef(null);

  function getFormData(form: HTMLFormElement, error: string = ''): IFormData {

    if (!form) {
      return {};
    }

    const data = new FormData(form);
    const result: IFormData = {};

    data.forEach(function(value, key){
      result[key] = value;
    });

    if (error) {
      result.error = error;
    }

    return result;
  }

  function getClosestError(el): string | undefined {
    let error = el.closest('.input')?.querySelector('input[name="error"]');

    if (!error) {
      error = el.closest('.phone')?.querySelector('input[name="error"]');
    }

    if (!error) {
      error = el.closest('.address')?.querySelector('input[name="error"]');
    }

    return  error ? error.value : undefined;
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    props.onSubmit(getFormData(form));
  }

  function handleChange(event: FormEvent<HTMLFormElement>) {
    setTimeout(() => {
      const node = event.target as Element;
      const form = node.closest('form') as HTMLFormElement;
      props.onChange && props.onChange(getFormData(form, getClosestError(event.target)));
    }, 100);
  }

  function handleBlur(event: any) {
    setTimeout(() => {
      const form = event.target.closest('form');
      props.onChange && props.onChange(getFormData(form, getClosestError(event.target)));
    }, 100);
  }

  useEffect(() => {
    // @ts-ignore
    const form = formEl?.current ? formEl.current as HTMLFormElement : null;
    const formData = form ? getFormData(form) : {};

    if (Object.keys(formData).map(i => !!formData[i]).length) {
      props.onChange && props.onChange(formData);
    }
  }, [ ])

  return (
    <form
        onBlur={handleBlur}
        onChange={handleChange}
        ref={formEl}
        className={ `form ${props.theme ? 'form_theme-' + props.theme : ''} ${className}` } action="src/current/components/form/form#" method="#" onSubmit={handleSubmit} name={props.name}>
      <fieldset className={ `
        form__fieldset ${ props.direction ? 'form__fieldset_'+ props.direction : ''} ${ props.sizeTop ? 'form__fieldset_' + props.sizeTop : '' }` }>
        {props.title && (
          <div className={ `form__legend-wrap ${ props.hiddenLegend ? 'form__legend-wrap_hidden' : '' }` }>
            <legend className={ 'form__legend' }>{props.title}</legend>
            <span className={ 'form__legend-desc' }>{props.requiredField}</span>
          </div>
          )}
        {props.hint && (<span className={ 'required-text' }>${props.hint}</span>)}
        {props.subtitle && (<h2 className={ `form__subtitle ${ props.hiddenSubtitle ? 'form__subtitle_hidden' : '' }` }>{props.subtitle}</h2>)}
        {props.children}
      </fieldset>
    </form>
  );
}

export default Form;
