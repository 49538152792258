import { giftStore, userStore, shopStore, recipientStore } from '../stores';
import { ICreateGiftRequest } from '../stores/gift/gift';
import { Logger } from "./logger";
import { getQueryParams, getDefaultHeaders, objectToQS, getGiftId, getOrCreateUserSessionId } from "./utils";
import { sendMetric } from "./metrics";
import {ERROR} from './conts';
import { IGiftItem } from '../stores/gift/gift';

async function loadGiftInfo(giftId: string): Promise<{
  code: string,
  delivery: {
    address: string;
    postcode: string;
  },
  items: IGiftItem[],
  recipient: {
    first_name: string,
    last_name: string,
    city: string,
    country: string,
    email: string,
    phone_number: string,
    province: string,
    street_address1: string,
    street_address2: string,
    type: string,
    recipient_optin: boolean,
  },
  message: {
    occasion: string,
    sender_text: string,
    recipient_text: string,
  }
  sender: {
    cover_img?: string,
    first_name: string,
    last_name: string,
    phone: string,
    email: string,
    locale: string,
    sender_optin: boolean,
    throughflow: boolean,
  },
  tracking?: {
    external_order_id: string;
    tracking_number: string;
    tracking_company: string;
    tracking_url: string;
  }
  status: string,
  flow_status: string,

}> {
  return fetch(`/api/gift/${giftId}?open=true`).then(res => res.json());
}

/**
 * Проверяет - а нужно ли пользователя отправить на сообщение о том что подарок оплачен
 * @param giftStatus - статуc подарка (completed | )
 */
function checkGiftStatus(giftStatus: string) {
  // если пользователь переходит на гифткард - то редирект не нужен
  if (window.location.pathname.includes('giftcard')) {
    return;
  }
  if (window.location.pathname.includes('oos')) {
    return;
  }
  if (giftStatus === 'completed') {
    window.location.pathname = '/pages/gift-paid';
  }
  if (giftStatus === 'accepted') {
    window.location.pathname = '/pages/gift-accepted';
  }
}

/**
 * Инициализация приложения (после заверешения срабатывает событие newgift_started)
 * Логика инициализации
 * - Загружаем магазин
 *    - Если ок - то загружаем подарок
 *    - Если подарок ок - то запускаем сессию пользователя и отправляем newgift_started.
 */

export async function initApp() {
  const logger = new Logger('InitApp');
  const params = getQueryParams();
  const giftId = getGiftId();

  logger.info('run app with params', { params });

  if (!giftId && !Object.keys(params).includes('goods')) {
    console.log('app already init');
    return false;
  }

  sessionStorage.clear();

  getOrCreateUserSessionId();
  localStorage.clear();

  if (params.store_code) {
    await shopStore.load(params.store_code);
    const qs = objectToQS({ ...params, store_code: null});
    const subDomains = window.location.host.split('.');
    logger.info('redirect_by_store_code', params, shopStore.settings?.subdomain_name);
    subDomains[0] = shopStore.settings?.subdomain_name || subDomains[0];
    window.location.href = `//${subDomains.join('.')}${window.location.pathname}?${qs}`;
  }

  userStore.setIsReady(false);
  logger.info('params', { params });

  if (params.guid || params.senderId ) {
    userStore.setIds(params.guid || '', params.senderId || params.senderid || '');
  }

  if (params.gift_id) {
    giftStore.setExtGiftId(params.gift_id);
  }

  const utm = {};

  Object.keys(params).forEach(key => {
    if (key.includes('utm')) {
      utm[key] = params[key];
    }
  });

  giftStore.setUtm(utm);

  if (params.giftlanding) {
    giftStore.setGiftLanding(params.giftlanding);
  }

  try {
    await shopStore.load();

    if (!shopStore.settings?.defaultCountry ) {
      await shopStore.setDefaultCountryCodeFromGeoIp();
    }

    await shopStore.loadCountries();

    const shop = shopStore.settings?.subdomain_name ?? shopStore.settings?.name;

    if (giftId) {
      const data = await loadGiftInfo(giftId);
      console.log('gift_loaded', { data });

      if (!window.location.href.includes('super=1')) {
        checkGiftStatus(data.status);
      }

      if (data.sender.locale) {
        shopStore.setLang(data.sender.locale);
      }

      giftStore.setStatus(data.status);
      giftStore.setFlowStatus(data.flow_status);
      userStore.setIsClassicFlow(!data.sender.throughflow);

      await giftStore.load(data.items.map(item => ({
        id: item.variant_id,
        quantity: item.quantity,
      })), shop);

      giftStore.setCode(data.code);

      giftStore.setThankYouMessage(data.message.recipient_text);

      if (data.sender.cover_img) {
        giftStore.setCoverImg(data.sender.cover_img);
      }

      if (data.tracking) {
        giftStore.setTrackingInfo(data.tracking);
      }

      giftStore.setOccasion(data.message.occasion, data.message.sender_text);
      recipientStore.setFullInfo({
        firstName: data.recipient.first_name,
        lastName: data.recipient.last_name,
        address: data.delivery?.address ?? '',
        index: data.delivery?.postcode ?? '',
        country: data.recipient.country,
        city: data.recipient.city,
        province: data.recipient.province,
        street_address1: data.recipient.street_address1,
        street_address2:data.recipient.street_address2,
        email: data.recipient.email,
        phone: data.recipient.phone_number,
        recipient_optin: data.recipient.recipient_optin,
      });
      userStore.setUserInfo({
        firstName: data.sender.first_name,
        lastName: data.sender.last_name,
        email: data.sender.email,
        phone: data.sender.phone,
        sender_optin: data.sender.sender_optin,
      });
      await giftStore.loadOptions(shop ?? '', giftStore.mainItem?.variant_id);

      // пользователю нельзя повторно отправлять сообщение thankYouNote giftcardFlow
      if ( data.message.recipient_text && ((window.location.pathname + window.location.search) === `/giftcard/${giftId}`) ) {
        window.location.pathname = `/giftcard/${giftId}/thanks`;
      }
    } else {
      await giftStore.load(
        params.goods || [],
        shop
      );
      await userStore.load();
    }
  } catch (err) {
    await sendMetric('app_fatal-error');
    logger.error(err.message, { err });
  }

  if (params.locale) {
    shopStore.setLang(params.locale);
  }

  if (params.fromiframe) {
    shopStore.setFromIframe(true);
  }

  if (params.fromapp) {
    shopStore.setFromApp(true);
  } else if (document) {
    const fromapp =
      document.cookie.indexOf('fromapp=ozonapp_android') !== -1 ||
      document.cookie.indexOf('fromapp=ozonapp_ios') !== -1;

    logger.info('check from app from cookie', { fromapp });
    shopStore.setFromApp(fromapp);
  }
  // variations newgift_started event
  const {start_from} = getQueryParams();

  if (start_from) {
    await sendMetric(`newgift_${start_from}_started`);
  } else {
    await sendMetric('newgift_started');
  }

  if (window.location.href.includes('type=iframe')) {
    giftStore.setIsFromIframe(true);
  }

  logger.info('app_loaded');
  userStore.setIsReady(true);
  return true;
}

export async function createGift(throughflow: number, superflow: boolean = false) {
  const { guid, senderId } = userStore;
  const { settings } = shopStore;
  const { occasion } = giftStore;
  const logger = new Logger('createGift');


  if (!settings) {
    logger.warn('shop settings is not active');
    return;
  }

  const request: ICreateGiftRequest = {
    locale: settings.lang,
    message: occasion?.comment || '',
    occasion: occasion?.reason || '',
    recipient: {
      first_name: recipientStore.firstName || '',
      last_name: recipientStore.lastName || '',
      country: recipientStore.country,
      city: recipientStore.city,
      province: recipientStore.province,
      street_address1: recipientStore.street_address1,
      street_address2: recipientStore.street_address2,
      phone_number: recipientStore.phone,
      email: recipientStore.email,
      recipient_optin: recipientStore.recipient_optin || false,
    },
    sender: {
      first_name: userStore.userInfo?.firstName || '',
      last_name:  userStore.userInfo?.lastName || '',
      email: userStore.userInfo?.email || '',
      phone: userStore.userInfo?.phone || '',
      sender_optin: userStore.userInfo?.sender_optin || false,
    },
    type: shopStore.settings?.type || '',
    link_delivery_type: userStore.shareVariant || 'send',
  }

  if (superflow) {
    request.sender.superflow = true;
    request.sender.cover_img = giftStore.coverImg;
    request.flow_type = settings.features?.flow_type;
  }

  return giftStore.createGift(throughflow, guid, senderId, settings.subdomain_name, request);
}

export async function checkOzonAddress(str: string): Promise<string | null> {
  const res = await fetch(`/api/ozon/validate-address?address=${str}`, {
    headers: getDefaultHeaders()
  });

  if (!res.ok) {
    return ERROR.OZON_ERROR;
  }

  const body = await res.json();

  if (body.exact === false) {
    return ERROR.ADDRESS_NOT_EXACT;
  }

  if (body.available === false) {
    return ERROR.ADDRESS_NOT_AVAILABLE
  }

  return null;
}

export async function acceptGift() {
  const { code } = giftStore;
  const { settings } = shopStore;

  if (!code) {
    throw new Error('gift not created');
  }

  if (!settings) {
    throw new Error('shop not defined');
  }

  await giftStore.acceptGift(settings?.subdomain_name, recipientStore);
}

export async function sendRecipientShipping() {
  const { code, thankYouMessage } = giftStore;

  await recipientStore.sendRecipientShipping(code, thankYouMessage);
}

