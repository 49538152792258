/**
 * Метод используется для валидации параметров сковозной формы
 * @param features - включенные фичи в магазине
 * @param data - данные с формы
 * @param isDigital - прзинак того, что форма для цифрового подарка
 * @param hasPrivacyCheckbox - признак того, что на форме есть чекбокс прайваси
 */
export function validateThroughFlow(features, data, isDigital, hasPrivacyCheckbox = false): boolean {
  if (isDigital && hasPrivacyCheckbox) {
    return data.email && !data.error && data.privacy;
  } if (isDigital) {
    return data.email && !data.error;
  } else if (features.is_ozon_form && hasPrivacyCheckbox) {
    return (
      !data.error &&
      data.phone &&
      data.firstName &&
      data.lastName &&
      data.email &&
      data.apartment &&
      data.index &&
      data.address &&
      data.privacy
    );
  } else if (features.is_ozon_form) {
    return (
      !data.error &&
      data.phone &&
      data.firstName &&
      data.lastName &&
      data.email &&
      data.apartment &&
      data.address &&
      data.index
    );
  } else if (features.is_levenger_form) {
    return (
      !data.error &&
      data.phone &&
      data.firstName &&
      data.lastName &&
      data.email &&
      data.index &&
      data.country &&
      data.city &&
      data.street_address1
    );
  } else if (features.is_pollini_form) {
    return (
      !data.error &&
      data.phone &&
      data.firstName &&
      data.lastName &&
      data.email &&
      data.index &&
      data.country &&
      data.city &&
      data.street_address1 &&
      data.province
    );
  } else if (features.is_default_form) {
    return(
      !data.error &&
      data.phone &&
      data.firstName &&
      data.lastName &&
      data.email &&
      data.index &&
      data.country &&
      data.city &&
      data.street_address1
    );
  } else {
    return false;
  }
}
