import React from "react";
import './progress.scss';
import {i18n} from "../../locales";

interface IProgressProps {
  disabled?: boolean,
  step: number,
}

function Progress ( props: IProgressProps ) {
  return(
    <section className={ 'progress' }>

      <div className={ `progress__item ${  props.step === 2 ? 'progress__item_disabled' : ''}` }>
        <span className={ `progress__number ${ props.step === 2 ? 'progress__number_disabled' : ''}` }>1</span>
        <span className={ `progress__text ${ props.step === 2 ? 'progress__text_disabled' : ''}` }>{ i18n('common.recipient.steps.1') }</span>
      </div>

      <span className={ 'progress__line' } />

      <div className={ `progress__item ${  props.step === 1 ? 'progress__item_disabled' : ''}` }>
        <span className={ `progress__number ${ props.step === 1 ? 'progress__number_disabled' : ''}` }>2</span>
        <span className={ `progress__text ${ props.step === 1 ? 'progress__text_disabled' : ''}` }>{ i18n('common.recipient.steps.2') }</span>
      </div>

    </section>

  );
}
export default Progress;
