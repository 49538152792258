import { action, observable, runInAction, makeObservable } from "mobx";
import { getShopSlug, getDefaultHeaders, isSenderSuperFlow, isClassicFlow } from '../../common/utils';
import { Logger } from '../../common/logger';
import { autoSave } from '../auto-save';
import { sendMetric } from "../../common/metrics";
import { IRecipientStore } from '../recipient/recipient';

const logger = new Logger('GiftStore');

export interface IGiftItem {
  available: boolean; // доступен к отправке
  currency: string; // валюта (RUB, EUR)
  description?: string; // описание
  digital?: boolean; // признак того, что это цифровой товар
  image_url: string; // ссылка на картинку товара
  model_id?: string;
  model_name?: string;
  name: string;
  ozon_search_url?: string; // поисковый урл для озона
  price: number; // цена
  price_before?: number;
  variant_id: string; // идентификатор товара
  have_multioptions?: boolean;

  quantity: number; // количество

  option1?: string;
  option2?: string;
  option3?: string;
  option4?: string;
  option5?: string;
}

export interface IGiftItemSuperflow {
  available: boolean;
  currency: string;
  description?: string;
  digital?: boolean;
  image_url: string;
  model_id?: string;
  model_name?: string;
  name: string;
  price_before?: number;
  price: number;
  variant_id: string;
  have_multioptions?: boolean;

  quantity?: number;

  option1?: string;
  option2?: string;
  option3?: string;
  option4?: string;
  option5?: string;
}

export interface IOptionsSuperflow {
  [key: string]: {
      name: string,
      values: Array<string>,
  }
}

export interface IOptionsSetSuperflow {
  options: IOptionsSuperflow,
  variants: Array<IGiftItemSuperflow>,
}

export interface IGiftStore {
  loaded: boolean;
  slug: string;
  initialGift?: IGiftItem; // подарок с которым пользователь пришел в магазин

  isCreating?: boolean; // признак того, что подарок создается
  isAccepting?: boolean; // признак того, что подарок принимается
}

export interface ITrackingInfo {
  external_order_id?: string;
  tracking_number?: string;
  tracking_company?: string;
  tracking_url?: string;
}

export interface IGetGiftResponce {
  code: string,
  delivery: {
    address: string;
    postcode: string;
  },
  items: IGiftItem[],
  recipient: {
    first_name: string,
    last_name: string,
    city: string,
    country: string,
    email: string,
    phone_number: string,
    province: string,
    street_address1: string,
    street_address2: string,
    type: string,
    recipient_optin: boolean,
  },
  message: {
    occasion: string,
    sender_text: string,
    recipient_text: string,
  }
  sender: {
    cover_img?: string,
    first_name: string,
    last_name: string,
    phone: string,
    email: string,
    locale: string,
    sender_optin: boolean,
  },
  tracking?: {
    external_order_id: string;
    tracking_number: string;
    tracking_company: string;
    tracking_url: string;
  }
  status: string,
  flow_status: string,
}

export interface ICreateGiftRequest {
  gift_id?: string; // внешинй гифтид - нужен для интеграции магенто к примеру
  idempotency_key?: number;
  items?: IGiftItem[];
  locale: string;
  message: string;
  occasion: string;
  thankYouMessage?: string;
  flow_type?: string;
  link_delivery_type?: string;
  recipient: {
    first_name: string,
    last_name: string,
    country?: string,
    city?: string,
    email?: string,
    phone_number?: string,
    postcode?: string,
    province?: string,
    street_address1?: string,
    street_address2?: string,
    recipient_optin?: boolean,
  },
  pass_params?: {[key: string]: string},
  sender: {
    superflow?: boolean;
    cover_img?: string;
    email: string;
    first_name: string,
    last_name: string,
    phone: string,
    giftlanding_section?: string,
    sender_optin?: boolean,
  },
  type: string,
}

export interface IGiftCertificate {
  id: number;
  image_url: string;
  name: number;
}

export class GiftStore implements IGiftStore {
  @observable code?: string; // итоговый код, для отправки на чекаут
  @observable extGiftId?: string; // нужен только для magento магазинов для их магии
  @observable isCreating: boolean = false;
  @observable isUpdating: boolean = false;
  @observable isOptionsLoading = false;
  @observable isOptionsSFLoading = false;
  @observable isAccepting: boolean = false;
  @observable isCheckoutRedirecting = false;
  @observable isVariantsNotFound?: boolean; // не найденно ни одного вариант
  @observable loaded: boolean = false;
  @observable slug: string = getShopSlug();
  @observable items?: IGiftItem[]; // корзина подарков
  @observable replacedItem?: IGiftItem; // объект из корзины, которые нужно заменить
  @observable mainItem?: IGiftItem;
  @observable certificates: IGiftCertificate[] = []; // сертификаты на подмену
  @observable substitutes: IGiftItem[] = []; // продукты на подмену
  @observable occasion?: {
    reason?: string;
    comment?: string;
  };
  @observable status?: string; // статус подарка pending, viewed, accepted, completed,
  @observable giftLanding: string = ''; // секция giftlanding, из которой пришел пользователь на подарок

  @observable options: Array<IGiftItem> = [];
  @observable optionsSuperflow: IOptionsSetSuperflow = { options: {}, variants: []};
  @observable thankYouMessage = '';
  @observable checkoutUrl: string = '';
  @observable oldMainItem?: IGiftItem;
  @observable utm: {[key:string]: string} = {};
  @observable basketPrice?: number;
  @observable coverImg?: string;
  @observable tracking?: ITrackingInfo;
  @observable flowStatus?: string;
  @observable selectedItem?: IGiftItem;
  @observable previousItem?: IGiftItem | null;
  @observable initialUrl?: string = '';
  @observable isFromIframe?: boolean = false;

  constructor() {
    makeObservable(this);
  }

  init() {
    autoSave(this, 'GiftStore');
  }

  private async loadGift(variantId: string, shop?: string) {
    if (!variantId || !shop) {
      logger.warn(`variantId or shop not defined`);
      return;
    }

    return fetch(`/api/shop/${shop}/variant/${variantId}?check_multioptions=true`, {
      headers: getDefaultHeaders(),
    }).then(res => {
      if (!res.ok) {
        throw new Error('invalid variant');
      }

      return res.json();
    });
  }

  @action setCoverImg(coverImg: string) {
    this.coverImg = coverImg;
  }

  @action setIsFromIframe(isFromIframe: boolean) {
    this.isFromIframe = isFromIframe;
  }

  @action setStatus(status: string) {
    this.status = status;
  }

  @action setInitialUrl(url: string) {
    this.initialUrl = url;
  }

  @action setFlowStatus(flowStatus: string) {
    this.flowStatus = flowStatus;
  }

  @action setUtm(utm) {
    logger.info('setUtm', utm);
    this.utm = utm;
  }

  @action setGiftLanding(section: string) {
    logger.info('setGiftLanding', section);
    this.giftLanding = section;
  }

  @action setTrackingInfo(data: ITrackingInfo) {
    this.tracking = {...data};
  }

  /**
   * Метод загружает сертификаты
   */
  @action async loadCertificates() {
    this.certificates = await fetch(`/api/ozon-certs`, {
      headers: getDefaultHeaders(),
    }).then(res => res.json());
    logger.info('certificates loaded');
  }

  /**
   * Сохраняет код созданого подрака
   * @param reason
   * @param comment
   */
  @action setCode(code: string) {
    this.code = code;
  }

  /**
   * Сохраняет подарок
   * @param item
   */
  @action setItems(items: IGiftItem[]) {
    this.items = items;
    this.mainItem = items[0];
    this.loaded = true;
  }

  /**
   * Метод выставляет объект для замены
   * @param item
   */
  @action setItemForReplace(item: IGiftItem) {
    this.replacedItem = item;
  }

  @action setSelectedItem(item: IGiftItem) {
    this.selectedItem = item;
  }

  @action setPreviousItem(item: IGiftItem | null) {
    this.previousItem = item;
  }

  @action setMainItem(item: IGiftItem) {
    this.oldMainItem = this.mainItem;
    this.mainItem = item;
  }

  /**
   * добавляем thankYouNote текст
   */
  @action setThankYouMessage(message: string) {
    this.thankYouMessage = message;
  }

  /**
   * Метод загружает альтернативные продукты
   */
  @action async loadSubstitutes(swap = 0) {
    const slug = window.location.hostname.split('.').shift() || '';

    if (!this.items) {
      return;
    }

    const results = await Promise.all(
      this.items.map((product) =>
        fetch(`/api/shop/${slug}/variant/${product.variant_id}/substitutes?swap=${swap}`, {
          headers: getDefaultHeaders(),
        }).then((resp) => resp.json())
      )
    );

    let items: IGiftItem[] = [];

    runInAction(() => {
      for (const res of results) {
        items = items.concat(res)
      }
      this.substitutes = items.reduce<IGiftItem[]>((arr, item) => {
        if (arr.find(existItem => existItem.variant_id === item.variant_id)) {
          return arr;
        }
        arr.push(item);
        return arr;
      }, []).map(item => ({
        ...item,
        quantity: 1,
      }));
      logger.info('substitutes loaded', { count: this.substitutes.length });
    });
  }



  /**
   * Сохраняет повод для подарка
   * @param reason
   * @param comment
   */
  @action setOccasion(reason: string, comment: string) {
    this.occasion = { reason, comment };
  }

  @action setOccasionComment(comment: string) {
    this.occasion = { comment };
  }

  /**
   * Устанавливаем внешний giftId нужен для корректной работы флоу в магенто
   * @param id
   */
  @action setExtGiftId(id: string) {
    this.extGiftId = id;
  }

  /**
   * Метод загружает данные по подарку
   * с которым пользователь пришел в аппку
   * @param variants - идентификатор и количество товаров в подарке
   * @param shop - магазин
   */
  @action async load(variants: Array<any>, shop?: string) {
    logger.info('load gifts', { variants, shop })
    const result: IGiftItem[] = [];

    for (const variant of variants) {
      try {
        let gift = result.find(item => item.variant_id === variant.id);

        if (!gift) {
            gift = await this.loadGift(variant.id, shop);
            result.push({
              ...gift,
              ...variant,
              quantity: parseInt(variant.quantity ?? '1', 10),
            } as IGiftItem);
        } else {
            gift.quantity = (gift.quantity ?? 0) + parseInt(variant.quantity, 10);
        }

        logger.info('load gift', gift, variant);
      } catch (err) {
        logger.error('load gift error', err);
        sendMetric('newgift_itemnotfound');
      }
    }

    if (result.length === 0) {
      logger.error('all gifts not found');
      this.isVariantsNotFound = true;
      return;
    }

    runInAction(async () => {
      this.items = result;
      this.mainItem = result[0];
      this.loaded = true;

      let price = 0;
      this.items.forEach(item => price += item.price);

      this.basketPrice = price;
      logger.info('all gifts loaded', { result, basketPrice: this.basketPrice });
    });
  }

  /**
   * Метод создает гифт
   */
  @action async createGift(throughflow: number, guid: string, senderId: string, shop: string, request: ICreateGiftRequest) {

    if (this.isCreating) {
      logger.info('gift processing');
      return;
    }

    if (this.code) {
      logger.info('gift already created');
      return;
    }

    this.isCreating = true;

    if (!request.idempotency_key) {
      request.idempotency_key = Date.now();
    }

    if (this.extGiftId) {
      request.gift_id = this.extGiftId;
    }

    if (!request.items && this.mainItem) {
      request.items = this.items;
    }

    if (this.giftLanding) {
      request.sender.giftlanding_section = this.giftLanding;
    }

    request.pass_params = this.utm;

      return fetch(`/api/shop/${shop}/gift?throughflow=${throughflow}&senderId=${senderId}&guid=${guid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getDefaultHeaders(),
      },
      body: JSON.stringify(request),
    })
      .then(res => res.json())
      .then(result => {
        if (result.statusCode === 400) {
          throw new Error(result.message);
        }
        this.code = result.code;
        this.isCreating = false;
        logger.info(`gift created ${ this.code }`);
        sendMetric('gift_created', { throughflow, code: this.code });
        return result;
      });
  }

  /**
   * Заменяем текущй продукт, на сертификат
   */
  @action swapMainItemByCertificate(certificate: IGiftCertificate) {
    logger.info('swapMainItemByCertificate', { certificate });
    this.mainItem = {
      name: `Сертификат на сумму ${certificate.name} руб.`,
      available: true,
      digital: true,
      currency: this.mainItem?.currency || 'rub',
      variant_id: certificate.id + '',
      image_url: certificate.image_url,
      price: certificate.name,
      quantity: 1,
    };
  }

  @action swapItemBySubstitute(newItem: IGiftItem) {
    logger.info('swapItemBySubstitute', { newItem });
    this.items = this.items?.map((item) => {
      if (this.replacedItem?.variant_id === item.variant_id) {
        return {
          ...newItem,
          quantity: item.quantity,
        };
      }
      return item;
    }).reduce((gifts: IGiftItem[], gift: IGiftItem) => {
      const existGift = gifts.find(item => item.variant_id === gift.variant_id);
      if (existGift) {
        existGift.quantity += gift.quantity;
      } else {
        gifts.push(gift);
      }

      return gifts;
    }, []);
  }

  @action async sendThankYou(message: string) {
    try {
      await fetch(`/api/gift/${this.code}/thankyou`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getDefaultHeaders(),
        },
        body: JSON.stringify({
          message
        })
      })
    } catch (err) {
      logger.warn('thankYou error', { err });
      sendMetric('accept_thankyou_error');
    }

    this.thankYouMessage = message;
  }

  /**
   * Метод подтверждает гифт
   */
  @action async acceptGift(shop: string, recipient: IRecipientStore) {
    const [ address, uid ] = (recipient.address || '').split('__');
    this.isAccepting = true;

    let result;

    sendMetric('accept_through');

    try {
      result = await fetch(`/api/gift/${this.code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getDefaultHeaders(),
        },
        body: JSON.stringify({
          recipient: {
            first_name: recipient.firstName,
            last_name: recipient.lastName,
            address: address,
            apartment: recipient.apartment,
            postcode: recipient.index,
            full_address: address,
            country: recipient.country,
            city: recipient.city,
            province: recipient.province,
            street_address1: recipient.street_address1,
            street_address2: recipient.street_address2,
            phone_number: recipient.phone,
            email: recipient.email,
            uid,
            recipient_optin: recipient.recipient_optin,
          },
          items: this.items,
        }),
      });

      if (result.status === 400) {
        result = await result.json();
      }

    } catch (err) {
      logger.warn('acceptGift error', { err });
    }

    if (result.statusCode === 400) {
      this.isAccepting = false;
      throw new Error(result.message);
    } else {
      sendMetric('checkout_redirected'); // id - yes
      this.checkoutUrl = `/api/gift/${this.code}/complete`;
      if (isSenderSuperFlow()) {
        this.redirectToCheckout();
      }
      this.isAccepting = false;
    }

  }

  @action setCheckoutUrl() {
    this.checkoutUrl = `/api/gift/${this.code}/complete`;
  }

  @action setIsOptionsSFLoading(isLoading:boolean) {
    this.isOptionsSFLoading = isLoading;
  }

  @action redirectToCheckout() {
    this.isCheckoutRedirecting = true;
    const currentUrl = this.checkoutUrl;
    const allowCodeReset = isSenderSuperFlow() && !isClassicFlow();
    if (allowCodeReset) {
      this.checkoutUrl = '';
      this.code = '';
    }
    if (this.isFromIframe) {
      window.top?.postMessage(currentUrl, '*');
    } else {
      window.location.href = currentUrl;
    }
  }

  /**
   * Метод подгружает доступные опции
   */
  @action async loadOptions(shop, itemId) {
    try {
      this.options = [];
      this.isOptionsLoading = true;
      this.options = await fetch(`/api/shop/${shop}/variant/${itemId}/options`, {
        headers: getDefaultHeaders(),
      }).then(res => res.json());
      logger.info('recipient_options_loaded', this.options);
      return this.options;
    } catch (err) {
      sendMetric('recipient_load_options_error');
      logger.error('recipient_load_options_error', err);
    } finally {
      this.isOptionsLoading = false;
    }
    return [];
  }

  /**
   * Метод подгружает доступные опции (для Superflow)
   */
  @action async loadOptionsSuperflow(shop, itemId, priceFilter = 0) {
    try {
      this.setIsOptionsSFLoading(true);
      this.optionsSuperflow = await fetch(`/api/shop/${shop}/variant/${itemId}/multioptions?price_filter=${priceFilter}`, {
        headers: getDefaultHeaders(),
      }).then(res => {
        if (!res.ok) {
          throw Error(`Response failed ${res.status}`);
        }
        return res.json();
      });
      logger.info('recipient_options_loaded', this.optionsSuperflow);
      return this.optionsSuperflow;
    } catch (err) {
      sendMetric('recipient_load_options_error');
      logger.error('recipient_load_options_error', err);
    } finally {
      this.setIsOptionsSFLoading(false);
    }
    return { options: {}, variants: [] };
  }

  @action async sendCode(verificationCode) {
    let result;

    try {
      result = await fetch(`/api/gift/${this.code}/sms_confirm`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getDefaultHeaders(),
        },
        body: JSON.stringify({
          code: verificationCode
        }),
      });

      return result;

    } catch (err) {
      logger.warn('sendCode error', { err });
    }
  }

  @action async resendCode() {
    let result;
    try {
      result = await fetch(`/api/gift/${this.code}/resend_sms`, {
        method: 'POST',
        headers: {
          ...getDefaultHeaders(),
        },
      });
      return result;
    } catch (err) {
      logger.warn('resendCode error', { err });
    }
  }

  @action async getGiftStatus() {
    try {
      return fetch(`/api/gift/${this.code}`).then(res => res.json());
    } catch (err) {
      logger.warn('get gift error', { err });
    }
  }

  @action async updateGift() {
    this.isUpdating = true;

    let result;

    try {
      result = await fetch(`/api/gift/${this.code}/oos`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getDefaultHeaders(),
        },
        body: JSON.stringify({
          items: this.items,
        }),
      });

      if (result.status === 400) {
        result = await result.json();
      }

    } catch (err) {
      logger.warn('updatingGift error', { err });
    }

    if (result.statusCode === 400) {
      this.isUpdating = false;
      throw new Error(result.message);
    } else {
      sendMetric('checkout_redirected');
      this.checkoutUrl = `/api/gift/${this.code}/complete`;
      this.isUpdating = false;
    }
  }
}

