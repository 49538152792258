import {useEffect, useState} from "react";
import {giftStore, initStores, shopStore} from "../stores";
import {ROUTES} from "../common/conts";
import {Logger} from "../common/logger";
import {useHistory} from 'react-router-dom';
import {getOrCreateUserSessionId, hexToHSL} from "../common/utils";
import {trackSgEvent} from "../common/metrics";
import {initApp} from "../common/actions";

const logger = new Logger('useNotAvailableItem');

export function useNotAvailableItem() {
    const history = useHistory();

    useEffect(() => {
        const { mainItem, basketPrice } = giftStore;

        if (!mainItem || !basketPrice) {
            return;
        }

        if (giftStore.status === 'completed') {
            logger.info('gift_already_paid', { status: giftStore.status });
            return;
        }

        const notAvailableItem = giftStore.items?.find(item => !item.available);

        if (notAvailableItem) {
            logger.info('item not available', { id: notAvailableItem.variant_id });
            giftStore.setItemForReplace(notAvailableItem);
            history.push(ROUTES.ALTERNATIVES);
        }
    }, [ giftStore.items, giftStore.status ]);
}

export function useInitApp() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    useEffect(() => {
        logger.debug('session = ' + getOrCreateUserSessionId())

        trackSgEvent('app-started',
            '3',
            'ozon',
            '0',
            '',
        );

        setIsLoading(true);

        initApp()
            .then(() => initStores())
            .catch(err => logger.error(`fatal error ${err.message}`, { stack: err.stack }))
            .finally(() => setIsLoading(false));
    }, [setIsLoading]);

    return {isLoading};
}

export function useCustomizeThemeByShop() {
    useEffect(() => {

        if (!shopStore.settings) {
            return;
        }

        const style = document.createElement('style');
        const head = document.head || document.getElementsByTagName('head')[0];
        const vh = window.innerHeight * 0.01;

        head.appendChild(style);

        style.type = 'text/css';

        style.appendChild(document.createTextNode(`
        body {
          --vh: ${vh}px;
          --brand-color: ${ shopStore.settings.brandColor };
          --main-font: ${ shopStore.settings.mainFont };
          --textColorMain: ${ shopStore.settings.textColorMain };
          --textColorSecondary: ${ shopStore.settings.textColorSecondary };
          --button-disabled-color: ${hexToHSL(shopStore.settings.brandColor, { h:-0, s:-0, l:+20 })};
          --brand-color-opacity-30: ${hexToHSL(shopStore.settings.brandColor, { h:-0, s:+20, l:+36 })};
          --brand-color-opacity-6: ${shopStore.settings.brandColor}06;
        }
    `));

        head.insertAdjacentHTML('beforeend', `<title>GiftMagic</title>`);
    }, [ shopStore.settings ]);

}
