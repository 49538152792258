import { UserStore } from './user/user';
import { ShopStore } from './shop/shop';
import { GiftStore } from './gift/gift';
import { RecipientStore } from './recipient/recipient';
import { Logger } from '../common/logger';

const logger = new Logger('Stores');
export const userStore = new UserStore();
export const shopStore = new ShopStore();
export const giftStore = new GiftStore();
export const recipientStore = new RecipientStore();

export function isNotStoresReady() {
  const isNotReady = !userStore.isReady || giftStore.isAccepting || giftStore.isCheckoutRedirecting;

  if (!isNotReady) {
    logger.debug('shop_ready', shopStore.settings);
  }

  return isNotReady;
}

export function initStores() {
  logger.debug('init_stores');
  [userStore, shopStore, giftStore, recipientStore].forEach(store => store.init());
}
