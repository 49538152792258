import { toJS, autorun, extendObservable, runInAction } from 'mobx'

export function autoSave(store, name) {
  let firstRun = true

  // will run on change
  autorun(() => {
    // on load check if there's an existing store on localStorage and extend the store
    if (firstRun) {
      const existingStore = sessionStorage.getItem(name);

      if (existingStore) {
        const data = JSON.parse(existingStore);
        runInAction(() => {
          Object.keys(data).forEach((key) => {
            store[key] = data[key];
          });
        })
      }
    }

    // from then on serialize and save to localStorage
    sessionStorage.setItem(name, JSON.stringify(toJS(store)));
  });

  firstRun = false
}
