export const levengerCountries = [
  {title: 'Select Country', value: ''},
  {title: 'USA', value: '0000'},
  {title: 'Canada', value: '0001'},
  {title: 'Germany', value: '0003'},
  {title: 'Argentina', value: '0054'},
  {title: 'Australia', value: '1007'},
  {title: 'Austria', value: '1008'},
  {title: 'Bahamas', value: '1010'},
  {title: 'Bahrain', value: '1011'},
  {title: 'Belgium', value: '1014'},
  {title: 'Bermuda', value: '1017'},
  {title: 'British isles', value: '1023'},
  {title: 'Canary islands', value: '1030'},
  {title: 'Cape vere islands', value: '1032'},
  {title: 'Caroline islands', value: '1033'},
  {title: 'Cayman island', value: '1034'},
  {title: 'Channel islands', value: '1037'},
  {title: 'China', value: '1039'},
  {title: 'Czech republic', value: '1047'},
  {title: 'Denmark', value: '1048'},
  {title: 'Egypt', value: '1054'},
  {title: 'Fiji', value: '1061'},
  {title: 'Finland', value: '1062'},
  {title: 'France', value: '1063'},
  {title: 'French guiana', value: '1064'},
  {title: 'French polynesia', value: '1065'},
  {title: 'Ghana', value: '1070'},
  {title: 'England', value: '1072'},
  {title: 'Greece', value: '1073'},
  {title: 'Greenland', value: '1074'},
  {title: 'Guadeloupe', value: '1076'},
  {title: 'Guam', value: '1077'},
  {title: 'Guyana', value: '1081'},
  {title: 'Hong Kong', value: '1084'},
  {title: 'Hungary', value: '1085'},
  {title: 'Iceland', value: '1086'},
  {title: 'India', value: '1087'},
  {title: 'Indonesia', value: '1088'},
  {title: 'Ireland', value: '1091'},
  {title: 'Israel', value: '1092'},
  {title: 'Italy', value: '1093'},
  {title: 'Japan', value: '1096'},
  {title: 'Jordan', value: '1097'},
  {title: 'South Korea', value: '1101'},
  {title: 'Leeward Islands', value: '1106'},
  {title: 'Liechtenstein', value: '1110'},
  {title: 'Lithuania', value: '1111'},
  {title: 'Luxembourg', value: '1112'},
  {title: 'Macao', value: '1113'},
  {title: 'Malaysia', value: '1117'},
  {title: 'Maldives', value: '1118'},
  {title: 'Malta', value: '1120'},
  {title: 'Martinique', value: '1121'},
  {title: 'Mauritania', value: '1122'},
  {title: 'Maruitius', value: '1123'},
  {title: 'Mexico', value: '1124'},
  {title: 'Monaco', value: '1125'},
  {title: 'Mongolia', value: '1126'},
  {title: 'Morocco', value: '1127'},
  {title: 'Netherlands', value: '1132'},
  {title: 'Netherlands antilles', value: '1133'},
  {title: 'New Caledonia', value: '1134'},
  {title: 'New Zealand', value: '1135'},
  {title: 'Norway', value: '1140'},
  {title: 'Papua new guinea', value: '1144'},
  {title: 'Philippines', value: '1145'},
  {title: 'Poland', value: '1149'},
  {title: 'Portugal', value: '1150'},
  {title: 'Qatar', value: '1151'},
  {title: 'Russia', value: '1154'},
  {title: 'Romania', value: '1155'},
  {title: 'Saint Helena', value: '1157'},
  {title: 'Saint Lucia', value: '1158'},
  {title: 'Santa Cruz Islands', value: '1160'},
  {title: 'Saudi Arabia', value: '1162'},
  {title: 'Singapore', value: '1167'},
  {title: 'Solomon Islands', value: '1168'},
  {title: 'Spain', value: '1172'},
  {title: 'St Vincent & Grenadi', value: '1174'},
  {title: 'Sweden', value: '1178'},
  {title: 'Switzerland', value: '1179'},
  {title: 'Taiwan', value: '1181'},
  {title: 'Thailand', value: '1183'},
  {title: 'Togo', value: '1184'},
  {title: 'Tibet', value: '1185'},
  {title: 'Tunisia', value: '1188'},
  {title: 'Turkey', value: '1189'},
  {title: 'Turks & Caicos Islnd', value: '1190'},
  {title: 'Ukraine', value: '1193'},
  {title: 'United Arab Emirates', value: '1194'},
  {title: 'Uruguay', value: '1196'},
  {title: 'Vatican City', value: '1198'},
  {title: 'Vietnam', value: '1200'},
  {title: 'Virgin Islands', value: '1201'},
  {title: 'Slovenia', value: '1210'},
  {title: 'Cyprus', value: '1213'},
];

// Страны, которые должны быть в верху списка
export const IMPORTANT_COUNTRIES = [
  'United States'
];

export const PROVINCES = {
  Argentina: 'province',
  Australia: 'state',
  Brazil: 'state',
  Canada: 'province',
  Chile: 'region',
  China: 'province',
  Colombia: 'province',
  Egypt: 'governorate',
  Guatemala: 'region',
  ['Hong Kong']: 'region',
  India: 'state',
  Indonesia: 'province',
  Ireland: 'county',
  Italy: 'province',
  Japan: 'prefecture',
  Malaysia: 'state',
  Mexico: 'state',
  ['New Zealand']: 'region',
  Nigeria: 'state',
  Panama: 'region',
  Peru: 'region',
  Philippines: 'region',
  Portugal: 'region',
  Romania: 'county',
  Russia: 'region',
  ['South Africa']: 'province',
  ['South Korea']: 'province',
  Spain: 'province',
  Thailand: 'province',
  ['United Arab Emirates']: 'emirate',
  ['United States']: 'state'
};

export const defaultCountries = [{
  "id": 0,
  "name": "Afghanistan",
  "code": "AF",
  "provinces": []
}, {
  "id": 1,
  "name": "Aland Islands",
  "code": "AX",
  "provinces": []
}, {
  "id": 2,
  "name": "Albania",
  "code": "AL",
  "provinces": []
}, {
  "id": 3,
  "name": "Algeria",
  "code": "DZ",
  "provinces": []
}, {
  "id": 4,
  "name": "American Samoa",
  "code": "AS",
  "provinces": []
}, {
  "id": 5,
  "name": "Andorra",
  "code": "AD",
  "provinces": []
}, {
  "id": 6,
  "name": "Angola",
  "code": "AO",
  "provinces": []
}, {
  "id": 7,
  "name": "Anguilla",
  "code": "AI",
  "provinces": []
}, {
  "id": 8,
  "name": "Antarctica",
  "code": "AQ",
  "provinces": []
}, {
  "id": 9,
  "name": "Antigua And Barbuda",
  "code": "AG",
  "provinces": []
}, {
  "id": 10,
  "name": "Argentina",
  "code": "AR",
  "provinces": []
}, {
  "id": 11,
  "name": "Armenia",
  "code": "AM",
  "provinces": []
}, {
  "id": 12,
  "name": "Aruba",
  "code": "AW",
  "provinces": []
}, {
  "id": 13,
  "name": "Australia",
  "code": "AU",
  "provinces": []
}, {
  "id": 14,
  "name": "Austria",
  "code": "AT",
  "provinces": []
}, {
  "id": 15,
  "name": "Azerbaijan",
  "code": "AZ",
  "provinces": []
}, {
  "id": 16,
  "name": "Bahamas",
  "code": "BS",
  "provinces": []
}, {
  "id": 17,
  "name": "Bahrain",
  "code": "BH",
  "provinces": []
}, {
  "id": 18,
  "name": "Bangladesh",
  "code": "BD",
  "provinces": []
}, {
  "id": 19,
  "name": "Barbados",
  "code": "BB",
  "provinces": []
}, {
  "id": 20,
  "name": "Belarus",
  "code": "BY",
  "provinces": []
}, {
  "id": 21,
  "name": "Belgium",
  "code": "BE",
  "provinces": []
}, {
  "id": 22,
  "name": "Belize",
  "code": "BZ",
  "provinces": []
}, {
  "id": 23,
  "name": "Benin",
  "code": "BJ",
  "provinces": []
}, {
  "id": 24,
  "name": "Bermuda",
  "code": "BM",
  "provinces": []
}, {
  "id": 25,
  "name": "Bhutan",
  "code": "BT",
  "provinces": []
}, {
  "id": 26,
  "name": "Bolivia",
  "code": "BO",
  "provinces": []
}, {
  "id": 27,
  "name": "Bosnia And Herzegovina",
  "code": "BA",
  "provinces": []
}, {
  "id": 28,
  "name": "Botswana",
  "code": "BW",
  "provinces": []
}, {
  "id": 29,
  "name": "Bouvet Island",
  "code": "BV",
  "provinces": []
}, {
  "id": 30,
  "name": "Brazil",
  "code": "BR",
  "provinces": []
}, {
  "id": 31,
  "name": "British Indian Ocean Territory",
  "code": "IO",
  "provinces": []
}, {
  "id": 32,
  "name": "Brunei Darussalam",
  "code": "BN",
  "provinces": []
}, {
  "id": 33,
  "name": "Bulgaria",
  "code": "BG",
  "provinces": []
}, {
  "id": 34,
  "name": "Burkina Faso",
  "code": "BF",
  "provinces": []
}, {
  "id": 35,
  "name": "Burundi",
  "code": "BI",
  "provinces": []
}, {
  "id": 36,
  "name": "Cambodia",
  "code": "KH",
  "provinces": []
}, {
  "id": 37,
  "name": "Cameroon",
  "code": "CM",
  "provinces": []
}, {
  "id": 38,
  "name": "Canada",
  "code": "CA",
  "provinces": []
}, {
  "id": 39,
  "name": "Cape Verde",
  "code": "CV",
  "provinces": []
}, {
  "id": 40,
  "name": "Cayman Islands",
  "code": "KY",
  "provinces": []
}, {
  "id": 41,
  "name": "Central African Republic",
  "code": "CF",
  "provinces": []
}, {
  "id": 42,
  "name": "Chad",
  "code": "TD",
  "provinces": []
}, {
  "id": 43,
  "name": "Chile",
  "code": "CL",
  "provinces": []
}, {
  "id": 44,
  "name": "China",
  "code": "CN",
  "provinces": []
}, {
  "id": 45,
  "name": "Christmas Island",
  "code": "CX",
  "provinces": []
}, {
  "id": 46,
  "name": "Cocos (Keeling) Islands",
  "code": "CC",
  "provinces": []
}, {
  "id": 47,
  "name": "Colombia",
  "code": "CO",
  "provinces": []
}, {
  "id": 48,
  "name": "Comoros",
  "code": "KM",
  "provinces": []
}, {
  "id": 49,
  "name": "Congo",
  "code": "CG",
  "provinces": []
}, {
  "id": 50,
  "name": "Congo, Democratic Republic",
  "code": "CD",
  "provinces": []
}, {
  "id": 51,
  "name": "Cook Islands",
  "code": "CK",
  "provinces": []
}, {
  "id": 52,
  "name": "Costa Rica",
  "code": "CR",
  "provinces": []
}, {
  "id": 53,
  "name": "Cote D'Ivoire",
  "code": "CI",
  "provinces": []
}, {
  "id": 54,
  "name": "Croatia",
  "code": "HR",
  "provinces": []
}, {
  "id": 55,
  "name": "Cuba",
  "code": "CU",
  "provinces": []
}, {
  "id": 56,
  "name": "Cyprus",
  "code": "CY",
  "provinces": []
}, {
  "id": 57,
  "name": "Czech Republic",
  "code": "CZ",
  "provinces": []
}, {
  "id": 58,
  "name": "Denmark",
  "code": "DK",
  "provinces": []
}, {
  "id": 59,
  "name": "Djibouti",
  "code": "DJ",
  "provinces": []
}, {
  "id": 60,
  "name": "Dominica",
  "code": "DM",
  "provinces": []
}, {
  "id": 61,
  "name": "Dominican Republic",
  "code": "DO",
  "provinces": []
}, {
  "id": 62,
  "name": "Ecuador",
  "code": "EC",
  "provinces": []
}, {
  "id": 63,
  "name": "Egypt",
  "code": "EG",
  "provinces": []
}, {
  "id": 64,
  "name": "El Salvador",
  "code": "SV",
  "provinces": []
}, {
  "id": 65,
  "name": "Equatorial Guinea",
  "code": "GQ",
  "provinces": []
}, {
  "id": 66,
  "name": "Eritrea",
  "code": "ER",
  "provinces": []
}, {
  "id": 67,
  "name": "Estonia",
  "code": "EE",
  "provinces": []
}, {
  "id": 68,
  "name": "Ethiopia",
  "code": "ET",
  "provinces": []
}, {
  "id": 69,
  "name": "Falkland Islands (Malvinas)",
  "code": "FK",
  "provinces": []
}, {
  "id": 70,
  "name": "Faroe Islands",
  "code": "FO",
  "provinces": []
}, {
  "id": 71,
  "name": "Fiji",
  "code": "FJ",
  "provinces": []
}, {
  "id": 72,
  "name": "Finland",
  "code": "FI",
  "provinces": []
}, {
  "id": 73,
  "name": "France",
  "code": "FR",
  "provinces": []
}, {
  "id": 74,
  "name": "French Guiana",
  "code": "GF",
  "provinces": []
}, {
  "id": 75,
  "name": "French Polynesia",
  "code": "PF",
  "provinces": []
}, {
  "id": 76,
  "name": "French Southern Territories",
  "code": "TF",
  "provinces": []
}, {
  "id": 77,
  "name": "Gabon",
  "code": "GA",
  "provinces": []
}, {
  "id": 78,
  "name": "Gambia",
  "code": "GM",
  "provinces": []
}, {
  "id": 79,
  "name": "Georgia",
  "code": "GE",
  "provinces": []
}, {
  "id": 80,
  "name": "Germany",
  "code": "DE",
  "provinces": []
}, {
  "id": 81,
  "name": "Ghana",
  "code": "GH",
  "provinces": []
}, {
  "id": 82,
  "name": "Gibraltar",
  "code": "GI",
  "provinces": []
}, {
  "id": 83,
  "name": "Greece",
  "code": "GR",
  "provinces": []
}, {
  "id": 84,
  "name": "Greenland",
  "code": "GL",
  "provinces": []
}, {
  "id": 85,
  "name": "Grenada",
  "code": "GD",
  "provinces": []
}, {
  "id": 86,
  "name": "Guadeloupe",
  "code": "GP",
  "provinces": []
}, {
  "id": 87,
  "name": "Guam",
  "code": "GU",
  "provinces": []
}, {
  "id": 88,
  "name": "Guatemala",
  "code": "GT",
  "provinces": []
}, {
  "id": 89,
  "name": "Guernsey",
  "code": "GG",
  "provinces": []
}, {
  "id": 90,
  "name": "Guinea",
  "code": "GN",
  "provinces": []
}, {
  "id": 91,
  "name": "Guinea-Bissau",
  "code": "GW",
  "provinces": []
}, {
  "id": 92,
  "name": "Guyana",
  "code": "GY",
  "provinces": []
}, {
  "id": 93,
  "name": "Haiti",
  "code": "HT",
  "provinces": []
}, {
  "id": 94,
  "name": "Heard Island & Mcdonald Islands",
  "code": "HM",
  "provinces": []
}, {
  "id": 95,
  "name": "Holy See (Vatican City State)",
  "code": "VA",
  "provinces": []
}, {
  "id": 96,
  "name": "Honduras",
  "code": "HN",
  "provinces": []
}, {
  "id": 97,
  "name": "Hong Kong",
  "code": "HK",
  "provinces": []
}, {
  "id": 98,
  "name": "Hungary",
  "code": "HU",
  "provinces": []
}, {
  "id": 99,
  "name": "Iceland",
  "code": "IS",
  "provinces": []
}, {
  "id": 100,
  "name": "India",
  "code": "IN",
  "provinces": []
}, {
  "id": 101,
  "name": "Indonesia",
  "code": "ID",
  "provinces": []
}, {
  "id": 102,
  "name": "Iran, Islamic Republic Of",
  "code": "IR",
  "provinces": []
}, {
  "id": 103,
  "name": "Iraq",
  "code": "IQ",
  "provinces": []
}, {
  "id": 104,
  "name": "Ireland",
  "code": "IE",
  "provinces": []
}, {
  "id": 105,
  "name": "Isle Of Man",
  "code": "IM",
  "provinces": []
}, {
  "id": 106,
  "name": "Israel",
  "code": "IL",
  "provinces": []
}, {
  "id": 107,
  "name": "Italy",
  "code": "IT",
  "provinces": []
}, {
  "id": 108,
  "name": "Jamaica",
  "code": "JM",
  "provinces": []
}, {
  "id": 109,
  "name": "Japan",
  "code": "JP",
  "provinces": []
}, {
  "id": 110,
  "name": "Jersey",
  "code": "JE",
  "provinces": []
}, {
  "id": 111,
  "name": "Jordan",
  "code": "JO",
  "provinces": []
}, {
  "id": 112,
  "name": "Kazakhstan",
  "code": "KZ",
  "provinces": []
}, {
  "id": 113,
  "name": "Kenya",
  "code": "KE",
  "provinces": []
}, {
  "id": 114,
  "name": "Kiribati",
  "code": "KI",
  "provinces": []
}, {
  "id": 115,
  "name": "Korea",
  "code": "KR",
  "provinces": []
}, {
  "id": 116,
  "name": "Kuwait",
  "code": "KW",
  "provinces": []
}, {
  "id": 117,
  "name": "Kyrgyzstan",
  "code": "KG",
  "provinces": []
}, {
  "id": 118,
  "name": "Lao People's Democratic Republic",
  "code": "LA",
  "provinces": []
}, {
  "id": 119,
  "name": "Latvia",
  "code": "LV",
  "provinces": []
}, {
  "id": 120,
  "name": "Lebanon",
  "code": "LB",
  "provinces": []
}, {
  "id": 121,
  "name": "Lesotho",
  "code": "LS",
  "provinces": []
}, {
  "id": 122,
  "name": "Liberia",
  "code": "LR",
  "provinces": []
}, {
  "id": 123,
  "name": "Libyan Arab Jamahiriya",
  "code": "LY",
  "provinces": []
}, {
  "id": 124,
  "name": "Liechtenstein",
  "code": "LI",
  "provinces": []
}, {
  "id": 125,
  "name": "Lithuania",
  "code": "LT",
  "provinces": []
}, {
  "id": 126,
  "name": "Luxembourg",
  "code": "LU",
  "provinces": []
}, {
  "id": 127,
  "name": "Macao",
  "code": "MO",
  "provinces": []
}, {
  "id": 128,
  "name": "Macedonia",
  "code": "MK",
  "provinces": []
}, {
  "id": 129,
  "name": "Madagascar",
  "code": "MG",
  "provinces": []
}, {
  "id": 130,
  "name": "Malawi",
  "code": "MW",
  "provinces": []
}, {
  "id": 131,
  "name": "Malaysia",
  "code": "MY",
  "provinces": []
}, {
  "id": 132,
  "name": "Maldives",
  "code": "MV",
  "provinces": []
}, {
  "id": 133,
  "name": "Mali",
  "code": "ML",
  "provinces": []
}, {
  "id": 134,
  "name": "Malta",
  "code": "MT",
  "provinces": []
}, {
  "id": 135,
  "name": "Marshall Islands",
  "code": "MH",
  "provinces": []
}, {
  "id": 136,
  "name": "Martinique",
  "code": "MQ",
  "provinces": []
}, {
  "id": 137,
  "name": "Mauritania",
  "code": "MR",
  "provinces": []
}, {
  "id": 138,
  "name": "Mauritius",
  "code": "MU",
  "provinces": []
}, {
  "id": 139,
  "name": "Mayotte",
  "code": "YT",
  "provinces": []
}, {
  "id": 140,
  "name": "Mexico",
  "code": "MX",
  "provinces": []
}, {
  "id": 141,
  "name": "Micronesia, Federated States Of",
  "code": "FM",
  "provinces": []
}, {
  "id": 142,
  "name": "Moldova",
  "code": "MD",
  "provinces": []
}, {
  "id": 143,
  "name": "Monaco",
  "code": "MC",
  "provinces": []
}, {
  "id": 144,
  "name": "Mongolia",
  "code": "MN",
  "provinces": []
}, {
  "id": 145,
  "name": "Montenegro",
  "code": "ME",
  "provinces": []
}, {
  "id": 146,
  "name": "Montserrat",
  "code": "MS",
  "provinces": []
}, {
  "id": 147,
  "name": "Morocco",
  "code": "MA",
  "provinces": []
}, {
  "id": 148,
  "name": "Mozambique",
  "code": "MZ",
  "provinces": []
}, {
  "id": 149,
  "name": "Myanmar",
  "code": "MM",
  "provinces": []
}, {
  "id": 150,
  "name": "Namibia",
  "code": "NA",
  "provinces": []
}, {
  "id": 151,
  "name": "Nauru",
  "code": "NR",
  "provinces": []
}, {
  "id": 152,
  "name": "Nepal",
  "code": "NP",
  "provinces": []
}, {
  "id": 153,
  "name": "Netherlands",
  "code": "NL",
  "provinces": []
}, {
  "id": 154,
  "name": "Netherlands Antilles",
  "code": "AN",
  "provinces": []
}, {
  "id": 155,
  "name": "New Caledonia",
  "code": "NC",
  "provinces": []
}, {
  "id": 156,
  "name": "New Zealand",
  "code": "NZ",
  "provinces": []
}, {
  "id": 157,
  "name": "Nicaragua",
  "code": "NI",
  "provinces": []
}, {
  "id": 158,
  "name": "Niger",
  "code": "NE",
  "provinces": []
}, {
  "id": 159,
  "name": "Nigeria",
  "code": "NG",
  "provinces": []
}, {
  "id": 160,
  "name": "Niue",
  "code": "NU",
  "provinces": []
}, {
  "id": 161,
  "name": "Norfolk Island",
  "code": "NF",
  "provinces": []
}, {
  "id": 162,
  "name": "Northern Mariana Islands",
  "code": "MP",
  "provinces": []
}, {
  "id": 163,
  "name": "Norway",
  "code": "NO",
  "provinces": []
}, {
  "id": 164,
  "name": "Oman",
  "code": "OM",
  "provinces": []
}, {
  "id": 165,
  "name": "Pakistan",
  "code": "PK",
  "provinces": []
}, {
  "id": 166,
  "name": "Palau",
  "code": "PW",
  "provinces": []
}, {
  "id": 167,
  "name": "Palestinian Territory, Occupied",
  "code": "PS",
  "provinces": []
}, {
  "id": 168,
  "name": "Panama",
  "code": "PA",
  "provinces": []
}, {
  "id": 169,
  "name": "Papua New Guinea",
  "code": "PG",
  "provinces": []
}, {
  "id": 170,
  "name": "Paraguay",
  "code": "PY",
  "provinces": []
}, {
  "id": 171,
  "name": "Peru",
  "code": "PE",
  "provinces": []
}, {
  "id": 172,
  "name": "Philippines",
  "code": "PH",
  "provinces": []
}, {
  "id": 173,
  "name": "Pitcairn",
  "code": "PN",
  "provinces": []
}, {
  "id": 174,
  "name": "Poland",
  "code": "PL",
  "provinces": []
}, {
  "id": 175,
  "name": "Portugal",
  "code": "PT",
  "provinces": []
}, {
  "id": 176,
  "name": "Puerto Rico",
  "code": "PR",
  "provinces": []
}, {
  "id": 177,
  "name": "Qatar",
  "code": "QA",
  "provinces": []
}, {
  "id": 178,
  "name": "Reunion",
  "code": "RE",
  "provinces": []
}, {
  "id": 179,
  "name": "Romania",
  "code": "RO",
  "provinces": []
}, {
  "id": 180,
  "name": "Russian Federation",
  "code": "RU",
  "provinces": []
}, {
  "id": 181,
  "name": "Rwanda",
  "code": "RW",
  "provinces": []
}, {
  "id": 182,
  "name": "Saint Barthelemy",
  "code": "BL",
  "provinces": []
}, {
  "id": 183,
  "name": "Saint Helena",
  "code": "SH",
  "provinces": []
}, {
  "id": 184,
  "name": "Saint Kitts And Nevis",
  "code": "KN",
  "provinces": []
}, {
  "id": 185,
  "name": "Saint Lucia",
  "code": "LC",
  "provinces": []
}, {
  "id": 186,
  "name": "Saint Martin",
  "code": "MF",
  "provinces": []
}, {
  "id": 187,
  "name": "Saint Pierre And Miquelon",
  "code": "PM",
  "provinces": []
}, {
  "id": 188,
  "name": "Saint Vincent And Grenadines",
  "code": "VC",
  "provinces": []
}, {
  "id": 189,
  "name": "Samoa",
  "code": "WS",
  "provinces": []
}, {
  "id": 190,
  "name": "San Marino",
  "code": "SM",
  "provinces": []
}, {
  "id": 191,
  "name": "Sao Tome And Principe",
  "code": "ST",
  "provinces": []
}, {
  "id": 192,
  "name": "Saudi Arabia",
  "code": "SA",
  "provinces": []
}, {
  "id": 193,
  "name": "Senegal",
  "code": "SN",
  "provinces": []
}, {
  "id": 194,
  "name": "Serbia",
  "code": "RS",
  "provinces": []
}, {
  "id": 195,
  "name": "Seychelles",
  "code": "SC",
  "provinces": []
}, {
  "id": 196,
  "name": "Sierra Leone",
  "code": "SL",
  "provinces": []
}, {
  "id": 197,
  "name": "Singapore",
  "code": "SG",
  "provinces": []
}, {
  "id": 198,
  "name": "Slovakia",
  "code": "SK",
  "provinces": []
}, {
  "id": 199,
  "name": "Slovenia",
  "code": "SI",
  "provinces": []
}, {
  "id": 200,
  "name": "Solomon Islands",
  "code": "SB",
  "provinces": []
}, {
  "id": 201,
  "name": "Somalia",
  "code": "SO",
  "provinces": []
}, {
  "id": 202,
  "name": "South Africa",
  "code": "ZA",
  "provinces": []
}, {
  "id": 203,
  "name": "South Georgia And Sandwich Isl.",
  "code": "GS",
  "provinces": []
}, {
  "id": 204,
  "name": "Spain",
  "code": "ES",
  "provinces": []
}, {
  "id": 205,
  "name": "Sri Lanka",
  "code": "LK",
  "provinces": []
}, {
  "id": 206,
  "name": "Sudan",
  "code": "SD",
  "provinces": []
}, {
  "id": 207,
  "name": "Suriname",
  "code": "SR",
  "provinces": []
}, {
  "id": 208,
  "name": "Svalbard And Jan Mayen",
  "code": "SJ",
  "provinces": []
}, {
  "id": 209,
  "name": "Swaziland",
  "code": "SZ",
  "provinces": []
}, {
  "id": 210,
  "name": "Sweden",
  "code": "SE",
  "provinces": []
}, {
  "id": 211,
  "name": "Switzerland",
  "code": "CH",
  "provinces": []
}, {
  "id": 212,
  "name": "Syrian Arab Republic",
  "code": "SY",
  "provinces": []
}, {
  "id": 213,
  "name": "Taiwan",
  "code": "TW",
  "provinces": []
}, {
  "id": 214,
  "name": "Tajikistan",
  "code": "TJ",
  "provinces": []
}, {
  "id": 215,
  "name": "Tanzania",
  "code": "TZ",
  "provinces": []
}, {
  "id": 216,
  "name": "Thailand",
  "code": "TH",
  "provinces": []
}, {
  "id": 217,
  "name": "Timor-Leste",
  "code": "TL",
  "provinces": []
}, {
  "id": 218,
  "name": "Togo",
  "code": "TG",
  "provinces": []
}, {
  "id": 219,
  "name": "Tokelau",
  "code": "TK",
  "provinces": []
}, {
  "id": 220,
  "name": "Tonga",
  "code": "TO",
  "provinces": []
}, {
  "id": 221,
  "name": "Trinidad And Tobago",
  "code": "TT",
  "provinces": []
}, {
  "id": 222,
  "name": "Tunisia",
  "code": "TN",
  "provinces": []
}, {
  "id": 223,
  "name": "Turkey",
  "code": "TR",
  "provinces": []
}, {
  "id": 224,
  "name": "Turkmenistan",
  "code": "TM",
  "provinces": []
}, {
  "id": 225,
  "name": "Turks And Caicos Islands",
  "code": "TC",
  "provinces": []
}, {
  "id": 226,
  "name": "Tuvalu",
  "code": "TV",
  "provinces": []
}, {
  "id": 227,
  "name": "Uganda",
  "code": "UG",
  "provinces": []
}, {
  "id": 228,
  "name": "Ukraine",
  "code": "UA",
  "provinces": []
}, {
  "id": 229,
  "name": "United Arab Emirates",
  "code": "AE",
  "provinces": []
}, {
  "id": 230,
  "name": "United Kingdom",
  "code": "GB",
  "provinces": []
}, {
  "id": 231,
  "name": "United States",
  "code": "US",
  "provinces": [{
    "id": 231,
    "name": "Alabama"
  }, {
    "id": 231,
    "name": "Alaska"
  }, {
    "id": 231,
    "name": "American Samoa"
  }, {
    "id": 231,
    "name": "Arizona"
  }, {
    "id": 231,
    "name": "Arkansas"
  }, {
    "id": 231,
    "name": "California"
  }, {
    "id": 231,
    "name": "Colorado"
  }, {
    "id": 231,
    "name": "Connecticut"
  }, {
    "id": 231,
    "name": "Delaware"
  }, {
    "id": 231,
    "name": "District of Columbia"
  }, {
    "id": 231,
    "name": "Federated States of Micronesia"
  }, {
    "id": 231,
    "name": "Florida"
  }, {
    "id": 231,
    "name": "Georgia"
  }, {
    "id": 231,
    "name": "Guam"
  }, {
    "id": 231,
    "name": "Hawaii"
  }, {
    "id": 231,
    "name": "Idaho"
  }, {
    "id": 231,
    "name": "Illinois"
  }, {
    "id": 231,
    "name": "Indiana"
  }, {
    "id": 231,
    "name": "Iowa"
  }, {
    "id": 231,
    "name": "Kansas"
  }, {
    "id": 231,
    "name": "Kentucky"
  }, {
    "id": 231,
    "name": "Louisiana"
  }, {
    "id": 231,
    "name": "Maine"
  }, {
    "id": 231,
    "name": "Marshall Islands"
  }, {
    "id": 231,
    "name": "Maryland"
  }, {
    "id": 231,
    "name": "Massachusetts"
  }, {
    "id": 231,
    "name": "Michigan"
  }, {
    "id": 231,
    "name": "Minnesota"
  }, {
    "id": 231,
    "name": "Mississippi"
  }, {
    "id": 231,
    "name": "Missouri"
  }, {
    "id": 231,
    "name": "Montana"
  }, {
    "id": 231,
    "name": "Nebraska"
  }, {
    "id": 231,
    "name": "Nevada"
  }, {
    "id": 231,
    "name": "New Hampshire"
  }, {
    "id": 231,
    "name": "New Jersey"
  }, {
    "id": 231,
    "name": "New Mexico"
  }, {
    "id": 231,
    "name": "New York"
  }, {
    "id": 231,
    "name": "North Carolina"
  }, {
    "id": 231,
    "name": "North Dakota"
  }, {
    "id": 231,
    "name": "Northern Mariana Islands"
  }, {
    "id": 231,
    "name": "Ohio"
  }, {
    "id": 231,
    "name": "Oklahoma"
  }, {
    "id": 231,
    "name": "Oregon"
  }, {
    "id": 231,
    "name": "Palau"
  }, {
    "id": 231,
    "name": "Pennsylvania"
  }, {
    "id": 231,
    "name": "Puerto Rico"
  }, {
    "id": 231,
    "name": "Rhode Island"
  }, {
    "id": 231,
    "name": "South Carolina"
  }, {
    "id": 231,
    "name": "South Dakota"
  }, {
    "id": 231,
    "name": "Tennessee"
  }, {
    "id": 231,
    "name": "Texas"
  }, {
    "id": 231,
    "name": "Utah"
  }, {
    "id": 231,
    "name": "Vermont"
  }, {
    "id": 231,
    "name": "Virginia"
  }, {
    "id": 231,
    "name": "Washington"
  }, {
    "id": 231,
    "name": "West Virginia"
  }, {
    "id": 231,
    "name": "Wisconsin"
  }, {
    "id": 231,
    "name": "Wyoming"
  }, {
    "id": 231,
    "name": "Virgin Islands"
  }, {
    "id": 231,
    "name": "Armed Forces Americas"
  }, {
    "id": 231,
    "name": "Armed Forces Europe"
  }, {
    "id": 231,
    "name": "Armed Forces Pacific"
  }]
}, {
  "id": 232,
  "name": "United States Outlying Islands",
  "code": "UM",
  "provinces": []
}, {
  "id": 233,
  "name": "Uruguay",
  "code": "UY",
  "provinces": []
}, {
  "id": 234,
  "name": "Uzbekistan",
  "code": "UZ",
  "provinces": []
}, {
  "id": 235,
  "name": "Vanuatu",
  "code": "VU",
  "provinces": []
}, {
  "id": 236,
  "name": "Venezuela",
  "code": "VE",
  "provinces": []
}, {
  "id": 237,
  "name": "Viet Nam",
  "code": "VN",
  "provinces": []
}, {
  "id": 238,
  "name": "Virgin Islands, British",
  "code": "VG",
  "provinces": []
}, {
  "id": 239,
  "name": "Virgin Islands, U.S.",
  "code": "VI",
  "provinces": []
}, {
  "id": 240,
  "name": "Wallis And Futuna",
  "code": "WF",
  "provinces": []
}, {
  "id": 241,
  "name": "Western Sahara",
  "code": "EH",
  "provinces": []
}, {
  "id": 242,
  "name": "Yemen",
  "code": "YE",
  "provinces": []
}, {
  "id": 243,
  "name": "Zambia",
  "code": "ZM",
  "provinces": []
}, {
  "id": 244,
  "name": "Zimbabwe",
  "code": "ZW",
  "provinces": []
}];
