import React, {useState} from 'react';
import './checkbox.scss';

export interface ICheckboxProps {
  name: string,
  text?: string,
  link?: string,
  href?: string,
  onChange?: (event) => void;
  variant?: string
}

export function Checkbox (props: ICheckboxProps) {
  const [ isChecked, setChecked ] = useState<boolean>(false);

  return (
    <label className={`check ${props.variant ? 'check_variant-' + props.variant : ''} `}>
      <input className={ 'check__input' } type={'checkbox'} name={props.name}
      onChange={(event) => {
        setChecked(event.target.checked);
        props.onChange && props.onChange(isChecked);
      }}/>
      <span className={ `check__box ${props.variant ? 'check__box_variant-' + props.variant : ''}` }/>
      <span className={`check__text ${props.variant ? 'check__text_variant-' + props.variant : ''}`}>{props.text}</span>
    </label>
  );
}
