import React from 'react';
import { observer } from 'mobx-react-lite';
import { shopStore, giftStore, isNotStoresReady } from '../stores';
import { Loading } from './components/loading/loading';
import './app.scss';
import './theme/levenger.scss';
import './theme/giftosa.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Favicon from 'react-favicon'
import { RecipientPage } from './pages/sender/recipient';
import { SenderPage } from './pages/sender/sender';
import { getCurrentTheme, getQueryParams } from '../common/utils';
import { useInitApp, useCustomizeThemeByShop } from '../hooks'

import Header from './components/header/header';
import Footer from './components/footer/footer';
import { GaAnalytics } from './components/analytics';
import * as Sentry from "@sentry/react";
import { OccasionPage } from './pages/sender/occasion';
import { SelectWrapTypePage } from './pages/sender/select-wrap-type';
import { RecipientDeliveryPage } from './pages/sender/recipient-delivery-info';
import { SharePage } from './pages/sender/share';
import { AlternativesPage } from './pages/alternatives';
import { ErrorPage } from './pages/error';
import { GiftBoxPage } from './pages/recipient/gift-box';
import { AcceptFormPage } from './pages/recipient/accept-form';
import { FinishPage } from './pages/recipient/finish';
import { ROUTES } from '../common/conts';
import { Logger } from '../common/logger';
import { sendMetric } from "../common/metrics";
import { GiftcardPage } from './pages/giftcard/giftcard';
import { GiftcardBackThanksPage } from './pages/giftcard/giftcard-back-thanks';
import { SharePopup } from "./components/SharePopup/SharePopup";


Sentry.init({ dsn: 'http://1d4ddb1146b04b0f9b7cbbc69620ef6b@sentry2.swiftgift.me:9000/3' });

const logger = new Logger('App');

// Запуск приложнеия
// - Загружаем магазин
//    - Если ок - то загружаем подарок
//    - Если подарок ок - то запускаем сессию пользователя и отправляем newgift_started.

const App = observer( () => {
  useInitApp();
  useCustomizeThemeByShop();

  const params = getQueryParams();

  if (giftStore.isVariantsNotFound) {
    return (
      <div className={'app'}>
        <div className={ 'wrapper-for-mobile-height' }>
          <Router>
            {!shopStore.fromApp && !shopStore.fromIframe && ( <Header /> )}
            <ErrorPage/>
            {!shopStore.fromIframe && ( <Footer/> )}
          </Router>
        </div>
      </div>
    );
  }

  if (isNotStoresReady()) {
    return (
      <div className={'app'}>
        <Loading/>
      </div>
    );
  }

  return(
    <div className={`app ${ getCurrentTheme() }`}>
      { shopStore.settings?.favicon && (
        <Favicon url={ shopStore.settings?.favicon } />
      )}
      <GaAnalytics gaAppId={shopStore.settings?.gaAppId}/>
      <div className={ 'wrapper-for-mobile-height' }>
        {params.sender && (
          <SharePopup/>
        )}
        <Router>
          {!shopStore.fromApp && !shopStore.fromIframe && ( <Header /> )}
          <Switch>
            <Route exact path={ROUTES.RECIPIENT_FORM}>
              <RecipientPage />
            </Route>
            <Route exact path={ROUTES.SENDER_FORM}>
              <SenderPage />
            </Route>
            <Route exact path={ROUTES.OCCASION}>
              <OccasionPage />
            </Route>
            <Route exact path={ROUTES.SELECT_WRAP_TYPE}>
              <SelectWrapTypePage />
            </Route>
            <Route exact path={ROUTES.DELIVERY_FORM}>
              <RecipientDeliveryPage/>
            </Route>
            <Route exact path={ROUTES.SHARE_LINK}>
              <SharePage />
            </Route>
            <Route exact path={ROUTES.ALTERNATIVES}>
              <AlternativesPage />
            </Route>
            <Route exact path={ROUTES.RECIPIENT_ALTERNATIVES}>
              <AlternativesPage isRecipient={true} />
            </Route>
            <Route exact path={ROUTES.FINISH}>
              <FinishPage />
            </Route>
            <Route exact path={ROUTES.GIFT_BOX}>
              <GiftBoxPage />
            </Route>
            <Route exact path={ROUTES.ACCEPT_FORM}>
              <AcceptFormPage  />
            </Route>
            <Route exact path={ROUTES.GIFT_CARD}>
              <GiftcardPage  />
            </Route>
            <Route exact path={ROUTES.GIFT_CARD_THANKS}>
              <GiftcardBackThanksPage  />
            </Route>
            <Route exact path={ROUTES.ERROR}>
              <ErrorPage  />
            </Route>
          </Switch>
        </Router>
      </div>
      {!shopStore.fromIframe && ( <Footer/> )}
    </div>
  );
});

export default Sentry.withErrorBoundary(App, {
  fallback: (
    <div className={'app'}>
      <div className={ 'wrapper-for-mobile-height' }>
        <Router>
          <Header />
          <ErrorPage/>
          <Footer/>
        </Router>
      </div>
    </div>
  ),
  onError(error) {
    sendMetric('newgift_recipientinfo_error');
    logger.error('crashed', error);
  }
});
