import React from 'react';
import './buttons.scss';

interface IButtonProps {
  children?: React.ReactChild | React.ReactChildren;
  name: string,
  type: "button" | "submit" | "reset" | undefined,
  text: string,
  disabled?: boolean,
  theme?: string,
  variant?: string,
  size?: string,
  onClick?: () => void,
  className?: string,
}

export function Button(props: IButtonProps) {
  const { className = '' } = props;

  function handleClick(event) {
    if (props.disabled) {
      event.preventDefault();
      return;
    }

    props.onClick && props.onClick()
  }

  const disabled = () => {
      return('button_disabled-default');
  };

  const theme = () => {
    return( 'button_variant-default' );
  };
  return (
    <button
      className={
        `button  ${ props.variant ? 'button_' + props.variant : `${ theme() }` } ${props.size ? 'button_size-' + props.size : ''} ${ props.disabled ? `${ disabled() }` : ''} ${ className }`
      }
      onClick={handleClick}
      type={props.type}
    >{ props.children }{ props.text }</button>
  )
}
