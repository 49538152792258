import React from 'react';
import './loading.scss';

export function Loading(){
  return (
    <div className={ 'loading' }>
			<div className={'loading__spin'} />
			<div className={'loading__gift'} />
    </div>
  );
}
