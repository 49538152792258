import Cookies from "universal-cookie/es6";
import { v4 as uuid4 } from 'uuid'
import { parse } from 'query-string';
import { ABTEST, USER_SESSION_HEADER, USER_UUID_HEADER } from './conts';
import { shopStore, userStore } from '../stores';
import { ICountry } from '../stores/shop/shop';

export function getUserUUID() {
  const cookies = new Cookies();
  if (!cookies.get('uid')) {
    cookies.set('uid', uuid4())
  }
  return cookies.get('uid')
}

export function getGiftId() {
  const segs = window.location.pathname.match(/\/(gift|giftcard|giftcard-gift|giftcard-form)\/([^\/^\?]+)/);

  if (segs) {
    return segs[2];
  }

  return '';
}

export function getDefaultHeaders(): { [ key: string ]: string } {
  return {
    [USER_UUID_HEADER]: userStore.uuid,
    [USER_SESSION_HEADER]: getOrCreateUserSessionId(),
  }
}

export function getShopSlug() {
  let slug = window.location.hostname.split('.').shift() || '';

  if (slug === 'ozon-new') {
    slug = 'ozon';
  }

  return slug;
}

let sessionId: string | null = null;
const SESSION_KEY_NAME = 'gm_session_id';
const LANDING_TRACK_ID_NAME = 'gm_landing_track_id';

export function getOrCreateUserSessionId(): string {
  const trackId = localStorage.getItem(LANDING_TRACK_ID_NAME);
  if (trackId) {
    console.debug('set session from landing');
    sessionId = trackId;
    sessionStorage.setItem(SESSION_KEY_NAME, sessionId || '');
    return sessionId;
  }

  if (sessionId) {
    return sessionId;
  }
  sessionId = sessionStorage.getItem(SESSION_KEY_NAME);
  if (!sessionId) {
    sessionId = uuid4();
    sessionStorage.setItem(SESSION_KEY_NAME, sessionId || '');
  }
  return sessionId || '';
}


export function getQueryParams(): {[ key: string ] : any } {
  const data = parse(window.location.search);
  const result = {};
  const groupRegexp = /(.*)\[(\d+)\]\[(.*)\]/;

  for (const key in data) {
    if (groupRegexp.test(key)) {
      const match =  key.match(groupRegexp);

      if (!match) {
        continue;
      }

      const [, field, number, prop ] = match;

      if (!result[field]) {
        result[field] = {};
      }
      if (!result[field][number]) {
        result[field][number] = {};
      }

      result[field][number][prop] = data[key];
    } else {
      result[key] = data[key];
    }
  }

  for (const key in result) {
    if (result[key] && typeof result[key]['0'] === 'object') {
      result[key].length = Object.keys(result[key]).length;
      result[key] = Array.from(result[key]);
    }
  }

  return result;
}

export function getRef() {
  if (window.location.search.indexOf('glcf1') !== -1 ) {
    return 'glcf1';
  } else if (window.location.search.indexOf('glcf2') !== -1) {
    return 'glcf2';
  } else if (window.location.search.indexOf('sglcf') !== -1) {
    return 'sglcf';
  } else if (window.location.search.indexOf('sgpstfs') !== -1) {
      return 'sgpstfs';
  } else if (window.location.search.indexOf('sgpstfr') !== -1) {
      return 'sgpstfr';
  } else if (window.location.search.indexOf('sglscfr') !== -1) {
      return 'sglscfr'; //double
  } else if (window.location.search.indexOf('glscfs') !== -1) {
      return 'glscfs';
  } else if (window.location.search.indexOf('sgascf') !== -1) {
      return 'sgascf';
  } else if (window.location.search.indexOf('sgpscfr') !== -1) {
      return 'sgpscfr';
  } else if (window.location.search.indexOf('sglrscfr') !== -1) {
      return 'sglrscfr';
  //SF TC communication point
  } else if (window.location.search.indexOf('stcgsre') !== -1) {
      return 'stcgsre';
  } else if (window.location.search.indexOf('stcgsse') !== -1) {
      return 'stcgsse';
  } else if (window.location.search.indexOf('sglscfr') !== -1) {
      return 'sglscfr'; //double
  } else if (window.location.search.indexOf('stccgtr') !== -1) {
      return 'stccgtr';
  } else {
    return '';
  }
}

/* tslint:disable */
export function hexToHSL(H, opts) {
  // Convert hex to RGB first
  let r = 0, g = 0, b = 0;
  if (H.length == 4) {
    // @ts-ignore
    r = "0x" + H[1] + H[1];
    // @ts-ignore
    g = "0x" + H[2] + H[2];
    // @ts-ignore
    b = "0x" + H[3] + H[3];
  } else if (H.length == 7) {
    // @ts-ignore
    r = "0x" + H[1] + H[2];
    // @ts-ignore
    g = "0x" + H[3] + H[4];
    // @ts-ignore
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r,g,b),
    cmax = Math.max(r,g,b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;
  if (delta == 0)
    h = 0;
  else if (cmax == r)
    h = ((g - b) / delta) % 6;
  else if (cmax == g)
    h = (b - r) / delta + 2;
  else
    h = (r - g) / delta + 4;
  h = Math.round(h * 60);
  if (h < 0)
    h += 360;
  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);
  if (opts) {
    h = h + opts.h;
    s = s + opts.s;
    l = l + opts.l;
  }
  return "hsl(" + h + "," + s + "%," + l + "%)";
}
/* tslint:enable */

export function objectToQS(params: {[key: string]: string | null}): string {

  function getQsValue(params, prefix = ''): string[] {
    let result: string[] = [];
    for (const key of Object.keys(params)) {
      const item = params[key];

      if (typeof item === 'string') {
        result.push(`${prefix ? `${prefix}[${key}]` : key}=${item}`);
        continue;
      }

      if (Array.isArray(item)) {
        (item as []).forEach((subItem, i) => {
          if (typeof subItem === 'string') {
            result.push(`${prefix ? `${prefix}[${key}]` : key}[${i}]=${subItem}`);
            return;
          }

          result = result.concat(getQsValue(subItem, `${key}[${i}]`));
        });
      }
    }

    return result;
  }

  return getQsValue(params).join('&');
}

export function upperCaseFirstLetter (str) {
    if (str !== undefined) {
        const arrayString = str.split(' ');
        const result = arrayString.reduce((acc, current) => `${acc} ${current.slice(0,1).toUpperCase()+current.slice(1)}`, '');
        return result.trim();
    } else {
        return '';
    }
}

export function getCurrentTheme() {
  let classPartner;
  if ( shopStore.settings?.subdomain_name === 'levenger') {
    classPartner = 'levenger'
  } else if ( shopStore.settings?.subdomain_name === 'pollini') {
    classPartner = 'pollini'
  } else if ( shopStore.settings?.subdomain_name === 'ozon') {
    classPartner = 'ozon'
  } else if ( shopStore.settings?.subdomain_name === 'app-giftosa-de' ) {
    classPartner = 'giftosa'
  } else if ( shopStore.settings?.subdomain_name === 'sg' ) {
    classPartner = 'sg'
  } else {
    classPartner = ''
  }
  return 'theme-' + classPartner;
}

export const getPrice = (cost, currency) => {
  if (!cost) return '';

  return cost.toLocaleString('en-US', {
          style: 'currency',
          currency: currency ?? 'USD',
      });
};

export const getOptions = (product) => {
  const keys = Object.keys(product).filter((item) => /option\d/.test(item));
  return keys.map(option => product[option]).join(' / ');
};

export const getTotalPrice = (items) => {
  const roundedTotal = items.reduce((acc, item) => {
      return acc + item.price * item.quantity * 100;
  }, 0);

  return roundedTotal / 100;
};

export const getTotalQuantity = (items) => {
  return items.reduce((acc, item) => {
      return acc + item.quantity;
  }, 0);
};

export const findCountry = (countries:ICountry[], code:string | undefined) => {
  if (!code) return '';
  const country = countries.find((item) => code === item.code);
  return country ? country.name : '';
};

export const checkAvailableItem = (products) => {
  const notAvailableItem = products?.find(item => !item.available);
  return !!notAvailableItem;
};

export const isMobile = {
  Android: function() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: function() {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
};

export const isDefaultCheckedCF = () => {
  const slug = window.location.hostname.split('.').shift() || '';
  return slug === 'trueclassic';
};

export const checkDefaultABTest = (experiment, variation) => {
  if (experiment === ABTEST.MOBILE_SHARING || experiment === ABTEST.MOBILE_SHARING_TEST) {
    const result = variation === '1' ? 1 : 0;
    return !result;
  }

  return true;
};

export const isSenderSuperFlow = () => userStore.isSenderSuperflow;
export const isClassicFlow = () => userStore.isClassicFlow;