import React, {ReactElement, useEffect, useState} from 'react';
import { observer } from 'mobx-react-lite';
import { giftStore, shopStore } from '../../../stores';
import './gift.scss';
import { GiftRibbon } from '../gift-ribbon/gift-ribbon';
import { BoxRibbon } from '../box-ribbon/box-ribbon';
import cn from 'classnames';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import  DefaultBanner from '../../../image/banner-default.png';

interface IGiftProps {
  hidden?: boolean,
  theme?: string,
  variant?: 'fullView' | 'boxView',
  covered?: 'covered' | 'opened',
  hiddenCover?: boolean,
  optionsSelector?: (selectedVariantId: string, title: string) => ReactElement,
}

const MAX_ITEMS_IN_THUMB = 8;

const Gift = observer((props: IGiftProps) => {
  const { items = [] } = giftStore;
  const { settings } = shopStore;
  const { variant = 'box' } = props;
  const [ selectedItemIndex, setSelectedItemIndex ] = useState(0);

  // slider status customization
  function statusFormatter (currentItem: number, total: number) {
    if (items.length <= 1) {
      return '';
    } else if (settings?.lang === 'ru') {
      return `${currentItem} из ${total} подарков`;
    } else if (settings?.lang === 'de') {
      return `${currentItem} von ${total} geshenke`;
    } else if (settings?.lang === 'it') {
      return `${currentItem} dei ${total} regali`;
    } else {
      return `Gift ${currentItem} of ${total}`;
    }
  }

  const isOverflowed = items.length >= MAX_ITEMS_IN_THUMB;
  const cShift =  selectedItemIndex + 1 - MAX_ITEMS_IN_THUMB;
  let smallFromStart = false;

  let thumbsBorders = {
    from: 0,
    to: MAX_ITEMS_IN_THUMB - 1,
  }

  if (cShift >= 0) {
    smallFromStart = true;
    thumbsBorders.from = cShift;
    thumbsBorders.to = MAX_ITEMS_IN_THUMB - 1 + cShift;
  }

  function renderIndicator(clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void, isSelected: boolean, index: number, label: string) {
    if (items.length === 1) {
      return '';
    }

    let isSmall = false;

    if (index < thumbsBorders.from || index > thumbsBorders.to) {
      return '';
    }

    if (isOverflowed && smallFromStart && index === thumbsBorders.from) {
      isSmall = true;
    }

    if (isOverflowed && !smallFromStart && index === thumbsBorders.to) {
      isSmall = true;
    }

    return <div
      key={index}
      onClick={clickHandler}
      className={cn('gift__thumb-dot', {
        'gift__thumb-dot_selected': isSelected,
        'gift__thumb-dot_small': isSmall,
      })}
    />;
  }

  useEffect(() => {
    let item = items[selectedItemIndex]
    if (!item) {
      setSelectedItemIndex(0);
      item = items[0];
    }
    if (giftStore.items) {
      giftStore.loadOptions(shopStore.settings?.subdomain_name, item.variant_id);
    }
  }, [selectedItemIndex, items, shopStore.settings]);

  return (
    <div className={ `gift ${ props.hidden  ? 'gift_hidden' : ''} ${ props.theme ? 'gift_theme-' + props.theme : '' }` }>
      {/* sender + recipient */}
      { props.covered === 'opened' && (
        <>
          { variant === 'boxView' && ( <BoxRibbon className={ 'gift__box-ribbon' } brandColor={ settings?.brandColor || '#3678DC' } /> ) }
          <div className={ `${ variant ? 'gift__wrapper-' + variant : '' }` }>
            <Carousel
              key={items.length}
              className={variant === 'boxView' ? 'boxView' : 'fullView'}
              renderIndicator={renderIndicator}
              statusFormatter={statusFormatter}
              infiniteLoop={true}
              showArrows={true}
              showStatus={true}
              showThumbs={false}
              onChange={(index) => {setSelectedItemIndex(index)}}
            >
              {items.map(item => (
                <div key={item.variant_id}>
                  <img src={item.image_url} />
                  { variant === 'boxView' && ( <GiftRibbon className={ 'gift__gift-ribbon' } brandColor={ settings?.brandColor || '#3678DC' }  /> ) }
                </div>
              ))}
            </Carousel>

            {/* gift name under product / recipient flow */}
            {variant === 'fullView' && !giftStore.isOptionsLoading && items[selectedItemIndex] && (
              <>
                { variant === 'fullView' && ( <span className={ 'gift__legend' }>{  items[selectedItemIndex].name }</span> ) }
              </>
            )}

            {/* options selector */}
            {variant === 'fullView' && !!props.optionsSelector && giftStore.items && items[selectedItemIndex] && (
              <div className="gift__option" key={items[selectedItemIndex].variant_id}>
                {props.optionsSelector(items[selectedItemIndex].variant_id, items[selectedItemIndex].name)}
              </div>
            )}
          </div>

          {giftStore.items && items[selectedItemIndex] && (Number(items[selectedItemIndex].quantity) > 1) && (
            <span className={'gift__quantity gift__quantity_' + (variant === 'boxView' ? 'boxView' : 'fullView')  }>{items[selectedItemIndex].quantity}</span>
          )}

          {/* banner */}
          {variant === 'boxView' && (
            <div className={ 'gift__brand-mask-box' }>
              { settings?.background ? (
                <img className="gift__brand-mask-image" src={ settings?.background } alt={'mask image with brand texture'} width='160px'/>
              ) : (
                <img className="gift__brand-mask-image" src={ DefaultBanner } alt={'mask image with brand texture'} width='160px'/>
              )}
            </div>
          )}
        </>
      ) }
    </div>
  )
});

export default Gift;
