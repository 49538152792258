import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { shopStore } from '../../../stores';
import { Modal } from '../modal/modal';
import './footer.scss';
import {i18n} from '../../locales';
import {ROUTES} from '../../../common/conts';
import {getQueryParams} from '../../../common/utils';

const Footer = observer(() => {
  const { settings } = shopStore;
  const [selectedView, setSelectedView] = useState<string>();
  const host = window.location.host;
  const protocol = window.location.protocol;
  const showFAQSenderFirst = [
    ROUTES.RECIPIENT_FORM,
    ROUTES.SENDER_FORM,
    ROUTES.OCCASION,
    ROUTES.SELECT_WRAP_TYPE,
    ROUTES.DELIVERY_FORM,
    ROUTES.SHARE_LINK].includes(window.location.pathname);
  const params = getQueryParams();

  return (
    <section className={'footer footer_theme-default'}>
      <div className={ 'footer__item' }>
        <a className={ 'footer__link' } href={settings?.siteUrl} target="_blank" rel="noopener noreferrer" tabIndex={0}>{settings?.name[0].toUpperCase()}{settings?.name.slice(1)}</a>
        <span className={ 'footer__link-slash' }>&#124;</span>
        <span className={ 'footer__partner' }>{ i18n( 'common.navigation.poweredByGiftmagic', {GiftMagic: (shopStore.appName === 'mygiftmagic' ? 'MyGiftMagic':'GiftMagic')} ) }</span>
      </div>
      <div className={ 'footer__item' }>
        <button className={ 'footer__link' } onClick={ () => setSelectedView('termsOfUse') }>{ i18n( 'common.navigation.termsOfUse' ) }</button>
        <span className={ 'footer__link-slash' }>&#124;</span>
        <button className={ 'footer__link' } onClick={ () =>  setSelectedView( 'howItWorks' )}>{ i18n( 'common.navigation.faq' ) }</button>
        <span className={ 'footer__link-slash' }>&#124;</span>
        <a className={ 'footer__link' } href={`${protocol}//${host}/pages/privacy-policy`} target="_blank" rel="noopener noreferrer" tabIndex={0}>{i18n('common.navigation.privacy')}</a>
      </div>

      <Modal hidden={ selectedView !== 'howItWorks' } hiddenVariant={'hidden-default'} onClose={() => setSelectedView('')} theme={ 'footer-modal' } >
        <section className={ 'footer-modal' }>
          <div className={ 'footer-modal__title-hiw' }>{ i18n('modal.hiw.title') }</div>
          <div className={ 'footer-modal__wrap' }>
            { showFAQSenderFirst || params.sender ? (
              <>
                {/*sender part*/}
                <h3 className={ 'footer-modal__subtitle-hiw' }>{ i18n('modal.hiw.subtitle1') }</h3>
                <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question1') }</p>
                <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer1-1') }<a className={ 'footer-modal__link-hiw' } href="http://getgiftmagic.com" target="_blank" rel="noopener noreferrer">{shopStore.appName === 'mygiftmagic' ? 'MyGiftMagic':'GiftMagic'}</a>{ i18n('modal.hiw.answer1-2') }</p>
                <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question2') }</p>
                <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer2') }</p>
                <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question3') }</p>
                <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer3') }</p>
                <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question4') }</p>
                <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer4') }</p>
                <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question5') }</p>
                <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer5-1') }<a className={ 'footer-modal__link-hiw' } href="mailto:support@gogiftmagic.com">support@gogiftmagic.com</a>{ i18n('modal.hiw.answer5-2') }</p>
                {/*recipient part*/}
                <h3 className={ 'footer-modal__subtitle-hiw' }>{ i18n('modal.hiw.subtitle2') }</h3>
                <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question6') }</p>
                <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer6-1') }<a className={ 'footer-modal__link-hiw' } href="http://getgiftmagic.com" target="_blank" rel="noopener noreferrer">{shopStore.appName === 'mygiftmagic' ? 'MyGiftMagic':'GiftMagic'}</a>{ i18n('modal.hiw.answer6-2') }</p>
                <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question7') }</p>
                <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer7-1') }{settings?.name ? settings?.name[0].toUpperCase()+settings?.name.slice(1) : 'shop'}{ i18n('modal.hiw.answer7-2' ) }</p>
                <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question8') }</p>
                <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer8-1') }{settings?.name ? settings?.name[0].toUpperCase()+settings?.name.slice(1) : 'shop'}{ i18n('modal.hiw.answer8-2') }</p>
                <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question9') }</p>
                <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer9') }</p>
                <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question10') }</p>
                <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer10-1') }<a className={ 'footer-modal__link-hiw' } href="mailto:support@gogiftmagic.com">support@gogiftmagic.com</a>{ i18n('modal.hiw.answer10-2') }</p>
              </>
              ) : (
                <>
                  {/*recipient part*/}
                  <h3 className={ 'footer-modal__subtitle-hiw' }>{ i18n('modal.hiw.subtitle2') }</h3>
                  <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question6') }</p>
                  <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer6-1') }<a className={ 'footer-modal__link-hiw' } href="http://getgiftmagic.com" target="_blank" rel="noopener noreferrer">{shopStore.appName === 'mygiftmagic' ? 'MyGiftMagic':'GiftMagic'}</a>{ i18n('modal.hiw.answer6-2') }</p>
                  <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question7') }</p>
                  <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer7-1') }{settings?.name ? settings?.name[0].toUpperCase()+settings?.name.slice(1) : 'shop'}{ i18n('modal.hiw.answer7-2' ) }</p>
                  <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question8') }</p>
                  <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer8-1') }{settings?.name ? settings?.name[0].toUpperCase()+settings?.name.slice(1) : 'shop'}{ i18n('modal.hiw.answer8-2') }</p>
                  <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question9') }</p>
                  <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer9') }</p>
                  <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question10') }</p>
                  <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer10-1') }<a className={ 'footer-modal__link-hiw' } href="mailto:support@gogiftmagic.com">support@gogiftmagic.com</a>{ i18n('modal.hiw.answer10-2') }</p>
                  {/*sender part*/}
                  <h3 className={ 'footer-modal__subtitle-hiw' }>{ i18n('modal.hiw.subtitle1') }</h3>
                  <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question1') }</p>
                  <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer1-1') }<a className={ 'footer-modal__link-hiw' } href="http://getgiftmagic.com" target="_blank" rel="noopener noreferrer">{shopStore.appName === 'mygiftmagic' ? 'MyGiftMagic':'GiftMagic'}</a>{ i18n('modal.hiw.answer1-2') }</p>
                  <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question2') }</p>
                  <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer2') }</p>
                  <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question3') }</p>
                  <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer3') }</p>
                  <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question4') }</p>
                  <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer4') }</p>
                  <p className={ 'footer-modal__paragraph-hiw_bold' }>{ i18n('modal.hiw.question5') }</p>
                  <p className={ 'footer-modal__paragraph-hiw' }>{ i18n('modal.hiw.answer5-1') }<a className={ 'footer-modal__link-hiw' } href="mailto:support@gogiftmagic.com">support@gogiftmagic.com</a>{ i18n('modal.hiw.answer5-2') }</p>
                </>
            )}
            </div>
        </section>
      </Modal>

      <Modal hidden={ selectedView !== 'termsOfUse' } hiddenVariant={'hidden-default'} onClose={() => setSelectedView('')} theme={ 'footer-modal' } >
        <section className={ 'footer-modal' }>
          <div className={ 'footer-modal__title-tou' }>{ i18n('modal.tou.title') }</div>
          <div className={ 'footer-modal__wrap' } tabIndex={0}>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph1') }</p>
            <h3 className={ 'footer-modal__subtitle-tou' }>{ i18n('modal.tou.subtitle1') }</h3>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph2', {GiftMagic: (shopStore.appName === 'mygiftmagic' ? 'MyGiftMagic':'GiftMagic')}) }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph3', {GiftMagic: (shopStore.appName === 'mygiftmagic' ? 'MyGiftMagic':'GiftMagic')}) }</p>
            <h3 className={ 'footer-modal__subtitle-tou' }>{ i18n('modal.tou.subtitle2') }</h3>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph4') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph5') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph6') }</p>
            <h3 className={ 'footer-modal__subtitle-tou' }>{ i18n('modal.tou.subtitle3') }</h3>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph7') }</p>
            <h3 className={ 'footer-modal__subtitle-tou' }>{ i18n('modal.tou.subtitle4') }</h3>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph8') }<a className={ 'footer-modal__link-hiw' } href="mailto:support@gogiftmagic.com">support@gogiftmagic.com</a></p>
            <h3 className={ 'footer-modal__subtitle-tou' }>{ i18n('modal.tou.subtitle5') }</h3>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph9') }</p>
            <h3 className={ 'footer-modal__subtitle-tou' }>{ i18n('modal.tou.subtitle6') }</h3>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph10', {GiftMagic: (shopStore.appName === 'mygiftmagic' ? 'MyGiftMagic':'GiftMagic')}) }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph11') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph12') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph13') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph14', {GiftMagic: (shopStore.appName === 'mygiftmagic' ? 'MyGiftMagic':'GiftMagic')}) }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph15') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph16') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph17-1') }<a className={ 'footer-modal__link-hiw' } href="mailto:support@gogiftmagic.com">support@gogiftmagic.com</a>{ i18n('modal.tou.paragraph17-2', {GiftMagic: (shopStore.appName === 'mygiftmagic' ? 'MyGiftMagic':'GiftMagic')}) }</p>
            <h3 className={ 'footer-modal__subtitle-tou' }>{ i18n('modal.tou.subtitle7') }</h3>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph18') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph19') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph20') }</p>
            <h3 className={ 'footer-modal__subtitle-tou' }>{ i18n('modal.tou.subtitle8') }</h3>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph21') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph22') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph23') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph24') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph25',{GiftMagic: (shopStore.appName === 'mygiftmagic' ? 'MyGiftMagic':'GiftMagic')}) }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph26') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph27') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph28') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph29') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph30') }</p>
            <h3 className={ 'footer-modal__subtitle-tou' }>{ i18n('modal.tou.subtitle9') }</h3>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph31') }</p>
            <h3 className={ 'footer-modal__subtitle-tou' }>{ i18n('modal.tou.subtitle10') }</h3>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph32', {GiftMagic: (shopStore.appName === 'mygiftmagic' ? 'MyGiftMagic':'GiftMagic')}) }</p>
            <h3 className={ 'footer-modal__subtitle-tou' }>{ i18n('modal.tou.subtitle11') }</h3>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph33') }</p>
            <h3 className={ 'footer-modal__subtitle-tou' }>{ i18n('modal.tou.subtitle12') }</h3>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph34') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph35', {GiftMagic: (shopStore.appName === 'mygiftmagic' ? 'MyGiftMagic':'GiftMagic')}) }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph36') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph37') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph38') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph39') }</p>
            <h3 className={ 'footer-modal__subtitle-tou' }>{ i18n('modal.tou.subtitle13') }</h3>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph40') }</p>
            <h3 className={ 'footer-modal__subtitle-tou' }>{ i18n('modal.tou.subtitle14') }</h3>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph41') }</p>
            <h3 className={ 'footer-modal__subtitle-tou' }>{ i18n('modal.tou.subtitle15') }</h3>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph42') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph43') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph44') }</p>
            <p className={ 'footer-modal__paragraph-tou' }>{ i18n('modal.tou.paragraph45-1') }<a className={ 'footer-modal__link-hiw' } href="mailto:support@gogiftmagic.com">support@gogiftmagic.com</a>{ i18n('modal.tou.paragraph45-2') }</p>
          </div>
        </section>
      </Modal>
    </section>
  );
});

export default Footer;
