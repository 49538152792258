import { shopStore, giftStore }  from '../stores';
import { Logger } from './logger';
import { getOrCreateUserSessionId, getUserUUID } from './utils'
import { USER_SESSION_HEADER, USER_UUID_HEADER } from './conts';

const logger = new Logger('Metrics');

function getIpFromCookie() {
  const sgs = document.cookie.match(/ipcookie=([^;]+)/);

  if (sgs && sgs.length === 2) {
    logger.debug('found ip in cookie', sgs[1]);
    return sgs[1];
  } else {
    return '';
  }
}

let lookupPromise: Promise<string> = lookupIp();

export function trackSgEvent(
  name,
  shopId,
  shopName,
  giftId,
  ipAddr,
  params = {},
) {
  const opt = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      [USER_SESSION_HEADER]: getOrCreateUserSessionId(),
      [USER_UUID_HEADER]: getUserUUID(),
    },
    body: JSON.stringify({
      name: name,
      shop_id: shopId,
      shop: shopName,
      ip: ipAddr || getIpFromCookie(),
      gift_id: giftId,
      products: giftStore.items ? giftStore.items.map(obj => ({ id: obj.variant_id, quantity: 1 })) : [],
      ...params
    }),
  };

  return fetch('/api/event', opt);
}

function lookupIp(): Promise<string> {
  const ipFromCookie = getIpFromCookie();

  if (ipFromCookie) {
    return Promise.resolve(ipFromCookie);
  }

  return fetch( '/api/ip', {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache'
  })
    .then((res) => res.text());
}

export function sendMetric(name, params = {}) {
  const shopId = shopStore.settings?.id;
  const shopName = shopStore.settings?.subdomain_name;
  const giftId = giftStore.code;

  logger.debug(name, params);

  return lookupPromise.then((ipAddr) => {
    return trackSgEvent(name, shopId, shopName, giftId, ipAddr, params);
  });
}
