import React from "react";
import './confetti-copy.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {i18n} from "../../locales";

interface IConfettiCopyProps {
  className?: string,
  url?: string,
  onCopy?: () => void,
}

export function ConfettiCopy (props: IConfettiCopyProps) {
  return(
    <div className={`confetti ${props.className ? props.className : '' }`}>
      <CopyToClipboard
        text={props.url}
        onCopy={props.onCopy}
      >
        <button className={'copy-button'}>
          <span className={'copy-button__text'}>{i18n('sender.share.redesign.form.giftLink')}</span>
          <span className={'copy-button__url-area'}>
            <p className={'copy-button__url-text'}>{props.url}</p>
          </span>
          <div className={'copy-button__image-block'} >
            <span className={'copy-button__copy-mobile'}></span>
            <span className={'copy-button__copy-desktop'}>{i18n('sender.share.redesign.form.copy')}</span>
          </div>
        </button>
      </CopyToClipboard>
    </div>
  );
};
