import React, { useState, useEffect } from 'react';
import Gift from "../../components/gift/gift";
import Info from "../../components/info/info";
import Form from "../../components/form/form";
import { Input } from "../../components/input/input";
import { Button } from "../../components/button/button";
import { Phone } from '../../components/phone/phone';
import { Checkbox } from '../../components/checkbox/checkbox';
import { userStore, shopStore } from '../../../stores';
import { Logger } from '../../../common/logger';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../common/conts';
import { sendMetric } from "../../../common/metrics";
import { observer } from "mobx-react-lite";
import { i18n } from '../../locales';
import { useNotAvailableItem } from '../../../hooks';

const logger = new Logger('senderPage');

export const SenderPage = observer(function  () {
  const history = useHistory();
  const { userInfo } = userStore;
  const { features } = shopStore;
  const [ isReady, setIsReady ] = useState<boolean>(false);
  const [canTransferUserData, setTransferUserData] = useState<boolean>(false);
  useNotAvailableItem();
  function handleChecked (isChecked) {
    if (!isChecked) {
      setTransferUserData(true);
      sendMetric('sender_optin_true');
    } else {
      setTransferUserData(false);
      sendMetric('sender_optin_false');
    }
  }

  function onSubmitSender(data) {
    userStore.setUserInfo({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      sender_optin: canTransferUserData,
    });

    logger.info('submit', data);
    sendMetric('newgift_recipientinfo_opened_nextbutton_clicked');
    history.push(ROUTES.OCCASION);
  }

  function onFormChange(data) {
    if (features.ozon_privacy) {
      setIsReady(!data.error && data.firstName && data.lastName && data.email && data.phone && data.privacy);
    } else {
      setIsReady(!data.error && data.firstName && data.lastName && data.email && data.phone);
    }
  }

  function onSenderError() {
    sendMetric('newgift_senderinfo_error');
  }

  useEffect(() => {
    sendMetric('newgift_senderinfo_opened');
  }, []);

  // scroll page up at ROUTE
  useEffect(() => {
    window.scroll(0,0);
  }, []);

  return (
    <div className={ 'main main_theme-default' }>
      <Info
        title={ i18n('sender.sender.intro.title') }
        desc={ i18n('sender.sender.intro.subtitle') }
        link={ true }
      />
      <div className={ 'main-container main-container_theme-default' }>
        <Gift covered={ 'opened' } variant={ 'boxView' } theme={'boxView'} hidden={ true }/>
        <Form
          title={ i18n('sender.sender.form.title') }
          direction={ 'row-media' }
          name={ 'recipient' }
          onSubmit={ data => onSubmitSender(data) }
          onChange={ onFormChange }
          requiredField={ i18n('form.legend.required') }
          className={ 'form_outer-size-top' }
        >
          <Input size={ 'half-media' } onError={ onSenderError } name={ 'firstName' } value={ userInfo?.firstName } title={ i18n('form.field.user.firstName') } required={ true } maxLength={ 50 } tabindex={ 0 } autoFocus={true} />
          <Input size={ 'half-media' } onError={ onSenderError } name={ 'lastName' } value={ userInfo?.lastName } title={ i18n('form.field.user.lastName') } required={ true } maxLength={ 50 } tabindex={ 0 } />
          <Input size={ 'half-media' } onError={ onSenderError } type={ 'email' } name={ 'email' }  value={ userInfo?.email } title={ i18n('form.field.user.email') } required={ true } maxLength={ 50 } tabindex={ 0 } />
          <Phone size={ 'half-media' } onError={ onSenderError } name={ 'phone' } value={ userInfo?.phone } title={ i18n('form.field.user.phone') } required={ true } tabindex={ 0 } outerSize={ '_16-24' } />
          <Checkbox
            name={ 'sender_optin' }
            text={
              i18n('form.action.opt-in.checkbox.1-1')+
              (shopStore.settings?.name ? shopStore.settings.name[0].toUpperCase()+shopStore.settings.name.slice(1) : 'shop')+
              i18n('form.action.opt-in.checkbox.1-2')
            }
            variant={'opt-in'}
            onChange={ isChecked => { handleChecked(isChecked); }
            }
          />
          <Button disabled={ !isReady } name={ 'send-sender-data' } text={ i18n('form.action.next') } type={ 'submit' } size={ 'default' } />

          <span className={'sender__tou'}>
            { i18n('form.legend.terms.1') }
            <a className={ 'accept__tou-link' } href={'/pages/privacy-policy'} target={'_blank'}>
              { i18n( 'form.legend.privacy') }
            </a>{i18n('form.legend.privacy.&')}
            <a className={ 'accept__tou-link' } href={'/pages/terms-of-use'} target={'_blank'}>
              { i18n( 'form.legend.terms.2') }
            </a>
          </span>
        </Form>
      </div>
    </div>
  );
});
