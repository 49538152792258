import React, {useEffect, useState} from 'react';
import './certificates.scss';
import { giftStore } from '../../../stores';
import { IGiftCertificate } from '../../../stores/gift/gift';

import Certificate from '../../../image/certificate.png';
import { observer } from "mobx-react-lite";
import {sendMetric} from "../../../common/metrics";

interface IProps {
  onDone: ( certificate: IGiftCertificate ) => void;
}

const Certificates = observer((props: IProps) => {
  const { certificates, mainItem } = giftStore;
  const [ selectedCert, setSelectedCert ] = useState<number>();

  function handleSend() {
    const cert = getSelectedCertInfo();

    if (!cert) {
      throw new Error('Invalid certificate');
    }

    props.onDone(cert);
  }

  function getSelectedCertInfo(): IGiftCertificate | undefined {
    return certificates.find(item => item.id === selectedCert) || undefined;
  }

  useEffect(() => {
    if (!mainItem) {
      throw new Error('Invalid mainItem');
    }

    const diffs: {[key: string]: number} = {};

    for (const item of certificates) {
      let diff = item.name - mainItem.price;

      if (diff < 0) {
        diff *= -1;
      }

      diffs[diff] = item.id
    }

    const minDiff = Math.min(...Object.keys(diffs).map(i => parseFloat(i)));
    const selected = certificates.find((item) => item.id === diffs[minDiff]);

    selected && setSelectedCert(selected.id);
  }, [ certificates ]);

  return(
      <div className={ 'certificate' }>
        <div className={ 'certificate__card' }>
          <img className={ 'certificate__card-image' } src={Certificate} alt={ 'your certificate picture' } />
          <span className={ 'certificate__card-value' }>{getSelectedCertInfo()?.name || ''}</span>
          <span className={ 'certificate__card-text' }>Выберите номинал</span>
        </div>

        <form className={ 'certificate__form' }>
          <div className={ 'certificate__scrollbar' }>
            {certificates.map(item => (
              <div  onClick={() => { setSelectedCert(item.id); sendMetric('newgift_500rurflow_cert_changed'); }} key={`${item.id}_${selectedCert}`} className={ 'certificate__wrap-radio' }>
                <input
                  className={ 'certificate__input' }
                  type="radio" value={item.id}
                  aria-labelledby={`cert_${item.id}`}
                  defaultChecked={item.id === selectedCert} />
                <label className={ 'certificate__label' } id={`cert_${item.id}`}>{item.name} &#8381;</label>
              </div>
            ))}
          </div>
        </form>

        <button className={ 'certificate__button-send' } onClick={handleSend}>Отправить</button>
      </div>
  );
});

export default Certificates;
