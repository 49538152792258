import ru from './ru.json'
import en from './en.json'
import it from './it.json'
import de from './de.json'

import { shopStore } from '../../stores';

const texts = { ru, en, it, de };
const DEFAULT_LANG = 'ru';

export function isSupportedLang(lang: string) {
	return texts[lang];
}

export function i18n(id: string, options: { [key: string]: string } = {}): string {
	const { settings } = shopStore;
	let lang = settings?.lang ? settings.lang : DEFAULT_LANG;
	let text = texts[lang][id];

	for (const key in options) {
		const regExp = new RegExp("\\{" + key + "\\}", "g");
		text = text.replace(regExp, options[key]);
	}

	return text;
}
