import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Form from "../../components/form/form";
import { Input } from "../../components/input/input";
import { recipientStore, shopStore, userStore } from "../../../stores";
import { Phone } from "../../components/phone/phone";
import { Address } from "../../components/address/address";
import { Button } from "../../components/button/button";
import { Checkbox } from "../../components/checkbox/checkbox";
import { sendMetric } from "../../../common/metrics";
import { giftStore } from '../../../stores';
import Progress from "../../components/progress/progress";
import { ERROR, ERROR_TEXTS, ROUTES } from "../../../common/conts";
import { useHistory } from 'react-router-dom';
import { acceptGift } from "../../../common/actions";
import { getGiftId } from "../../../common/utils";
import { i18n } from "../../locales";
import { Select } from "../../components/select/select";
import { levengerCountries } from "../../../common/countires";
import { validateThroughFlow } from '../../../common/validators';
import { levengerStates } from "../../../common/states";
import Gift from "../../components/gift/gift";
import { useNotAvailableItem } from '../../../hooks';

export const AcceptFormPage = observer( function () {
  const history = useHistory();
  const { mainItem, oldMainItem} = giftStore;
  const [ addressError, setAddressError ] = useState<string>();
  const { userInfo } = userStore;
  const [ isReady, setIsReady ] = useState<boolean>(false);
  const [ selectedCountry, setSelectedCountry ] = useState<string>('');
  const [ statesList, setStateList ] = useState<Array<{ title: string, value: string }>>([])
  const { features, settings, countriesList } = shopStore;
  const { items = [] } = giftStore;
  const isDigital = items?.find(item => item.digital);
  const [canTransferUserData, setTransferUserData] = useState<boolean>(false);

  useNotAvailableItem();
  async function onSubmitRecipient (data) {
    setIsReady(false);

    if (isDigital) {
      recipientStore.setEmail(data.email);
    } else {
      recipientStore.setFullInfo(data);
      recipientStore.setTransferRecipientData(canTransferUserData);
    }

    if (oldMainItem && oldMainItem?.variant_id !== mainItem?.variant_id) {
      sendMetric('accept_product_changed');
    }

    setAddressError('');

    try {
      await acceptGift();
    } catch (err) {
      if ([ERROR.ADDRESS_NOT_AVAILABLE, ERROR.OZON_ERROR, ERROR.ADDRESS_NOT_EXACT].indexOf(err.message) !== -1) {
        setAddressError(ERROR_TEXTS[err.message]);
        sendMetric('accept_landing_address_error');
      } else {
        console.error('delivery_error', err);
        sendMetric('accept_delivery_error');
      }
    }
  }
   function handleChecked(isChecked) {
     if (!isChecked) {
       setTransferUserData(true);
       sendMetric('recipient_optin_true');
     } else {
       setTransferUserData(false);
       sendMetric('recipient_optin_false');
     }
   }

  function onFormChange (data) {
    if (data.hasOwnProperty('province') && data.province === '') {
      setIsReady(false);
      return;
    }
    setIsReady(validateThroughFlow(features, data, mainItem?.digital, true));
  }

  useEffect(() => {
    if (giftStore.checkoutUrl) {
      history.push(ROUTES.FINISH.replace(':giftId', getGiftId()));
    }
  }, [ giftStore.checkoutUrl ]);

  useEffect(() => {
    if (giftStore.options.length > 1) {
      sendMetric('accept_landing_alternatives_available')
    }
  }, [ giftStore.options.length ])

  useEffect(() => {
    if (features.is_levenger_form && selectedCountry) {
      const states = levengerStates[selectedCountry.toLowerCase()];

      if (!states){
        setStateList([]);
        return;
      }

      const options = Object.entries(states).map(([key, value]) => ({ title: value + '', value: key }));

      options.unshift({ title: '', value: '' });
      setStateList(options);
    } else if (features.is_default_form) {
      const country = countriesList.find(item => item.name === selectedCountry);

      if (!country?.provinces?.length){
        setStateList([]);
        return;
      }

      const options = country?.provinces?.map(item => ({ title: item.name + '', value: item.name })) || [];
      options.unshift({ title: '', value: '' });

      country?.provinces?.length
        ? setStateList(options)
        : setStateList([]);
    } else {
      setStateList([]);
    }
  }, [ selectedCountry ]);

  function onAcceptFormError() {
    setIsReady(false);
    sendMetric('accept_delivery_error');
  }

  useEffect(() => {

    if (countriesList) {
      setSelectedCountry(countriesList[0].name);
    }

    sendMetric('accept_delivery_opened');
  }, [])

  function onOptionChange(from, to) {
    const fromItem = giftStore.items?.find(item => item.variant_id === from);
    const toItem = giftStore.options.find(item => item.variant_id === to);

    if (fromItem) {
      giftStore.setItemForReplace(fromItem);
    }

    if (toItem) {
      giftStore.swapItemBySubstitute(toItem);
    }
  }

  return(
    <>
      <Progress  step={ 1 }/>
      <main className={ 'main main_theme-recipient accept' }>
        <h1 className={ 'accept__title' }>
          {items.length > 1 ?
            `${i18n('recipient.unwrap.intro.title.many', { item:`${items.length}`, name: ([userInfo?.firstName, userInfo?.lastName].join(' ')) || ''} )}`
            :
            `${i18n('recipient.unwrap.intro.title', { name: ([userInfo?.firstName, userInfo?.lastName].join(' ')) || ''} )}`
          }
        </h1>
        <div className={ 'main-container' }>
          <section className={'accept__gift'}>
            <Gift
              covered={ 'opened' }
              variant={ 'fullView' }
              theme={'fullView'}
              optionsSelector={giftStore.options.length > 1 ? (selectedVariantId, title) => (
                  <Form
                      name={ 'options' }
                      onSubmit={ data => onSubmitRecipient(data) }
                  >
                    <Select
                        title={ i18n('form.field.giftOptions') }
                        name={ 'variantId' }
                        label={ i18n('form.field.giftOptions') }
                        required={ true }
                        tabindex={ 0 }
                        outerSize={ '_0' }
                        value={ selectedVariantId }
                        onChange={ (event) => onOptionChange(selectedVariantId, event.target.value) }
                        onClick={ () => sendMetric('accept_landing_opened_alternatives') }
                        options={ giftStore.options.map(item => ({
                          value: item.variant_id,
                          title: item.name
                        }))}
                    />
                  </Form>
              ) : undefined}
            />
          </section>
          <section className={'accept__form'}>
            <Form
              name={ 'accept' }
              onSubmit={ data => onSubmitRecipient(data) }
              title={ i18n('common.recipient.steps.1') }
              onChange={ onFormChange }
              requiredField={ i18n('form.legend.required') }

            >
              <div className={ 'form__fieldset_row' }>
                <Input onError={ onAcceptFormError } name={ 'firstName' } title={ i18n('form.field.user.firstName') } value={recipientStore.firstName} required={ true } maxLength={ 50 } tabindex={ 0 } size={ 'half-media' } />
                <Input onError={ onAcceptFormError } name={ 'lastName' } title={ i18n('form.field.user.lastName') } value={recipientStore.lastName} required={ true } maxLength={ 50 } tabindex={ 0 } size={ 'half-media' } autoFocus={true} />
                <Input onError={ onAcceptFormError } name={ 'email' } type={'email'} title={ i18n('form.field.user.email') }  required={ true } maxLength={ 50 } tabindex={ 0 } size={ 'half-media' } />
                <Phone onError={ onAcceptFormError } name={ 'phone' } title={ i18n('form.field.user.phone') }  required={ true } tabindex={ 0 } size={ 'half-media' } />
                { !mainItem?.digital && features.is_ozon_form && (
                  <>
                    <Address error={ addressError } onError={ onAcceptFormError } name={ 'address' } title={ 'Город, улица, дом' }  required={ true } tabindex={ 5 } size={ 'full' } />
                    <Input onError={ onAcceptFormError } name={ 'apartment' } title={ i18n('form.field.apartmentSuiteRoom') }  required={ true } maxLength={ 50 } tabindex={ 6 } size={ 'half' } />
                    <Input onError={ onAcceptFormError } name={ 'index' } title={ i18n('form.field.postZip') }  required={ true } tabindex={ 7 } maxLength={ 50 } outerSize={ '_16-24' } size={ 'half' }/>
                  </>
                )}
                { features.is_levenger_form && (
                  <>
                    <Select
                      title={ '' }
                      name={ 'country' }
                      label={ i18n('form.field.country') }
                      required={ true }
                      tabindex={ 5 }
                      onChange={ (event) => setSelectedCountry(event.target.value) }
                      options={ levengerCountries }
                    />
                    { statesList.length > 0 && (
                      <Select
                        title={ '' }
                        name={ 'province' }
                        label={ i18n('form.field.countyState') }
                        required={ true }
                        tabindex={ 5 }
                        options={ statesList }
                      />
                    )}
                    <Input onError={ onAcceptFormError } name={ 'street_address1' } title={ i18n('form.field.addressLine1') } required={ true } maxLength={ 255 } tabindex={ 6 } size={ 'full' }/>
                    <Input name={ 'street_address2' } title={ i18n('form.field.addressLine2') } required={ false } maxLength={ 255 } tabindex={ 7 } size={ 'full' }/>
                    <Input onError={ onAcceptFormError } name={ 'index' } title={ i18n('form.field.postZip') } required={ true } maxLength={ 50 } tabindex={ 8 } size={ (settings?.type === 'ozon') ? 'half' : 'half' }/>
                    <Input onError={ onAcceptFormError } name={ 'city' } title={ i18n('form.field.city') } required={ true } maxLength={ 50 } tabindex={ 9 } size={ 'half' }/>
                  </>
                )}

                { features.is_pollini_form && (
                  <>
                    <Select
                      title={ '' }
                      name={ 'country' }
                      label={ i18n('form.field.country') }
                      required={ true }
                      tabindex={ 5 }
                      onChange={ (event) => setSelectedCountry(event.target.value) }
                      options={ countriesList.map(item => ({
                        value: item.name,
                        title: item.name,
                        key: item.code,
                      })) }
                    />
                    <Input onError={ onAcceptFormError } name={ 'province' } title={ i18n('form.field.countyState') } required={ true } maxLength={ 255 } tabindex={ 2 } size={ 'full' }/>
                    <Input onError={ onAcceptFormError } name={ 'street_address1' } title={ i18n('form.field.addressLine1') } required={ true } maxLength={ 255 } tabindex={ 3 } size={ 'full' }/>
                    <Input name={ 'street_address2' } title={ i18n('form.field.addressLine2') } required={ false } maxLength={ 255 } tabindex={ 4 } size={ 'full' }/>
                    <Input onError={ onAcceptFormError } name={ 'index' } title={ i18n('form.field.postZip') } required={ true } maxLength={ 50 } tabindex={ 8 } size={ 'half' }/>
                    <Input onError={ onAcceptFormError } name={ 'city' } title={ i18n('form.field.city') } required={ true } maxLength={ 50 } tabindex={ 9 } size={ 'half' }/>
                  </>
                )}

                { features.is_default_form && (
                  <>
                    <Select
                      title={ '' }
                      name={ 'country' }
                      label={ i18n('form.field.country') }
                      required={ true }
                      tabindex={ 0 }
                      onChange={ (event) => setSelectedCountry(event.target.value) }
                      options={ countriesList.map(item => ({
                        value: item.name,
                        title: item.name,
                        key: item.code,
                      })) }
                    />
                    { statesList.length > 0 && (
                      <Select
                        title={ '' }
                        name={ 'province' }
                        label={ i18n('form.field.countyState') }
                        required={ true }
                        tabindex={ 0 }
                        options={ statesList }
                      />
                    )}
                    <Input onError={ onAcceptFormError } name={ 'street_address1' } title={ i18n('form.field.addressLine1') } required={ true } maxLength={ 255 } tabindex={ 0 } size={ 'full' }/>
                    <Input name={ 'street_address2' } title={ i18n('form.field.addressLine2') } required={ false } maxLength={ 255 } tabindex={ 0 } size={ 'full' }/>
                    <Input onError={ onAcceptFormError } name={ 'index' } title={ i18n('form.field.postZip') } required={ true } maxLength={ 50 } tabindex={ 0 } size={ 'half' }/>
                    <Input onError={ onAcceptFormError } name={ 'city' } title={ i18n('form.field.city') } required={ true } maxLength={ 50 } tabindex={ 0 } outerSize={ '_16-24' } size={ 'half' }/>
                  </>
                )}
              </div>
              <Checkbox
                name={ 'recipient_optin' }
                text={
                  i18n('form.action.opt-in.checkbox.1-1')+
                  (shopStore.settings?.name ? shopStore.settings.name[0].toUpperCase()+shopStore.settings.name.slice(1) : 'shop')+
                  i18n('form.action.opt-in.checkbox.1-2')
                }
                variant={'opt-in'}
                onChange={ (isChecked) => {handleChecked(isChecked);} }
              />
              <Button disabled={ !isReady } name={ 'accept-button' } type={ 'submit' } text={ i18n('form.action.next') } size={ 'default' } />
              {/* correct check metric and delete this comment */}
              { features.is_ozon_form ? (
                <Checkbox
                  name={'privacy'}
                  text={`Я принимаю условия Пользовательского соглашения и даю своё согласие GiftMagic на обработку моей персональной информации на условиях, определенных`}
                  link={'политикой конфиденсеальности'}
                  href={'https://ozon.gogiftmagic.com/pages/privacy'}
                  onChange={(isChecked) =>
                    sendMetric(isChecked ? 'accept_landing_personal_data_checked' : 'accept_landing_personal_data_unchecked')
                  }
                />
              ) : (
                <span className={'accept__tou'}>
                  { i18n('form.legend.terms.1') }
                  <a className={ 'accept__tou-link' } href={'/pages/privacy-policy'} target={'_blank'}>
                    { i18n( 'form.legend.privacy') }
                  </a>{i18n('form.legend.privacy.&')}
                  <a className={ 'accept__tou-link' } href={'/pages/terms-of-use'} target={'_blank'}>
                    { i18n( 'form.legend.terms.2') }
                  </a>
                </span>
              )}
            </Form>
          </section>
        </div>
      </main>
    </>
  );
});
