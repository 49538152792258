const features = {
  ozon: {
    "is_ozon_form": true,
    "ozon_privacy": true,
    "certificates": true,
    "alternatives": true,
    "phone_one_country": true,
  },
  levenger: {
    "is_levenger_form": true,
  },
  pollini: {
    "is_pollini_form": true,
  }
};

export function getFeatures(slug: string) {
  return features[slug] || { is_default_form: true };
}
