import React from "react";
import './box-mask.scss';
import { shopStore } from "../../../stores";
import DefaultBanner from "../../../image/banner-default.png";
import {hexToHSL} from '../../../common/utils';

export interface IBoxMaskProps {
  brandColor?: string,
  background?: string,
  className?: string,
}

export function BoxMask (props: IBoxMaskProps) {
  const {brandColor} = props;
  const {settings} = shopStore;

  return (
  <svg className={props.className} width="279" height="282" viewBox="0 0 279 282" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g clipPath={`url(#clip0_7234_25129)`}>
      <path d="M223.636 36.6967C223.615 40.8829 221.037 44.5267 218.382 47.6185C200.716 68.187 162.185 88.4634 138.986 71.4792C141.839 71.2099 149.125 71.9451 154.973 71.2337C168.86 69.5451 179.032 56.446 187.49 44.6174C192.676 37.3648 195.706 15.5876 204.735 13.3955C213.354 11.3043 223.676 28.3486 223.636 36.6967Z" fill={hexToHSL( brandColor, { h:-0, s:-33, l:-47 })}/>
      <path d="M145.473 42.1619C152.704 29.8303 159.679 22.1442 168.7 13.64C177.719 5.13584 189.497 -0.86078 201.521 0.100408C202.949 0.214851 204.416 0.441726 205.646 1.22294C207.051 2.11616 207.99 3.62707 208.88 5.0878C213.797 13.1584 223.63 24.2354 223.635 34.9293C202.971 31.2475 180.486 36.548 162.393 47.7759C148.887 56.1579 139.804 72.8522 138.459 72.7184C125.658 71.4437 142.07 47.9647 145.473 42.1619Z" fill={hexToHSL( brandColor, { h:-0, s:-35, l:-37 })}/>
      <path d="M53.029 39.6325C53.0517 43.8192 55.6328 47.4607 58.2911 50.551C75.973 71.1049 114.519 91.3517 137.706 74.3496C134.852 74.082 127.567 74.8237 121.718 74.1162C107.83 72.4384 97.6473 59.3474 89.1797 47.5249C83.9891 40.2772 80.9422 18.5014 71.9108 16.317C63.2907 14.2323 52.9821 31.2851 53.029 39.6325Z" fill={hexToHSL( brandColor, { h:-0, s:-33, l:-47 })}/>
      <path d="M131.194 45.0385C123.953 32.7123 116.972 25.0327 107.946 16.5356C98.9197 8.03829 87.1371 2.05079 75.1144 3.02131C73.6861 3.13718 72.2185 3.3646 70.9893 4.14716C69.5849 5.04156 68.6475 6.55297 67.7585 8.01435C62.8474 16.089 53.0231 27.1731 53.0275 37.867C73.6881 34.1689 96.1771 39.452 114.278 50.6661C127.792 59.0376 136.887 75.7247 138.232 75.5899C151.031 74.3056 134.602 50.8388 131.194 45.0385Z" fill={hexToHSL( brandColor, { h:-0, s:-35, l:-37 })}/>
      <g clipPath={`url(#clip1_7234_25129)`}>
        <mask id="mask0_7234_25129" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="17" y="74" width="247" height="210">
          <path d="M263.262 283.175L17.0423 283.439L17.1007 75.1392L263.321 74.8762L263.262 283.175Z" fill={hexToHSL( brandColor, { h:-0, s:-25, l:-7 })}/>
        </mask>
        <g mask="url(#mask0_7234_25129)">
          <rect x="17" y="34.9883" width="246" height="248.745" fill="url(#pattern0)"/>
        </g>
      </g>
      <path d="M279.234 126.274L-0.0158815 126.311L-0.0493701 73.9018L279.2 73.8644L279.234 126.274Z" fill={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
      <path d="M263.479 131.499L263.477 126.375L263.479 131.499Z" fill="#E5E5E5"/>
      <path d="M263.483 140.921L263.48 131.501L263.483 140.921Z" fill="#352E79"/>
      <path d="M160.424 281.363L139.144 281.33L138.845 135.223L27.7116 126.315L263 126.277L263 145L159.689 137.14L160.424 281.363Z" fill={hexToHSL( brandColor, { h:-0, s:-36, l:-27 })}/>
      <path d="M150.83 126.418L138.824 126.423L138.824 126.104L150.83 126.418ZM137.676 126.423L27.9598 126.466L27.9539 126.466L137.676 126.423Z" fill="#7E9AD7"/>
      <path d="M153.649 281.521L123.203 281.533L123.121 71.4352C123.121 69.3053 124.783 67.578 126.836 67.5773L149.848 67.5683C151.902 67.5675 153.566 69.2934 153.567 71.4233L153.649 281.521Z" fill={hexToHSL( brandColor, { h:-0, s:-35, l:-37 })}/>
    </g>
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_7234_25129" transform="translate(0 0.00551705) scale(0.00110988)"/>
      </pattern>
      <clipPath id="clip0_7234_25129">
        <rect width="279" height="282" fill="white"/>
      </clipPath>
      <clipPath id="clip1_7234_25129">
        <rect width="279" height="208" fill="white" transform="translate(0 74)"/>
      </clipPath>
      {settings?.background ?
        <image id="image0_7234_25129" width="901" height="901" xlinkHref={settings?.background}/>
        :
        <image id="image0_7234_25129" width="901" height="901" xlinkHref={DefaultBanner}/>
      }
    </defs>
  </svg>

);
}
