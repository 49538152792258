import React from 'react';
import './modal.scss';
import CloseButton from '../../../image/close-button.svg';

interface IModalProps{
  children?: React.ReactChild | React.ReactChildren;
  title?: string,
  onClose?: () => void;
  hidden?: boolean,
  hiddenVariant: string,
  subtitle?: string,
  theme?: string,
  buttonHidden?: boolean,
}

export function Modal (props: IModalProps) {
  return (
    <section className={ `modal` }>
      <div className={ `modal__overlay modal__overlay_theme-default ${ props.hidden ? 'modal__overlay_hidden' : ''}` }>
        <div className={ `modal__window ${ props.theme ? 'modal__window_theme-' + props.theme : '' } ${ props.hidden ? 'modal__window_' + props.hiddenVariant : ''} ` }>
          <button
            className={ `modal__close-button ${props.theme ? 'modal__close-button_theme-' + props.theme : ''} ${ props.buttonHidden ? 'modal__close-button_hidden' : ''}`}
            onClick={props.onClose}
          >
            <img className={ 'modal__close-button-image' } src={ CloseButton }/>
          </button>
          {props.children}
        </div>
      </div>
    </section>
  );
}
