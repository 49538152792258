import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { IGiftItem } from '../../../stores/gift/gift'
import {giftStore, shopStore} from '../../../stores';
import { sendMetric } from "../../../common/metrics";
import './substitutes.scss';
import {i18n} from "../../locales";
import {Select} from "../select/select";

interface IProps {
  onDone: ( product: IGiftItem ) => void;
}

const MAX_SHOWED_ITEMS = 3;

export const Substitutes = observer((props: IProps) => {
  const { substitutes } = giftStore;
  const [ showedItems, setShowedItems ] = useState<IGiftItem[]>([]);
  const [ selectedOption, setSelectedOption ] = useState<IGiftItem | null>(null);
  const [ selectedVariant, setSelectedVariant] = useState<IGiftItem | null>(null);
  const [ options, setOptions] = useState<any[]>([]);

  function handleDone() {
    if (options.length > 1 && selectedOption) {
      props.onDone(selectedOption);
    } else if (selectedVariant) {
      props.onDone(selectedVariant);
    }
  }

  async function updateShovedItems() {
    await giftStore.loadSubstitutes();
    let showedIds: string[] = [];
    setSelectedVariant(null);
    if (showedItems.length) {
      showedIds = showedItems.map(item => '' + item.variant_id);
    }

    const newItems: IGiftItem [] = substitutes
        .filter(item => !showedIds.includes(String(item.variant_id))) ?? [];

    setShowedItems(newItems.slice(0, MAX_SHOWED_ITEMS));
  }

  async function handleSelect(item) {
    setSelectedVariant(item);
    setSelectedOption(null);
    setOptions([]);
    const options = await giftStore.loadOptions(shopStore.settings?.subdomain_name, item.variant_id);
    setOptions([...options]);
  }

  function onOptionChange(event) {
    const id = event.target.value;
    setSelectedOption(options.find(item => item.variant_id === id));
  }

  useEffect(() => {
    if (!showedItems.length) {
      updateShovedItems();
    }
  }, [showedItems]);

  return (
    <section className={ 'products' }>
      <div  className={ 'products__wrapper' }>
        {showedItems.map(item => (
          <div onClick={() => handleSelect(item)} key={item.variant_id} className={
            `products__item ${item.variant_id === selectedVariant?.variant_id ? 'products__item_selected' : ''}`
          }>
            <div className={ 'products__image-wrapper' }>
              <img className={ 'products__image' } src={item.variant_id === selectedVariant?.variant_id && selectedOption ? selectedOption.image_url : item.image_url} alt={ 'product-name-text' }/>
            </div>
            <span className={ 'products__name' }>{item.variant_id === selectedVariant?.variant_id && selectedOption ? selectedOption.name : item.name}</span>
          </div>
        ))}
      </div>
      {options.length > 1 && selectedVariant && (
          <div className={'products__item-options'}>
            <Select
                title={ i18n('form.field.giftOptions') }
                name={ 'variantId' }
                label={ i18n('form.field.giftOptions') }
                required={ true }
                tabindex={ 0 }
                outerSize={ '_0' }
                onChange={ onOptionChange }
                options={ options.map(item => ({
                  value: item.variant_id,
                  title: item.name,
                }))}
            />
          </div>

      )}

      <button className={ 'products__button-next' }
              onClick={handleDone}
              disabled={giftStore.isOptionsLoading || !selectedVariant}>{i18n('form.action.next')}
      </button>

      <button className={ 'products__button-refresh-items' }
              onClick={() => {
                console.log('1234');
                sendMetric( 'newgift_500rurflow_refresh' );
                updateShovedItems();
              }}>
        <span className={'products__button-refresh-items-image'}></span>
        Refresh items
      </button>
    </section>
  );
});

