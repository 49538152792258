import { action, observable, runInAction, makeObservable } from 'mobx';
import { getShopSlug, getDefaultHeaders } from '../../common/utils';
import { Logger } from '../../common/logger';
import { autoSave } from '../auto-save';
import { sendMetric } from '../../common/metrics';
import { getFeatures } from './features';
import { isSupportedLang } from '../../current-flow/locales';
import { defaultCountries, IMPORTANT_COUNTRIES } from '../../common/countires';

const logger = new Logger('ShopStore');
const isSuperFlow = window.location.href.includes('super=1') || sessionStorage.getItem('is_super');

enum SHOP_TYPE  {
  SHOPIFY = 'shopify',
  API = 'api',
  OZON = 'ozon'
}

interface IShopInfo {
  background: string;
  brandColor: string;
  secondaryBrandColor?: string;
  id: number;
  lang: string;
  logo: string;
  name: string;
  siteUrl: string
  subdomain_name: string;
  supportEmail: string;
  features?: IFeatures;
  type: SHOP_TYPE;
  mainFont: string;
  mainFontUrl: string;
  favicon?: string;
  textColorMain?: string;
  textColorSecondary?: string;
  refUrl?: string;
  defaultCountry?: string;
  onlyDefaultCountry?: boolean;
  gaAppId?: string;
  vkAppId?: string;
  fbAppId?: string;
}

interface IFeatures {
  flow_type?: string;
}

export interface IShopStore {
  loaded: boolean;
  slug: string;
  settings?: IShopInfo;
}

interface IProvince {
  id: number;
  country_id?: number;
  name: string;
  code?: string;
  tax_name?: string;
  tax_type?: string;
  shipping_zone_id?: number;
  tax?: number;
  tax_percentage?: number;
}

export interface ICountry {
  id: number;
  name: string;
  code: string;
  tax_name?: string;
  tax?: number;
  provinces: IProvince[];
}

export class ShopStore implements IShopStore {
  @observable fromApp?: boolean = false; // признак того, что стор открыл нас через приложеньку (WebView)
  @observable fromIframe?: boolean = false; // признак того, что открыли в iframe;
  @observable loaded: boolean = false;
  @observable slug: string = getShopSlug();
  @observable settings?: IShopInfo;
  @observable features = getFeatures(getShopSlug());
  @observable countryCode: string = 'en';
  @observable countriesList: ICountry[] = [];
  @observable appName?: string = process.env.REACT_APP_NAME; // имя приложения в котором установлен шоп (getgiftmagic/mygiftmagic)
  @observable defaultCountry?: string;

  constructor() {
    makeObservable(this);
  }

  init() {
    autoSave(this, 'ShopStore');
    logger.info('shop features', this.features);
  }

  @action async setDefaultCountryCodeFromGeoIp () {
    this.countryCode = await fetch('/api/geoip?full=1')
      .then(response => response.json())
      .then(countryData => {
        this.defaultCountry = countryData.country.names.en;
        return countryData.country.isoCode;
      })
      .catch(error => {
        console.error('error: ', error)
      });
  }

  @action setFromApp(state: boolean) {
    sendMetric('fromapp', { fromapp: state });
    this.fromApp = state;
  }

  @action setFromIframe(state: boolean) {
    sendMetric('fromiframe', { fromiframe: state });
    this.fromIframe = state;
  }

  @action setLang(lang: string) {
    if (this.settings && isSupportedLang(lang)) {
      this.settings.lang = lang;
    }
  }

  /**
   * Метод загружает доступные страны для этого магазина
   */

  @action async loadCountries() {
    if (this.settings?.type === SHOP_TYPE.SHOPIFY) {
      const { countries } = await fetch(`/api-shopify/${this.slug}/shipping_zones`).then(res => res.json()) as {countries: ICountry[]};
      this.countriesList = countries.sort((a, b) => {

        if (this.settings?.defaultCountry) {
          if (this.settings?.defaultCountry === a.code) {
            return -1;
          }

          if (this.settings?.defaultCountry === b.code) {
            return 1;
          }
        }

        if (this.defaultCountry) {
          if (this.defaultCountry === a.name) {
            return -1;
          }

          if (this.defaultCountry === b.name) {
            return 1;
          }
        }

        return 0;
      });

      this.countriesList = this.countriesList.filter(item => item.code !== '*');

    } else {
      this.countriesList = defaultCountries;
    }
  }

  @action load(storeCode: string = '') {
    return fetch(`/api/shop/${this.slug}?${storeCode ? `store_code=${storeCode}` : ''}`, {
      headers: getDefaultHeaders(),
    }).then(res => {
      this.countryCode = res.headers.get('x-country-code') || 'ru';
      return res.json()
    }).then((data: IShopInfo) => {
      runInAction(() => {
        this.settings = data;
        this.loaded = true;

        if ( this.slug === 'pollini' ) {
            this.settings.brandColor = '#141414';
            this.settings.mainFont = 'Futura PT, sans-serif';
            this.settings.favicon = '/icon/favicon-pollini.ico';
        }
        if ( this.slug === 'levenger' ) {
          this.settings.mainFont = 'Montserrat, sans-serif';
          this.settings.favicon = '/icon/favicon-levenger.ico';
        }
        if ( this.slug === 'osay' ) {
          this.settings.favicon = '/icon/favicon-osay.ico';
        }
        if ( this.slug === 'fifthorigins' ) {
          this.settings.favicon = '/icon/favicon-fifthorigins.ico';
        }

        if ( !this.settings.mainFont ) {
          this.settings.mainFont = 'Lato, sans-serif';
        }
        if ( !this.settings.favicon ) {
          this.settings.favicon = '/icon/favicon-rose-32x32.ico';
        }
        if ( !this.settings.brandColor ) {
          if (isSuperFlow) {
            this.settings.brandColor = '#4B64E8';
          } else {
            this.settings.brandColor = '#3678DC';
          }
        }
        if ( !this.settings.textColorMain ) {
          this.settings.textColorMain = '#2D2C2C';
        }
        if ( !this.settings.textColorSecondary ) {
          this.settings.textColorSecondary = '#939393';
        }

        logger.info(`shop loaded`, data);
      });
    })
  }
}

