import React, { useEffect, useState } from "react";
import {observer} from "mobx-react-lite";
import Progress from "../../components/progress/progress";
import Form from '../../components/form/form';
import {Button} from "../../components/button/button";
import {Textarea} from "../../components/textarea/textarea";
import {giftStore, userStore, shopStore} from "../../../stores";
import { i18n } from '../../locales';
import { sendMetric } from "../../../common/metrics";
import Gift from "../../components/gift/gift";
import { useNotAvailableItem } from '../../../hooks';

export const FinishPage = observer( function () {
  const { userInfo } = userStore;
  const [ isAlreadySent, setIsAlreadySent ] = useState<boolean>( !!giftStore.thankYouMessage );
  const { settings } = shopStore;
  const [ isReady, setIsReady ] = useState<boolean>(false);
  useNotAvailableItem();
  async function submitThankYou(data) {
    setIsAlreadySent( true );
    if (data.message) {
      sendMetric('accept_finish_thankyou_filled')
      await giftStore.sendThankYou(data.message);
    }
  }

  function onFormChange(data) {
    setIsReady(!!data.message);
  }

  useEffect(() => {
    sendMetric('accept_finish_opened');
  }, [])

  return(
    <>
      <Progress step={ 2 } />
      <main className={ 'main main_theme-recipient accept finish' }>
        <div className={ 'main-container' }>
          <Gift covered={ 'opened' } variant={ 'fullView' } theme={'fullView'}/>

          <Form
            name={ 'accept' }
            onSubmit={ submitThankYou }
            onChange={ onFormChange }
            subtitle={ i18n('recipient.finish.intro.title') }
            className={ 'accept__form' }
          >
            <span className={ 'finish__form-subtitle-desc' }>{ i18n('recipient.finish.intro.subtitle') }</span>
            { !isAlreadySent ? (
              <>
                <Textarea
                  text={  i18n('recipient.finish.thankYouNote.placeholder', { name: userInfo?.firstName || '' }) }
                  name={'message'}
                  outerSize={ '_16-24' }
                  autoFocus={true}
                />
                <Button
                  name={ 'send-finish-comment' }
                  type={ 'submit' }
                  onClick={() => sendMetric('accept_finish_enabled_thankyou')}
                  text={ i18n('recipient.finish.thankYouNote.button') }
                  size={ 'default' }
                  disabled={ !isReady  }
                />
                <a
                  className={ 'button button_variant-link' }
                  href={ settings?.refUrl || settings?.siteUrl }
                  rel={ 'noopener noreferrer' }
                  target={ '_self' } onClick={() => sendMetric('accept_finish_skip_thankyou')}
                >
                  { i18n('recipient.finish.takeMeToPartner', { partner: settings?.name ? settings.name[0].toUpperCase()+settings.name.slice(1) : ''}) }
                </a>
              </>
            ) : (
              <Button
                variant={ 'variant-recipient-disabled' }
                name={ 'final-button' }
                type={ 'button' }
                text={ `${ i18n('recipient.finish.thankYouNote.success') }` }
                disabled={ true }
              >
                <span className={ 'finish__image-button' }/>
              </Button>
            )}
          </Form>
        </div>
      </main>
    </>
  );
});
