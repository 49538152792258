import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ROUTES } from '../../../common/conts';
import { Button } from "../../components/button/button";
import { useHistory } from 'react-router-dom';
import { userStore, shopStore, giftStore, recipientStore } from "../../../stores";
import { getGiftId, getQueryParams } from '../../../common/utils';
import { i18n } from '../../locales';
import { sendMetric } from "../../../common/metrics";
import Info from "../../components/info/info";
import Gift from "../../components/gift/gift";
import {BoxMask} from "../../components/box-mask/box-mask";
import { useNotAvailableItem } from '../../../hooks';

export const GiftcardPage = observer( function () {
  const history = useHistory();
  const [ isCovered, setIsCovered ] = useState(true);
  const { userInfo } = userStore;
  const {settings} = shopStore;
  const { items = [] } = giftStore;
  const params = getQueryParams();

  useNotAvailableItem();

  const address = `${
    (recipientStore.index ?? '') + (recipientStore.index ? ', ' : '') +
    (recipientStore.country ?? '') + (recipientStore.country ? ', ' : '') +
    (recipientStore.city ?? '') + (recipientStore.city ? ', ' : '') +
    (recipientStore.street_address1 ?? '') + (recipientStore.street_address1 ? ', ' : '') +
    (recipientStore.street_address2 ?? '')
  }`;

  useEffect(() => {
    if (params.sender) {
      sendMetric('postcard_sender_opened');
    } else {
      sendMetric('postcard_recipient_opened');
    }
  }, [])

  return(
    <main className={ 'main main_theme-default' }>

      <div className={ 'main-container main-container_theme-giftcard giftcard' }>
        { isCovered ? (
          <>
            <BoxMask className={'box-mask box-mask_theme-giftcard'} brandColor={settings?.brandColor} background={settings?.background}/>
            <div className={ 'giftcard__wrapper-covered-description' }>
              {items.length > 1 ?
                <span className={ 'giftcard__post-subtitle' }>{i18n('giftcard.intro.subtitle.many', {name: userInfo?.firstName || ''})}</span>
                :
                <span className={ 'giftcard__post-subtitle' }>{i18n('giftcard.intro.subtitle', {name: userInfo?.firstName || ''})}</span>
              }
              <span className={ 'giftcard__post-text' }>{ giftStore.occasion?.comment }</span>
            </div>
          </>
        ) : (
          <Gift covered={ 'opened' } variant={ 'fullView' } theme={'giftcard'}/>
        ) }

      </div>

      { isCovered && (
        <Button
          variant={ 'variant-default' }
          size={ 'default' }
          name={ 'open-post-card' }
          type={ 'button' }
          text={(items.length > 1) ? i18n('recipient.unwrap.box.many') : i18n('recipient.unwrap.box.one')}
          onClick={ () => {
            setIsCovered(false);
            if (!params.sender) {sendMetric('postcard_recipient_unpacked');}
          }}
          className={'giftcard__button-wrapper'}
        />
      )}

      <Info
        title={
          items.length > 1 ?
            `${i18n('recipient.unwrap.intro.title.many', { item:`${items.length}`, name: ([userInfo?.firstName, userInfo?.lastName].join(' ')) || ''} )}`
            :
            `${i18n('recipient.unwrap.intro.title', {name: ([userInfo?.firstName, userInfo?.lastName].join(' ')) || ''} )}`
        }
        desc={
          <>
            <div className={'info_bold-desc-giftcard'}>
              {items.length > 1 ?
                i18n('giftcard.intro.subtitle.full.many', {name: ([userInfo?.firstName, userInfo?.lastName].join(' ')) || '',
                  partner: settings?.name ? settings.name[0].toUpperCase()+settings.name.slice(1) : '' })
                :
                i18n('giftcard.intro.subtitle.full', {name: ([userInfo?.firstName, userInfo?.lastName].join(' ')) || '',
                  partner: settings?.name ? settings.name[0].toUpperCase()+settings.name.slice(1) : '' })
              }
            </div>
            {address && address.length && (
              <span>{i18n('giftcard.intro.hasAddress')} { address }</span>
            )}
          </>
        }
        theme={'giftcard'}
        titleVariant={'giftcard'}
        descVariant={'giftcard'}
      />

      { !isCovered && !params.sender && (
        <>
          <p className={'giftcard__thank-you-desc'}>{i18n('giftcard.createNote', {name: [userInfo?.firstName, userInfo?.lastName].join(' ') || ''})}</p>
          <button className={ 'giftcard__button' }
                  onClick={ (e) => {
                    history.push(ROUTES.GIFT_CARD_THANKS.replace(':giftId', getGiftId()));
                  } }>
            {i18n('recipient.finish.thankYouNote.button')}
          </button>
        </>
      )}

    </main>
  );
});
