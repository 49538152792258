import React, { useState } from 'react';
import './select.scss';

interface ISelectOption {
  title: string,
  value: string,
}

export interface ISelectProps {
  title: string,
  name: string,
  label: string,
  value?: string,
  tabindex?: number,
  required?: boolean,
  options: ISelectOption[],
  onChange?: (event) => void,
  size?: string,
  outerSize?: string,
  onClick?: (event) => void,
  autoFocus?: boolean,
}

export function Select(props: ISelectProps) {
  const { options = [] } = props;
  const [ isFocused, setFocus ] = useState<boolean>(false);

  function handleChange(event) {
    if (props.onChange) {
      props.onChange(event);
    }
  }

  function handleClick(event) {
    if (props.onClick) {
      props.onClick(event);
    }
  }

  return (
    <div className={ `select select_outer-size ${ props.outerSize ? 'select_outer-size' + props.outerSize : '' } ${ props.size ?  `select_${props.size}-size` : ''}` }>
      <label className={ 'select__label' } htmlFor={ `select-${props.name}` }>{props.label}{props.required ? ' *' : ''}</label>

      <select
        onFocus={ () => setFocus(true) }
        onBlur={ () => setFocus(false) }
        onChange={ handleChange }
        onClick={ handleClick }
        className={ ['select__element', isFocused ? 'select__element_focus' : ''].join(' ')}
        id={`select-${props.name}`}
        defaultValue={props.value}
        name={props.name}
        autoFocus={props.autoFocus}
      >
        {options.map(option => (
          <option key={option.value} defaultChecked={props.value === option.value} value={option.value}>{option.title}</option>
        ))}
      </select>
      <span className={ 'select__checkmark' }></span>
    </div>
  )
}
