import React from 'react';
import {hexToHSL} from '../../../common/utils';

export interface IGiftRibbonProps {
  brandColor: string,
  className?: string,
}

export function GiftRibbon (props: IGiftRibbonProps) {
  const {brandColor} = props

  return (
    <svg className={ props.className } aria-hidden={ "true" } width="86" height="77" viewBox="0 0 86 77" fill="none"  xmlns="http://www.w3.org/2000/svg">
      <path d="M62.387 -10.4639L70.1342 -2.71668L-5.63532 73.0528C-6.41636 73.8339 -7.6827 73.8339 -8.46375 73.0528L-13.3825 68.134C-14.1636 67.353 -14.1636 66.0867 -13.3825 65.3056L62.387 -10.4639Z" fill="url(#step0_linear)"/>
      <path d="M62.387 -10.4639L70.1342 -2.71668L-5.63532 73.0528C-6.41636 73.8339 -7.6827 73.8339 -8.46375 73.0528L-13.3825 68.134C-14.1636 67.353 -14.1636 66.0867 -13.3825 65.3056L62.387 -10.4639Z" fill="url(#step1_linear)"/>
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="-16" y="-11" width="87" height="86">
        <path d="M62.8935 -10.9708L70.6407 -3.22363L-6.14199 73.559C-6.92304 74.3401 -8.18937 74.3401 -8.97042 73.559L-13.8892 68.6402C-14.6702 67.8592 -14.6702 66.5929 -13.8892 65.8118L62.8935 -10.9708Z" fill="url(#step2_linear)"/>
        <path d="M62.8935 -10.9708L70.6407 -3.22363L-6.14199 73.559C-6.92304 74.3401 -8.18937 74.3401 -8.97042 73.559L-13.8892 68.6402C-14.6702 67.8592 -14.6702 66.5929 -13.8892 65.8118L62.8935 -10.9708Z" fill="url(#step3_linear)"/>
      </mask>
      <g mask="url(#mask0)">
        <g opacity="0.5" filter="url(#filter0_f)">
          <path d="M28.2133 23.9999L34.5772 30.3638L14.7782 50.1628C13.9972 50.9439 12.7308 50.9439 11.9498 50.1628L8.41427 46.6273C7.63322 45.8463 7.63322 44.5799 8.41427 43.7989L28.2133 23.9999Z" fill="black"/>
        </g>
      </g>
      <path d="M17.6573 23.1648C16.9644 18.3841 20.672 14.1005 25.5026 14.1005L27.2713 14.1005C32.1019 14.1005 35.8095 18.3841 35.1166 23.1648L34.6808 26.1717C34.0836 30.2927 30.5511 33.3502 26.387 33.3502C22.2228 33.3502 18.6903 30.2927 18.0931 26.1717L17.6573 23.1648Z" fill="url(#step4_linear)"/>
      <path d="M17.6573 23.1648C16.9644 18.3841 20.672 14.1005 25.5026 14.1005L27.2713 14.1005C32.1019 14.1005 35.8095 18.3841 35.1166 23.1648L34.6808 26.1717C34.0836 30.2927 30.5511 33.3502 26.387 33.3502C22.2228 33.3502 18.6903 30.2927 18.0931 26.1717L17.6573 23.1648Z" fill="url(#step5_linear)" fillOpacity="0.1"/>
      <path d="M17.6572 43.5355C16.9643 48.3162 20.6719 52.5998 25.5025 52.5998L27.2712 52.5998C32.1018 52.5998 35.8093 48.3162 35.1165 43.5355L34.6807 40.5286C34.0834 36.4075 30.5509 33.3501 26.3868 33.3501C22.2227 33.3501 18.6902 36.4075 18.093 40.5286L17.6572 43.5355Z" fill="url(#step6_linear)"/>
      <path d="M17.6572 43.5355C16.9643 48.3162 20.6719 52.5998 25.5025 52.5998L27.2712 52.5998C32.1018 52.5998 35.8093 48.3162 35.1165 43.5355L34.6807 40.5286C34.0834 36.4075 30.5509 33.3501 26.3868 33.3501C22.2227 33.3501 18.6902 36.4075 18.093 40.5286L17.6572 43.5355Z" fill="url(#step7_linear)" fillOpacity="0.1"/>
      <g opacity="0.3" filter="url(#filter1_f)">
        <circle cx="26.8492" cy="33.208" r="9" transform="rotate(30 26.8492 33.208)" fill="#141414"  style={{mixBlendMode: 'multiply'}}/>
      </g>
      <path d="M17.156 41.859C11.9138 42.6187 7.21655 38.5532 7.21655 33.2562C7.21655 27.9591 11.9138 23.8936 17.156 24.6533L26.4663 26.0026L26.4663 40.5097L17.156 41.859Z" fill="url(#step8_linear)"/>
      <path d="M17.156 41.859C11.9138 42.6187 7.21655 38.5532 7.21655 33.2562C7.21655 27.9591 11.9138 23.8936 17.156 24.6533L26.4663 26.0026L26.4663 40.5097L17.156 41.859Z" fill="url(#step9_linear)" fillOpacity="0.35"/>
      <path d="M35.7766 41.8596C41.0188 42.6193 45.7161 38.5538 45.7161 33.2568C45.7161 27.9597 41.0188 23.8942 35.7766 24.654L26.4664 26.0033L26.4664 40.5103L35.7766 41.8596Z" fill="url(#step10_linear)"/>
      <path d="M35.7766 41.8596C41.0188 42.6193 45.7161 38.5538 45.7161 33.2568C45.7161 27.9597 41.0188 23.8942 35.7766 24.654L26.4664 26.0033L26.4664 40.5103L35.7766 41.8596Z" fill="url(#step11_linear)" fillOpacity="0.35" />
      <g opacity="0.3" filter="url(#filter2_f)">
        <circle cx="26.1662" cy="33.3911" r="7.5" transform="rotate(30 26.1662 33.3911)" fill="#141414" style={{mixBlendMode: 'multiply'}}/>
      </g>
      <g filter="url(#filter3_d)">
        <circle cx="26.3796" cy="33.2638" r="7.393" transform="rotate(30 26.3796 33.2638)" fill="url(#step12_linear)"/>
        <circle cx="26.3796" cy="33.2638" r="7.393" transform="rotate(30 26.3796 33.2638)" fill="url(#step13_radial)" fillOpacity="0.3"/>
      </g>
      <defs>
        <filter id="filter0_f" x="0" y="16.9999" width="41.5772" height="41.5772" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur"/>
        </filter>
        <filter id="filter1_f" x="10.5549" y="16.9138" width="32.5885" height="32.5885" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur"/>
        </filter>
        <filter id="filter2_f" x="11.921" y="19.1459" width="28.4904" height="28.4904" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur"/>
        </filter>
        <filter id="filter3_d" x="-8.71936" y="2.16479" width="70.1981" height="70.1981" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="12.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0391667 0 0 0 0 0.327903 0 0 0 0 0.783333 0 0 0 0.4 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        <linearGradient id="step0_linear" x1="-14.2535" y1="73.6581" x2="75.7819" y2="68.1848" gradientUnits="userSpaceOnUse">
          <stop                 stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
          <stop offset="0.4632" stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
          <stop offset="0.8263" stopColor={hexToHSL( brandColor, { h:-7, s:-5, l:+10 })}/>
          <stop offset="0.8528" stopColor={hexToHSL( brandColor, { h:-7, s:-5, l:+10 })}/>
          <stop offset="0.9041" stopColor={hexToHSL( brandColor, { h:-2, s:-4, l:+3 })}/>
          <stop offset="0.9539" stopColor={hexToHSL( brandColor, { h:-2, s:-4, l:+3 })}/>
          <stop offset="1"      stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
        </linearGradient>
        <linearGradient id="step1_linear" x1="67.56" y1="-7.8897" x2="-10.9231" y2="70.5934" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.08"/>
          <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="step2_linear" x1="-14.8022" y1="74.2063" x2="75.9238" y2="69.2806" gradientUnits="userSpaceOnUse">
          <stop                 stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
          <stop offset="0.4632" stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
          <stop offset="0.8263" stopColor={hexToHSL( brandColor, { h:-7, s:-5, l:+10 })}/>
          <stop offset="0.8528" stopColor={hexToHSL( brandColor, { h:-5, s:-4, l:+7 })}/>
          <stop offset="0.9041" stopColor={hexToHSL( brandColor, { h:-2, s:-4, l:+3 })}/>
          <stop offset="0.9539" stopColor={hexToHSL( brandColor, { h:-1, s:-3, l:+1 })}/>
          <stop offset="1"      stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
        </linearGradient>
        <linearGradient id="step3_linear" x1="68.0835" y1="-8.4137" x2="-11.4298" y2="71.0996" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.08"/>
          <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="step4_linear" x1="27.2202" y1="28.8381" x2="27.515" y2="13.3121" gradientUnits="userSpaceOnUse">
          <stop                 stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
          <stop offset="0.4632" stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
          <stop offset="0.8263" stopColor={hexToHSL( brandColor, { h:-7, s:-5, l:+10 })}/>
          <stop offset="0.9539" stopColor={hexToHSL( brandColor, { h:-1, s:-3, l:+1 })}/>
          <stop offset="1"      stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
        </linearGradient>
        <linearGradient id="step5_linear" x1="26.3869" y1="14.1005" x2="26.387" y2="33.3502" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="step6_linear" x1="27.2201" y1="37.8622" x2="27.5148" y2="53.3881" gradientUnits="userSpaceOnUse">
          <stop                 stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
          <stop offset="0.4632" stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
          <stop offset="0.8263" stopColor={hexToHSL( brandColor, { h:-7, s:-5, l:+10 })}/>
          <stop offset="0.9539" stopColor={hexToHSL( brandColor, { h:-1, s:-3, l:+1 })}/>
          <stop offset="1"      stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
        </linearGradient>
        <linearGradient id="step7_linear" x1="26.3868" y1="52.5998" x2="26.3868" y2="33.3501" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="step8_linear" x1="21.9542" y1="32.4229" x2="6.42819" y2="32.1281" gradientUnits="userSpaceOnUse">
          <stop                 stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
          <stop offset="0.4632" stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
          <stop offset="0.8263" stopColor={hexToHSL( brandColor, { h:-7, s:-5, l:+10 })}/>
          <stop offset="0.9539" stopColor={hexToHSL( brandColor, { h:-1, s:-3, l:+1 })}/>
          <stop offset="1"      stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
        </linearGradient>
        <linearGradient id="step9_linear" x1="7.21655" y1="33.2562" x2="26.4663" y2="33.2561" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="step10_linear" x1="30.9784" y1="32.4236" x2="46.5044" y2="32.1288" gradientUnits="userSpaceOnUse">
          <stop                 stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
          <stop offset="0.4632" stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
          <stop offset="0.8263" stopColor={hexToHSL( brandColor, { h:-7, s:-5, l:+10 })}/>
          <stop offset="0.9539" stopColor={hexToHSL( brandColor, { h:-1, s:-3, l:+1 })}/>
          <stop offset="1"      stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
        </linearGradient>
        <linearGradient id="step11_linear" x1="45.7161" y1="33.2568" x2="26.4664" y2="33.2568" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="step12_linear" x1="26.993" y1="37.191" x2="27.2292" y2="25.2656" gradientUnits="userSpaceOnUse">
          <stop                 stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
          <stop offset="0.4632" stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
          <stop offset="0.8263" stopColor={hexToHSL( brandColor, { h:-7, s:-5, l:+10 })}/>
          <stop offset="0.9539" stopColor={hexToHSL( brandColor, { h:-1, s:-3, l:+1 })}/>
          <stop offset="1"      stopColor={hexToHSL( brandColor, { h:-0, s:-0, l:-0 })}/>
        </linearGradient>
        <radialGradient id="step13_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.3229 29.6554) rotate(100.017) scale(11.1717)">
          <stop stopColor="white"/>
          <stop offset="0.874612" stopColor="white" stopOpacity="0"/>
        </radialGradient>
      </defs>
  </svg>
  );
}
