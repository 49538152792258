import React, { useState, useEffect } from 'react';
import Info from '../../components/info/info';
import Gift from '../../components/gift/gift';
import Form from '../../components/form/form';
import { Input } from '../../components/input/input';
import { Select } from '../../components/select/select';
import { Button } from '../../components/button/button';
import { Address } from '../../components/address/address';
import { Phone } from "../../components/phone/phone";
import { recipientStore, giftStore, shopStore } from '../../../stores';
import { createGift, acceptGift } from '../../../common/actions';
import { useHistory } from 'react-router-dom';
import { ROUTES, ERROR, ERROR_TEXTS } from "../../../common/conts";
import { sendMetric } from "../../../common/metrics";
import { observer } from "mobx-react-lite";
import { i18n } from "../../locales";
import { levengerCountries } from '../../../common/countires';
import { levengerStates } from '../../../common/states';
import { validateThroughFlow } from "../../../common/validators";
import { useNotAvailableItem } from '../../../hooks';

export const RecipientDeliveryPage = observer(function  () {
  const [ isReady, setIsReady ] = useState<boolean>(false);
  const [ addressError, setAddressError ] = useState<string>();
  const { code, items, checkoutUrl } = giftStore;
  const { features, countriesList } = shopStore;
  const history = useHistory();

  useNotAvailableItem();

  const [ selectedCountry, setSelectedCountry ] = useState<string>('');
  const [ statesList, setStateList ] = useState<Array<{ title: string, value: string }>>([]);
  const isDigital = items?.find(item => item.digital);

  async function onSubmitRecipientDelivery(data) {
    setIsReady(false);

    if (isDigital) {
      recipientStore.setEmail(data.email);
    } else {
      recipientStore.setFullInfo(data);
    }

    setAddressError('');
    try {
      if (!giftStore.code) {
        await createGift(1);
        sendMetric('newgift_through_checkout'); // id - yes
      }
      await acceptGift();
    } catch (err) {
      if ([ERROR.ADDRESS_NOT_AVAILABLE, ERROR.OZON_ERROR, ERROR.ADDRESS_NOT_EXACT].indexOf(err.message) !== -1) {
        setAddressError(ERROR_TEXTS[err.message]);
      } else {
        console.error('delivery_error', err);
      }

      sendMetric('newgift_through_delivery_error');
    }

  }

  useEffect(() => {
    console.log('code is ready');
  }, [code]);

  useEffect(() => {
    if (checkoutUrl) {
      giftStore.redirectToCheckout();
    }
  }, [checkoutUrl])


  function onFormChange(data) {
    if (statesList.length && data.province === '') {
      setIsReady(false);
      return;
    }
    setIsReady(validateThroughFlow(features, data, isDigital));
  }

  function onThroughDeliveryError() {
    setIsReady(false);
    sendMetric('newgift_through_delivery_error');
  }

  async function skipStep() {
    history.push(ROUTES.SHARE_LINK);
    await createGift(0);
  }

  useEffect(() => {
    if (features.is_levenger_form && selectedCountry) {
      const states = levengerStates[selectedCountry.toLowerCase()];

      if (!states){
        setStateList([]);
        return;
      }

      const options = Object.entries(states).map(([key, value]) => ({ title: value + '', value: key }));

      options.unshift({ title: '', value: '' });
      setStateList(options);
    } else if (features.is_default_form) {
      const country = countriesList.find(item => item.name === selectedCountry);

      if (!country?.provinces?.length){
        setStateList([]);
        return;
      }

      const options = country?.provinces?.map(item => ({ title: item.name + '', value: item.name })) || [];
      options.unshift({ title: '', value: '' });

      country?.provinces?.length
        ? setStateList(options)
        : setStateList([]);
    } else {
      setStateList([]);
    }
  }, [ selectedCountry ]);

  useEffect(() => {
    if (countriesList) {
      setSelectedCountry(countriesList[0].name);
    }
  }, []);

  // scroll page up at ROUTE
  useEffect(() => {
    window.scroll(0,0);
  }, []);

  return (
    <div className={ 'main main_theme-default' }>
      <Info
        title={ i18n('sender.address.intro.title') }
        desc={ i18n('sender.address.intro.subtitle') }
        link={ true }
        className={ 'info_outer-size_24' }
      />
      <div className={ 'main-container main-container_theme-default' }>
        <Gift covered={ 'opened' } variant={ 'boxView' } theme={'boxView'} hidden={ true }/>
        { !isDigital && (
          <Form
            title={ i18n('sender.recipient.form.title') }
            name={ 'recipient-delivery' }
            onSubmit={data => onSubmitRecipientDelivery(data)}
            onChange={onFormChange}
            requiredField={ i18n('form.legend.required') }
            className={ 'form_outer-size-top' }
          >
            <div className={ 'form__fieldset_row' }>
              <Input onError={ onThroughDeliveryError } name={ 'firstName' } title={ i18n('form.field.recipient.firstName') } value={recipientStore.firstName} required={ true } maxLength={ 50 } tabindex={ 0 } size={ 'half-media' } />
              <Input onError={ onThroughDeliveryError } name={ 'lastName' } title={ i18n('form.field.recipient.lastName') } value={recipientStore.lastName} required={ true } maxLength={ 50 } tabindex={ 0 } size={ 'half-media' } autoFocus={true} />
              <Input onError={ onThroughDeliveryError } name={ 'email' } type={ 'email' } title={ i18n('form.field.recipient.email') }  required={ true } maxLength={ 50 } tabindex={ 0 } size={ 'half-media' } />
              <Phone onError={ onThroughDeliveryError } name={ 'phone' } title={ i18n('form.field.recipient.phone') }  required={ true } tabindex={ 0 } size={ 'half-media' } />
              { features.is_ozon_form && (
                <>
                  <Address error={ addressError } onError={ onThroughDeliveryError } name={ 'address' } title={ 'Город, улица, дом' }  required={ true } tabindex={ 0 } size={ 'full' } />
                  <Input onError={ onThroughDeliveryError } name={ 'apartment' } title={ i18n('form.field.apartmentSuiteRoom') }  required={ true } maxLength={ 50 } tabindex={ 0 } size={ 'half' } />
                  <Input onError={ onThroughDeliveryError } name={ 'index' } title={ i18n('form.field.postZip') }  required={ true } tabindex={ 0 } maxLength={ 50 } size={ 'half' } outerSize={ '_16-24' } />
                </>
              )}
              { features.is_levenger_form && (
                <>
                  <Select
                    title={ '' }
                    name={ 'country' }
                    label={ i18n('form.field.country') }
                    required={ true }
                    tabindex={ 0 }
                    onChange={ (event) => setSelectedCountry(event.target.value) }
                    options={ levengerCountries }
                  />
                  { statesList.length > 0 && (
                    <Select
                      title={ '' }
                      name={ 'province' }
                      label={ i18n('form.field.countyState') }
                      required={ true }
                      tabindex={ 0 }
                      options={ statesList }
                    />
                  )}
                  <Input onError={ onThroughDeliveryError } name={ 'street_address1' } title={ i18n('form.field.addressLine1') } required={ true } maxLength={ 255 } tabindex={ 0 } size={ 'full' }/>
                  <Input name={ 'street_address2' } title={ i18n('form.field.addressLine2') } required={ false } maxLength={ 255 } tabindex={ 0 } size={ 'full' }/>
                  <Input onError={ onThroughDeliveryError } name={ 'index' } title={ i18n('form.field.postZip') } required={ true } maxLength={ 50 } tabindex={ 0 } size={ 'half' }/>
                  <Input onError={ onThroughDeliveryError } name={ 'city' } title={ i18n('form.field.city') } required={ true } maxLength={ 50 } tabindex={ 0 } size={ 'half' }/>
                </>
              )}

              { features.is_pollini_form && (
                <>
                  <Select
                    title={ '' }
                    name={ 'country' }
                    label={ i18n('form.field.country') }
                    required={ true }
                    tabindex={ 0 }
                    onChange={ (event) => setSelectedCountry(event.target.value) }
                    options={ countriesList.map(item => ({
                      value: item.name,
                      title: item.name,
                      key: item.code,
                    })) }
                  />
                  <Input onError={ onThroughDeliveryError } name={ 'province' } title={ i18n('form.field.countyState') } required={ true } maxLength={ 255 } tabindex={ 0 } size={ 'full' }/>
                  <Input onError={ onThroughDeliveryError } name={ 'street_address1' } title={ i18n('form.field.addressLine1') } required={ true } maxLength={ 255 } tabindex={ 0 } size={ 'full' }/>
                  <Input name={ 'street_address2' } title={ i18n('form.field.addressLine2') } required={ false } maxLength={ 255 } tabindex={ 0 } size={ 'full' }/>
                  <Input onError={ onThroughDeliveryError } name={ 'index' } title={ i18n('form.field.postZip') } required={ true } maxLength={ 50 } tabindex={ 0 } size={ 'half' }/>
                  <Input onError={ onThroughDeliveryError } name={ 'city' } title={ i18n('form.field.city') } required={ true } maxLength={ 50 } tabindex={ 0 } size={ 'half' }/>
                </>
              )}

              { features.is_default_form && (
                <>
                  <Select
                    title={ '' }
                    name={ 'country' }
                    label={ i18n('form.field.country') }
                    required={ true }
                    tabindex={ 0 }
                    onChange={ (event) => setSelectedCountry(event.target.value) }
                    options={countriesList.map(item => ({
                      value: item.name,
                      title: item.name,
                      key: item.code,
                    })) }
                  />
                  { statesList.length !== 0 && (
                    <Select
                      title={ '' }
                      name={ 'province' }
                      label={ i18n('form.field.countyState') }
                      required={ true }
                      tabindex={ 5 }
                      options={ statesList }
                    />
                  )}
                  <Input onError={ onThroughDeliveryError } name={ 'street_address1' } title={ i18n('form.field.addressLine1') } required={ true } maxLength={ 255 } tabindex={ 0 } size={ 'full' }/>
                  <Input name={ 'street_address2' } title={ i18n('form.field.addressLine2') } required={ false } maxLength={ 255 } tabindex={ 0 } size={ 'full' }/>
                  <Input onError={ onThroughDeliveryError } name={ 'index' } title={ i18n('form.field.postZip') } required={ true } maxLength={ 50 } tabindex={ 0 } size={ 'half' }/>
                  <Input onError={ onThroughDeliveryError } name={ 'city' } title={ i18n('form.field.city') } required={ true } maxLength={ 50 } tabindex={ 0 } size={ 'half' }/>
                </>
              )}
            </div>
            <Button disabled={!isReady || giftStore.isAccepting} name={ 'send-delivery-data' } text={ i18n('form.action.next') } type={ 'submit' } size={ 'default' } />

            <Button onClick={ skipStep } variant={ 'variant-link' } name={ 'skip-occasion-data' } text={ i18n('sender.address.form.switch') } type={ 'submit' } />

            <span className={'recipient-delivery__tou'}>
              { i18n('form.legend.terms.1') }
              <a className={ 'accept__tou-link' } href={'/pages/privacy-policy'} target={'_blank'}>
                { i18n( 'form.legend.privacy') }
              </a>{i18n('form.legend.privacy.&')}
              <a className={ 'accept__tou-link' } href={'/pages/terms-of-use'} target={'_blank'}>
                { i18n( 'form.legend.terms.2') }
              </a>
            </span>
          </Form>
        )}
        {isDigital && (
          <Form
            title={ i18n( 'sender.recipient.form.title' ) }
            direction={ 'row' }
            name={ 'recipient-delivery' }
            onSubmit={data => onSubmitRecipientDelivery(data)}
            onChange={onFormChange}
            className={ 'form_outer-size-top' }
          >
            <Input name={ 'email' } type={ 'email' } title={ i18n( 'form.field.recipient.email' ) }  required={ true } maxLength={ 50 } tabindex={ 0 } size={ 'full' } autoFocus={true} />
            <Button disabled={!isReady || giftStore.isAccepting} size={ 'default' } name={ 'send-delivery-data' } text={ i18n( 'form.action.next' ) } type={ 'submit' }/>
            <Button onClick={skipStep} variant={ 'variant-link-with-position-desktop' } name={ 'skip-occasion-data' } text={ i18n( 'sender.email.form.switch' ) } type={ 'submit' }/>
          </Form>
        )}
      </div>
    </div>
  );
});
