import React, {useEffect, useState} from 'react';
import Certificates  from '../components/certificates/certificates';
import { useHistory } from 'react-router-dom';
import { Substitutes } from '../components/substitutes/substitutes';
import { giftStore, shopStore } from "../../stores";
import { ROUTES } from "../../common/conts";
import { observer } from "mobx-react-lite";
import { sendMetric } from "../../common/metrics";
import { getGiftId } from "../../common/utils";
import {i18n} from "../locales";

enum View {
  CERTIFICATES,
  PRODUCTS
}

interface IProps {
  isRecipient?: boolean
}

export const AlternativesPage = observer(function  (props: IProps) {
  const [ shovedView, setShovedView ] = useState<View>(View.PRODUCTS);
  const { replacedItem } = giftStore;
  const history = useHistory();

  function handleDoneCertificate(cert) {
    giftStore.swapMainItemByCertificate(cert);
    sendMetric('newgift_500rurflow_cert_started');
    history.push(ROUTES.RECIPIENT_FORM);
  }

  async function handleDoneSubstitutes(item) {
    sendMetric('newgift_500rurflow_choosen');
    giftStore.swapItemBySubstitute(item);

    if (props.isRecipient || giftStore.code) {
      history.push(ROUTES.ACCEPT_FORM.replace(':giftId', giftStore.code || getGiftId()));
    } else {
      history.push(ROUTES.RECIPIENT_FORM);
    }
  }

  useEffect(() => {
    // показываем сертификаты только для алтернтив озона со стороны сенедра
    if (!props.isRecipient && shopStore.settings?.subdomain_name === 'ozon') {
      giftStore.loadCertificates().catch(err => console.error('load certificates error', err));
      setShovedView(View.CERTIFICATES);
    }

    sendMetric('newgift_500rurflow');
  }, [ ]);

  // scroll page up at ROUTE
  useEffect(() => {
    window.scroll(0,0);
  }, []);

  return (
    <div className="main main_theme-alternative">
      <section className={ 'alternatives' }>
        <div className={ 'oos-gift' }>
          <div className={ 'oos-gift__image-box' }>
            <img className={ 'oos-gift__image' } src={replacedItem?.image_url} alt={ 'out of stock gift' } />
          </div>
          <div className={ 'oos-gift__description' }>
            <span className={ 'oos-gift__description-title' }>{replacedItem?.name}</span>
            <span className={ 'oos-gift__description-cost' }>{replacedItem?.price} {replacedItem?.currency}</span>
          </div>
        </div>
        <span className={ 'alternatives__orange-warning' }>Unfortunately this gift from [sender_name] is out of stock</span>
        { shovedView === View.CERTIFICATES && (
          <section className="alternatives__content">
            <h1 className={ 'alternatives__title' }>Предлагаем вам отправить электронный сертификат OZON</h1>
            <span className={ 'alternatives__desc' }>Получатель сам сможет выбрать себе идеальный подарок из миллионов товаров в нашем каталоге</span>
            <Certificates onDone={handleDoneCertificate}/>
            <button className="alternatives__button-select button_pressed-down" onClick={() => {
              sendMetric('newgift_500rurflow_cert_go_alt');
              setShovedView(View.PRODUCTS);
            }}>
              или <span className="alternatives__button-select_theme"> Выберите </span>другой подарок
            </button>
          </section>
        )}
        { shovedView === View.PRODUCTS && (
          <section className="alternatives__content">
            {props?.isRecipient ? (
              <h1 className={ 'alternatives__title' }>{i18n('oos.title')}</h1>
            ) : (
              <h1 className={ 'alternatives__title' }>{i18n('oos.title')}</h1>
            )}
            <Substitutes onDone={handleDoneSubstitutes}/>
          </section>
        )}
      </section>
    </div>
  );
});
