import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { ROUTES } from '../../../common/conts';
import { Button } from "../../components/button/button";
import { useHistory } from 'react-router-dom';
import {userStore, shopStore, giftStore} from "../../../stores";
import {getGiftId, getRef} from '../../../common/utils';
import { i18n } from '../../locales';
import { sendMetric } from "../../../common/metrics";
import  DefaultBanner from '../../../image/banner-default.png';
import {BoxMask} from '../../components/box-mask/box-mask';
import { useNotAvailableItem } from '../../../hooks';

const REF_CHANNELS = {
  glcf1: 'email_intermediate',
  glcf2: 'email_reminder_1st',
  sglcf: 'smslink'
};

export const GiftBoxPage = observer( function () {
  const history = useHistory();
  const { userInfo } = userStore;
  const { thankYouMessage } = giftStore;
  const { settings } = shopStore;
  const ref = getRef();
  const { items = [] } = giftStore;
  useNotAvailableItem();
  useEffect(() => {
    thankYouMessage && history.push(ROUTES.FINISH.replace(':giftId', getGiftId()));
  }, [ thankYouMessage ])

  useEffect(() => {
    sendMetric('accept_landing_opened');
    // трэкай переход из ссылки
    if (ref && REF_CHANNELS[ref]) {
      sendMetric(`accept_opened_src_sender_${REF_CHANNELS[ref]}`);
    }
  }, [])

  return(
    <main className={ 'main main_theme-recipient giftbox' }>
      <BoxMask className={'box-mask box-mask_theme-recipient'}
               brandColor={ settings?.brandColor ? settings.brandColor : '#3678DC'}
               background={ settings?.background ? settings.background : DefaultBanner }
               aria-hidden={ true }
      />
      <Button className={'giftbox__button'} variant={ 'variant-default' } size={ 'recipient-giftbox' }
              name={ 'open-gift-box' } type={ 'button' }
              text={ (items.length > 1) ? i18n('recipient.unwrap.box.many') : i18n('recipient.unwrap.box.one') }
              onClick={ () => { history.push(ROUTES.ACCEPT_FORM.replace(':giftId', getGiftId())) } }/>

      <h1 className={ 'giftbox__title' }>
        {items.length > 1 ?
          `${i18n('recipient.unwrap.intro.title.many', { item:`${items.length}`, name: ([userInfo?.firstName, userInfo?.lastName].join(' ')) || ''} )}`
          :
          `${i18n('recipient.unwrap.intro.title', {name: ([userInfo?.firstName, userInfo?.lastName].join(' ')) || ''} )}`
        }
      </h1>
      {giftStore.occasion?.comment && (<p className={ 'giftbox__subtitle' }>{ giftStore.occasion?.comment }</p>)}
    </main>

  );
});
