export const USER_UUID_HEADER = 'X-User-Id';
export const USER_SESSION_HEADER = 'X-Session-Id';

export const ROUTES = {
  RECIPIENT_FORM: '/wrap/recipient',
  SENDER_FORM: '/wrap/sender',
  OCCASION: '/wrap/occasion',
  SELECT_WRAP_TYPE:  '/wrap/select-wrap-type',
  DELIVERY_FORM: '/wrap/recipient-delivery-info',
  SHARE_LINK: '/wrap/share-link',
  RECIPIENT_ALTERNATIVES: '/gift/:giftId/alternatives',
  ALTERNATIVES: '/wrap/alternatives',
  ERROR: '/wrap/error',
  GIFT_BOX: '/gift/:giftId',
  ACCEPT_FORM: '/gift/:giftId/accept',
  FINISH: '/gift/:giftId/finish',
  GIFT_CARD: '/giftcard/:giftId',
  GIFT_CARD_THANKS: '/giftcard/:giftId/thanks',
};

export const ERROR = {
  ADDRESS_NOT_AVAILABLE: 'address_not_available',
  ADDRESS_NOT_EXACT: 'address_not_exact',
  OZON_ERROR: 'ozon_error',
};


// TODO перенести в i18n после влития branding
export const ERROR_TEXTS = {
  [ERROR.ADDRESS_NOT_AVAILABLE]: ' К сожалению, в данный момент доставка товаров Ozon на данный адрес не доступна',
  [ERROR.ADDRESS_NOT_EXACT]: 'Неточный адрес',
  [ERROR.OZON_ERROR]: 'сетевая ошибка или ошибка на стороне озона',
};

export const MINIMAL_GIFT_PRICE = 999;

export const FLOW_STATUS = {
  DEFAULT: 'default',
  RECIPIENT_SHIPPING_INFO_DONE: 'recipient_shipping_info_done',
  SMS_AWAITING: 'sms_awaiting',
  SMS_CONFIRMED: 'sms_confirmed',
};

export const STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  COMPLETED: 'completed',
};

export const ABTEST = {
  MOBILE_SHARING_TEST: 'mobile-sharing-test',
  MOBILE_SHARING: 'mobile-sharing',
};