import React, { useState } from 'react';
import './textarea.scss';

export interface ITextareaProps {
  name?: string,
  text?: string,
  value?: string,
  size?: string,
  outerSize?: string,
  maxLength?: number,
  placeholder?: string,
  autoFocus?: boolean,
}

export function Textarea (props: ITextareaProps) {
  const [value, setValue] = useState<string>(props.value || '');
  const [ isFocused, setFocus ] = useState<boolean>(false);


  return (
    <div className={ `textarea textarea_outer-size 
    ${ props.outerSize ? 'textarea_outer-size' + props.outerSize : '' } 
    ${ props.size ?  `textarea_${props.size}-size` : ''}
    ${ isFocused ? 'textarea_focus' : '' }
    ` }>
      <textarea
        name={props.name}
        className={ 'textarea__box' }
        onFocus={ () => { setFocus(true) } }
        onBlur={ () => { setFocus(false) } }
        onChange={ event => setValue(event.target.value) }
        defaultValue={ value }
        id={ `textarea-${props.name }` }
        rows={ 4 }
        maxLength={props.maxLength}
        placeholder={ props.placeholder }
        autoFocus={props.autoFocus}
      />
      <label
        className={[ 'textarea__label', value || isFocused ? 'textarea__label_tiny' : '' ].join(' ') }
        htmlFor={ `textarea-${props.name}` }
      >
          {props.text}
      </label>
    </div>
  );
}
