import * as Sentry from "@sentry/react";

export class Logger {
  private label;

  constructor(label: string) {
    this.label = label
  }

  private transformMeta(meta: Array<any>): Array<any> {
    return JSON.parse(JSON.stringify(meta)) as Array<any>;
  }

  info(message, ...meta) {
    console.log(`[${this.label}] ${message}`, ...this.transformMeta(meta));
  }
  warn(message, ...meta) {
    console.warn(`[${this.label}] ${message}`, ...this.transformMeta(meta));
    Sentry.captureEvent(message);
  }
  debug(message, ...meta) {
    console.debug(`[${this.label}] ${message}`, ...this.transformMeta(meta));
  }
  error(message, ...meta) {
    console.error(`[${this.label}] ${message}`, ...this.transformMeta(meta));
  }
}

