import {observable, action, runInAction, makeObservable} from 'mobx';
import {getUserUUID, getDefaultHeaders, isDefaultCheckedCF, checkDefaultABTest} from '../../common/utils';
import {Logger} from '../../common/logger';
import {autoSave} from '../auto-save';

export interface IUserInfo {
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  sender_optin?: boolean;
}

export interface IUserStore {
  uuid: string; // уникальный идентификатор, если не передаетя то задаем сами
  fromapp?: boolean; // признак того, что аппка открывается в мобильном вебвью
}

const logger = new Logger('UserStore');

export class UserStore implements IUserStore {
  @observable loaded: boolean = false;
  @observable guid: string = '';
  @observable senderId: string = '';
  @observable uuid: string = getUserUUID();
  @observable fromapp?: boolean;
  @observable experiment?: string;
  @observable variation?: string;
  @observable userInfo?: IUserInfo;
  @observable isReady = false;
  @observable currentFlow?: string = '';
  @observable shareVariant?: string = '';
  @observable isClassicFlow: boolean = false;
  @observable isSenderSuperflow?: boolean = false;

  constructor() {
    makeObservable(this);
  }

  init() {
    autoSave(this, 'UserStore');
  }

  @action setIsSenderSuperflow(state: boolean) {
    this.isSenderSuperflow = state;
  }

  @action setIsReady(state: boolean) {
    this.isReady = state;
  }

  @action setIsClassicFlow(state: boolean) {
    this.isClassicFlow = state;
  }

  @action load() {
    return fetch(`/api/ab/user/${this.uuid}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...getDefaultHeaders(),
      },
      body: JSON.stringify({
        gift: {
          shop: null,
          variant_id: null,
        },
        user_data: {
          fromapp: this.fromapp
        },
        ...(process.env.REACT_APP_EXPERIMENT_DIRECT === '1' && {
          variation: process.env.REACT_APP_VARIATION,
          experiment: process.env.REACT_APP_EXPERIMENT
        }),
      })
    }).then(res => res.json()).then(data => runInAction(() => {
      this.experiment = data.experiment;
      this.variation = data.variation;
      this.loaded = true;
      const isDefaultCase = checkDefaultABTest(data.experiment, data.variation);
      this.setIsClassicFlow(!isDefaultCase);
      logger.info(`loaded`, data);
    }))
  }

  @action setIds(guid: string, senderId: string) {
    runInAction(() => {
      this.guid = guid;
      this.senderId = senderId;
      logger.info('setIds', { guid, senderId });
    })
  }

  @action setFio({ firstName, lastName }) {
    runInAction(() => {
      this.userInfo = {
        firstName, lastName,
      }
      logger.info('setFio', { firstName, lastName });
    })
  }


  @action setContacts(email: string, phone: string) {
    runInAction(() => {
      this.userInfo = {
        ...this.userInfo ?? {},
        email, phone,
      }
    })
  }


  @action setUserInfo(userInfo: IUserInfo) {
    this.userInfo = userInfo;
  }

  @action setCurrentFlow(currentFlow) {
    runInAction(() => {
      this.currentFlow = currentFlow;
        logger.info('currentFlow', { currentFlow });
    })
  }

  @action setShareVariant(shareVariant) {
    runInAction(() => {
      this.shareVariant = shareVariant;
      logger.info('shareVariant', { shareVariant });
    })
  }
}
