import React, { useState, useEffect } from 'react';
import './phone.scss';
import '../../vendors/react-phone-2/lib/style.css';
import PhoneInput from '../../vendors/react-phone-2/src';
import { i18n } from "../../locales";
import { shopStore } from '../../../stores';
import { isValidPhoneNumber, CountryCode } from 'libphonenumber-js';
import rawCountries from '../../vendors/react-phone-2/src/rawCountries';

import de from '../../vendors/react-phone-2/lang/de.json';
import ru from '../../vendors/react-phone-2/lang/ru.json';
import it from '../../vendors/react-phone-2/lang/it.json';

export interface IPhoneProps {
  title: string,
  name: string,
  value?: string,
  tabindex?: number,
  required?: boolean,
  onError?: () => void;
  size?: string,
  outerSize?: string,
}

const locales = {
  de, ru, it, en: null,
}

export function Phone(props: IPhoneProps) {
    const { settings = { lang: 'en', defaultCountry: '', onlyDefaultCountry: true } } = shopStore;

  const [ isFocused, setFocus ] = useState<boolean>(false);
  const [ value, setValue ] = useState<string | null>(props.value || null);
  const [ error, setError] = useState<string>();
  const defaultCountry = settings.defaultCountry?.toLowerCase();
  const [ countryCode, setCountryCode ] = useState<string>();

  function validate() {
    const country = countryCode?.toUpperCase() as CountryCode;
    const checkedValue = value ? value.replace(/([^\d]+)/g, '') : ''

    if (value === null) {
      setError('');
    } else if (!checkedValue && props.required) {
      setError( i18n('form.error.fieldRequired') );
    } else if (!isValidPhoneNumber('+' + checkedValue || '', country)) {
      setError(i18n('form.error.phoneNotValid'));
    } else {
      setError('')
    }
  }

  useEffect(() => {
    if (isFocused && !value) {
      setCountryCode(shopStore.countryCode.toLowerCase());
      setValue(shopStore.countryCode.toLowerCase());
    }
  }, [ isFocused ])

  useEffect(() => {
    validate();
  }, [ value ]);

  useEffect(() => {
    if (props.onError && error && !isFocused) {
      props.onError();
    }
  }, [ error ]);

  function handleChange(value) {
    // модифицируем только для русских партнеров
    if (settings.lang === 'ru' && /^8.*/.test(value)) {
      value = value.replace(/^(\d)(.*)/, '7$2');
    }

    setValue(value);
  }

  function handleFocus() {
    if ((!countryCode || !value) && defaultCountry) {
      const countryData = rawCountries.find(item => item[2] === defaultCountry);
      if (countryData && countryData[3]) {
        setCountryCode(defaultCountry);
        setValue(countryData[3] + '')
      }
    }
    setFocus(true);
  }

  return (
    <div className={ ['phone phone_outer-size', `${ props.outerSize ? 'phone_outer-size' + props.outerSize : '' }`, `${ props.size ? `phone_${props.size}-size` : '' }`,
      isFocused ? 'phone_focused' : '',
      value ? 'phone_has-value' : '',
      error ? 'phone_invalid' : '',
    ].join(' ') }>
      <PhoneInput
        country={ countryCode }
        onChange={(value: string, countryData: any) => {
          setCountryCode(countryData.countryCode);
          handleChange(value)
        }}
        disableDropdown={settings.onlyDefaultCountry}
        countryCodeEditable={!settings.onlyDefaultCountry}
        localization={ locales[settings.lang] || {} }
        searchPlaceholder={i18n('form.field.countrySearch')}
        onFocus={handleFocus}
        onBlur={() => { setFocus(false); setValue(value || ''); validate(); }}
        value={value || ''}
        enableSearch={true}
      />
      <label className={['phone__label', value || isFocused ? 'phone__label_tiny' : '' ].join(' ')} htmlFor={`phone-${props.name}`}>
        {props.title}{props.required ? ' *' : ''}
      </label>
      {error && (
        <div className={ 'phone__error' }>
          <input type={'hidden'} name={`error`} value={error}/>
          { error }
        </div>
      )}
    </div>
  )
}
