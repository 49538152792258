import React, { useEffect, useState } from 'react';
import './wrap-type-selector.scss';
import { sendMetric } from "../../../common/metrics";
import { i18n } from '../../locales';
import { shopStore } from "../../../stores";

enum WrapType {
  LINK,
  FULL
}

export function WrapTypeSelector () {
  const [selectedWrapType, setSelectedWrapType] = useState<WrapType>(WrapType.LINK);
  const {settings} = shopStore;

  function handleChange(type: WrapType) {
    sendMetric(type === WrapType.FULL ? 'newgift_through_clicked' : 'newgift_classic_clicked');
    setSelectedWrapType(type);
  }

  function textBrandColor() {
    if ( settings?.name === '!!!sg' ) {
      return('mobile-radio__text_checked');
    } else {
      return ('');
    }
  }

  return (
    <div className={'container'}>

      <div className={'container_mobile'}>
        <div className={'mobile-radio'}>
          <label className={ `mobile-radio__label mobile-radio__label_left ${ selectedWrapType === WrapType.LINK  ? 'mobile-radio__label_left-checked' : '' }`}>
            <input
              className={'mobile-radio__input'}
              type="radio"
              name={'wrap_type'}
              value={'send_link'}
              defaultChecked={true}
              onChange={ () => handleChange(WrapType.LINK) }
            />
            <span className={`mobile-radio__img mobile-radio__img_many-address ${ selectedWrapType === WrapType.LINK ? 'mobile-radio__img_checked' : '' }` } />
            <span className={`mobile-radio__text ${ selectedWrapType === WrapType.LINK ? textBrandColor() : '' }`}>
              { i18n('sender.flow.classic.title.mobile.1') }<br/>
              { i18n('sender.flow.classic.title.mobile.2') }
            </span>
          </label>
          <label className={`mobile-radio__label mobile-radio__label_right ${ selectedWrapType === WrapType.FULL  ? 'mobile-radio__label_right-checked' : '' }`}>
            <input
              className={'mobile-radio__input'}
              type="radio"
              value={'address'}
              name={'wrap_type'}
              onChange={ () => handleChange(WrapType.FULL) }
            />
            <span className={`mobile-radio__img mobile-radio__img_one-address ${ selectedWrapType === WrapType.FULL ? 'mobile-radio__img_checked' : '' }`}/>
            <span className={`mobile-radio__text ${ selectedWrapType === WrapType.FULL ? textBrandColor() : '' }`}>
              { i18n('sender.flow.through.title.mobile.1') }<br/>
              { i18n('sender.flow.through.title.mobile.2') }
            </span>
          </label>
        </div>

        <div className={'mobile-list'}>
          <ul className={'mobile-list__box'}>
            <li className={'mobile-list__item'}>
              { selectedWrapType === WrapType.LINK  ? i18n('sender.flow.classic.description.1') : i18n('sender.flow.through.description.1') }
            </li>
            <li className={'mobile-list__item'}>
              { selectedWrapType === WrapType.LINK  ? i18n('sender.flow.classic.description.2') : i18n('sender.flow.through.description.2') }
            </li>
            <li className={'mobile-list__item'}>
              { selectedWrapType === WrapType.LINK  ? i18n('sender.flow.classic.description.3') : i18n('sender.flow.through.description.3') }
            </li>
          </ul>
        </div>
      </div>

      <div className={ 'container_desktop' }>
        <div className={ 'radio' }>
          <label className={ `radio__label ${ selectedWrapType === WrapType.LINK  ? 'radio__label_checked' : '' }` }>
            <input
              className={ 'radio__input' }
              type={ 'radio' }
              value={ 'send_link' }
              name={ 'wrap_type' }
              defaultChecked={ true }
              onChange={ () => handleChange(WrapType.LINK) }
            />
            <span className={ 'radio__pseudo-input' } />
            <span className={ 'radio__img radio__img_many-address' } />
            <div className={ 'radio__text-box text-box' }>
              <h3 className={ 'text-box__title' }>{ i18n('sender.flow.classic.title') }</h3>
                <ul className={ 'text-box__list' }>
                  <li className={ 'text-box__item' }>{ i18n('sender.flow.classic.description.1') }</li>
                  <li className={ 'text-box__item' }>{ i18n('sender.flow.classic.description.2') }</li>
                  <li className={ 'text-box__item' }>{ i18n('sender.flow.classic.description.3') }</li>
                </ul>
              </div>
          </label>
        </div>
        <div className={ 'radio' }>
          <label className={ `radio__label ${ selectedWrapType === WrapType.FULL  ? 'radio__label_checked' : '' }` }>
            <input
              className={ 'radio__input' }
              type={ 'radio' }
              value={ 'address' }
              name={ 'wrap_type' }
              onChange={ () => handleChange(WrapType.FULL) }
            />
            <span className={ 'radio__pseudo-input' } />
            <span className={ 'radio__img radio__img_one-address' } />
            <div className={ 'radio__text-box text-box' }>
              <h3 className={ 'text-box__title' }>{ i18n('sender.flow.through.title') }</h3>
              <ul className={ 'text-box__list' }>
                <li className={ 'text-box__item' }>{ i18n('sender.flow.through.description.1') }</li>
                <li className={ 'text-box__item' }>{ i18n('sender.flow.through.description.2') }</li>
                <li className={ 'text-box__item' }>{ i18n('sender.flow.through.description.3') }</li>
              </ul>
            </div>
          </label>
        </div>
      </div>
    </div>

  );
}
