import {observable, action, runInAction, makeObservable} from 'mobx';
import { Logger } from "../../common/logger";
import { autoSave } from '../auto-save';
import { getDefaultHeaders } from '../../common/utils';

export interface IRecipientStore {
  firstName?: string;
  lastName?: string;
  address?: string;
  email?: string;
  phone?: string;
  apartment?: string;
  index?: string;
  country?: string;
  street_address1?: string;
  street_address2?: string;
  city?: string;
  province?: string;
  isCompleted?: boolean;
  recipient_optin?: boolean;
}

interface IShippingInfoRequest {
    first_name?: string;
    last_name?: string;
    phone?: string;
    email?: string;
    recipient_optin?: boolean;
    note?: string;
}

const logger = new Logger('RecipientStore');

export class RecipientStore implements IRecipientStore {
  @observable firstName?: string;
  @observable lastName?: string;
  @observable fullName?: string;
  @observable address?: string;
  @observable email?: string;
  @observable phone?: string;
  @observable apartment?: string;
  @observable index?: string;
  @observable isCompleted = false;
  @observable country?: string;
  @observable street_address1?: string;
  @observable street_address2?: string;
  @observable city?: string;
  @observable province?: string;
  @observable recipient_optin?: boolean;
  @observable currentFlow?: string = '';

  constructor() {
    makeObservable(this);
  }

  init() {
    autoSave(this, 'RecipientStore');
  }

  @action setFio({ firstName, lastName, fullName }) {
    runInAction(() => {
      this.firstName = firstName;
      this.lastName = lastName;
      this.fullName = fullName || [`${firstName || ''}`, `${lastName || ''}`].join(' ');
      logger.info('setFio', { firstName, lastName, fullName });
    })
  }

  @action setEmail(email: string) {
    this.email = email;
  }

  @action setContacts(email: string, phone: string) {
    runInAction(() => {
      this.email = email;
      this.phone = phone;
    })
  }

  @action setTransferRecipientData(status: boolean) {
    runInAction(() => {
      this.recipient_optin = status;
    })
  }

  @action setFullInfo(data: Partial<IRecipientStore>) {
    runInAction(() => {
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.address = data.address;
      this.email = data.email;
      this.phone = data.phone;
      this.apartment = data.apartment;
      this.index = data.index;
      this.country = data.country;
      this.street_address1 = data.street_address1;
      this.street_address2 = data.street_address2;
      this.city = data.city;
      this.province = data.province;
      this.isCompleted = true;
    });
  }

  @action setCurrentFlow(currentFlow) {
    runInAction(() => {
      this.currentFlow = currentFlow;
      logger.info('currentFlow', { currentFlow });
    })
  }

  @action setRecipientShipping(data: Partial<IRecipientStore>) {
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.email = data.email;
      this.phone = data.phone;
      this.recipient_optin = data.recipient_optin;
  }

  @action async sendRecipientShipping(code, message) {
    const request:IShippingInfoRequest = {
      first_name: this.firstName,
      last_name: this.lastName,
      phone: this.phone,
      email: this.email,
      recipient_optin: this.recipient_optin,
    };

    if (message && message.trim().length) {
      request.note = message;
    }

    try {
      await fetch(`/api/gift/${code}/recipient_shipping`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getDefaultHeaders(),
        },
        body: JSON.stringify(request)
      })
    } catch (err) {
      logger.warn('send shipping info error', { err });
    }
  }
}
