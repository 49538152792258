import React from 'react';
import { observer } from 'mobx-react-lite';
import { shopStore } from '../../../stores';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../../common/conts';
import './header.scss';
import '../../app.scss';
import { sendMetric } from "../../../common/metrics";
import { i18n } from '../../locales';
import {getGiftId} from "../../../common/utils";

export interface IHeaderProps {
  role?: boolean,
}
const Header = observer((props: IHeaderProps) => {
  const location = useLocation();
  const { settings, fromApp } = shopStore;

  const backLinkVisible = [ ROUTES.ALTERNATIVES, ROUTES.RECIPIENT_ALTERNATIVES.replace(':giftId', getGiftId()), ROUTES.RECIPIENT_FORM ].includes(location.pathname);

  // отступ меньше из-за большого контента
  const headerOuterSizeRecipient = [ ROUTES.DELIVERY_FORM, `${ settings?.name !== 'ozon' && ( ROUTES.ACCEPT_FORM.replace(':giftId', getGiftId()) ) }`, `${ settings?.name !== 'ozon' && ( ROUTES.FINISH.replace(':giftId', getGiftId()) ) }` ].includes(location.pathname);

  return (
    <>
      { !fromApp && (
        <div className={ `header ${shopStore.appName === 'mygiftmagic' ?  'header_theme-rebranding' : 'header_theme-default' } ${ headerOuterSizeRecipient ? 'header_size-outer-24px' : '' }` }>
          {/*back link element*/}
          <a
            className={ `header__back-link ${ backLinkVisible ? '' : 'header__back-link_hidden'  }` }
            href={ settings?.siteUrl }
            aria-hidden={ props.role }
            tabIndex={0}
            onClick={() => {
              sendMetric('newgift_500rurflow_backtoshop');
              location.href = settings?.siteUrl;
            }}
          >
            <span
              className={ 'header__back-link-img' }
              title={ 'link to page back' }
            />
            <span className={ 'header__back-link-text' }>
              { i18n('common.navigation.backTo') } {settings?.name ? settings.name[0].toUpperCase()+settings.name.slice(1) : 'shop'}
            </span>
          </a>
          {/* logo element */}
          { settings?.logo ? (
            <img className={ `header__logo header__logo_default` } src={settings?.logo }/>
          ) : (
            <span className={ 'header__logo header__logo_text' }>{settings?.name}</span>
          )}
          {/* brand element */}
          {shopStore.appName !== 'mygiftmagic' ? (
            <div className={ 'header__giftmagic-logo-box' }>
              <span className={ 'header__giftmagic-logo-image' }/>
            </div>
          ) : (
            <div className={ 'header__giftmagic-logo-box' }>
              <img className={'header__giftmagic-logo-image-rebranding'} width={'103'} height={'52'} src={"https://cdn.gogiftmagic.com/upload/logo/logo-mgm.svg"} alt={"myGiftMagic"} />
            </div>
          )}
        </div>
      )}
    </>
  );
});

export default Header;
