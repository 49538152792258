import React, {useEffect, useState} from 'react';
import './input.scss';
import {i18n} from "../../locales";

export interface IInputProps {
  title: string,
  name: string,
  value?: string,
  tabindex?: number,
  required?: boolean,
  readonly?: boolean,
  type?: 'email' | 'password',
  onError?: () => void;
  onFocus?: () => void;
  maxLength?: number,
  size?: string,
  outerSize?: string,
  autoFocus?: boolean,
}

const rEmail = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

export function Input(props: IInputProps) {
  const [ isFocused, setFocus ] = useState<boolean>(false);
  const [ value, setValue ] = useState<string | null>(props.value || null);
  const [ error, setError] = useState<string>();

  function validate () {
    if (value === null) {
      setError('');
    } else if (props.required && value.trim() === '') {
      setError( i18n( 'form.error.fieldRequired' ) );
    } else if (props.required && !value) {
      setError( i18n( 'form.error.fieldRequired' ) );
    } else if (value && props.type === 'email' && !rEmail.test(value)) {
      setError(i18n( 'form.error.emailNotValid' ));
    } else {
      setError('');
    }
  }

  useEffect(() => {
    validate();
  }, [ value ]);

  useEffect(() => {
    if (props.onError && error && !isFocused) {
      props.onError();
    }
  }, [ error ]);

  function handleInput(target: any) {
    let value = target.value;

    if (value && props.type === 'email') {
      value = value.trim();
    }

    validate();
    setValue(value);
  }

  function handleFocus() {
    setFocus(true);

    if (props.onFocus) {
      props.onFocus();
    }
  }

  function handleBlur() {
    setFocus(false);
    setValue(value || '');
    validate();
  }

  return (
    <div className={ `input input_outer-size ${ props.outerSize ? 'input_outer-size' + props.outerSize : '' } ${ props.size ?  `input_${props.size}-size` : ''} ` }>
      <input
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={['input__element', `${ error && !isFocused ? 'input__element_invalid' : ''}`, isFocused ? 'input__element_focus' : ''].join(' ') }
        type="text" name={props.name} id={`input-${props.name}`}
        required={props.required}
        value={value || ''}
        onInput={ event => handleInput(event.target) }
        readOnly={ props.readonly }
        maxLength={ props.maxLength }
        autoFocus={props.autoFocus}
      />
      <label className={['input__label', value || isFocused ? 'input__label_tiny' : ''].join(' ')} htmlFor={`input-${props.name}`}>
        {props.title}{props.required ? ' *' : ''}
      </label>
      {error && (
        <div className={ 'input__error' }>
          <input type={'hidden'} name={'error'} value={error} />
          { error }
        </div>
      )}
    </div>
  )
}
